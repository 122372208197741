import React, { memo, useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import SpeedChart from './charts/SpeedChart';
import SpeedModal from './modals/SpeedModal';
import { SpeedDetailsProps } from './interfaces/speed.interface';
import { HZbandsData, RPMbandsData } from './constants/speed.contants';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import { generateGroupPDF } from '../../common';

const SpeedDetails: React.FC<SpeedDetailsProps> = ({ heading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const speedHzChartdiv1 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv2 = useRef<HTMLDivElement | null>(null);
  const speedHzChartdiv3 = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv4 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const root = useRef<any>(null);
  const root2 = useRef<any>(null);
  const root3 = useRef<any>(null);
  const root4 = useRef<any>(null);

  const [data, setData] = useState<{
    max: TimeseriesData | AssetTimeseriesData;
    min: TimeseriesData | AssetTimeseriesData;
    pID: TimeseriesData | AssetTimeseriesData;
    speed: TimeseriesData | AssetTimeseriesData;
  }>({
    max: {} as TimeseriesData | AssetTimeseriesData,
    min: {} as TimeseriesData | AssetTimeseriesData,
    pID: {} as TimeseriesData | AssetTimeseriesData,
    speed: {} as TimeseriesData | AssetTimeseriesData,
  });
  const [showLoading, setShowLoading] = useState(true);

  const hElement = document.getElementById('main-root');
  const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
  const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);

  const userPrefrenceData = useAppSelector((state) => state.controlroom.userPreference);
  const speedObject = userPrefrenceData.find(item => item?.title.toLowerCase() === "Speed".toLowerCase());
  const assetinfo = useAppSelector((state) => state.assetInfo.assetDetails);

  useEffect(() => {
    if (loading) {
        setShowLoading(true);
    } else {
        const timer = setTimeout(() => setShowLoading(false), 100);
        return () => clearTimeout(timer);
    }
}, [loading]);

  const speedChannelData = {
    max: useAppSelector(getChannelDataByKey(channelIDsObj.max || '')),
    min: useAppSelector(getChannelDataByKey(channelIDsObj.min || '')),
    pID: useAppSelector(getChannelDataByKey(channelIDsObj.pID || '')),
    speed: useAppSelector(getChannelDataByKey(channelIDsObj.speed || '')),
  }

  const speedTimeSeriesData = {
    max: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.max || '')),
    min: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.min || '')),
    pID: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.pID || '')),
    speed: useAppSelector(getTimeSeriesDataByKey(speedObject?.mappings?.sle?.speed || '')),
  }

  useEffect(() => {
    setData(assetinfo?.isSleAsset ? speedTimeSeriesData : speedChannelData);
  }, [JSON.stringify(speedChannelData), JSON.stringify(speedTimeSeriesData), JSON.stringify(assetinfo), JSON.stringify(userPrefrenceData)])

  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const printWidgetAsPDF = () => {
    const roots = [root, root2];
    generateGroupPDF(
      roots,
      setIsDropdownOpen,
      currentUser,
      'Speed',
      { width: 160, hight: 150 }
    );
  }

  return (
    <>
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          {speedObject?.expandable && <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>}
          {speedObject?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName ${(data?.min === undefined && data?.max === undefined && data?.pID === undefined && data?.speed === undefined) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                {/* <button className={`btn btn-default cancelSelectorName ${(data?.min === undefined && data?.max === undefined && data?.pID === undefined && data?.speed === undefined) ? 'btn-disabled' : ''}`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button> */}
              </div>
            )}
          </div>}
        </div>
        <hr />
        <div id="speedChart-container" className='flex flex-wrap items-center justify-center wellChartSection speedChart-container-dashboard nodata'>

          {showLoading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) :
            data?.min || data?.max || data?.pID || data?.speed
              ? (
                <>
                  {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
                  <SpeedChart chartID={'speedHzChartdiv1'}
                    chartRef={speedHzChartdiv1}
                    classValue={'w-[50%]'}
                    data={data}
                    chartName={'HZ'}
                    minValue={50}
                    maxValue={350}
                    startAngle={-220}
                    endAngle={40}
                    root={root}
                    innerCircleEndvalue={350}
                    bandsData={HZbandsData}
                    spacingValue={4}
                    labelPosition={117}
                  />
                  <SpeedChart chartID={'speedRpmChartdiv2'}
                    chartRef={speedRpmChartdiv2}
                    classValue={'w-[50%]'}
                    data={data}
                    chartName={'RPM'}
                    minValue={50}
                    maxValue={5040}
                    startAngle={-220}
                    endAngle={40}
                    root={root2}
                    innerCircleEndvalue={5040}
                    bandsData={RPMbandsData}
                    spacingValue={50}
                    labelPosition={117}
                  />
                </>
              ) : (
                <NoData heading='No data found' description="Please adjust your date range or filter criteria
                and try again." />
              )}

        </div>
      </div>
      <SpeedModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={'Speed'}
        loading={showLoading}
        modelWidth={'70rem'}
        modelClass={'wellChartModal speedChartModal'}
        innerComponent={
          <>
            {showLoading ? (
              <Loader />
            ) :
              data?.min || data?.max || data?.pID || data?.speed
                ? (
                  <>
                    {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
                    <SpeedChart chartID={'speedHzChartdiv3'}
                      chartRef={speedHzChartdiv3}
                      classValue={'w-[50%]'}
                      data={data}
                      chartName={'HZ'}
                      minValue={50}
                      maxValue={350}
                      startAngle={-220}
                      endAngle={40}
                      root={root3}
                      innerCircleEndvalue={350}
                      bandsData={HZbandsData}
                      spacingValue={4}
                      labelPosition={120}
                    />
                    <SpeedChart chartID={'speedRpmChartdiv4'}
                      chartRef={speedRpmChartdiv4}
                      classValue={'w-[50%]'}
                      data={data} chartName={'RPM'}
                      minValue={50} maxValue={5040}
                      startAngle={-220}
                      endAngle={40}
                      root={root4}
                      innerCircleEndvalue={5040}
                      bandsData={RPMbandsData}
                      spacingValue={50}
                      labelPosition={120}
                    />
                  </>
                ) : (
                  <NoData heading='No data found' description="Please adjust your date range or filter criteria
                  and try again." />
                )}
          </>
        }
      />
    </>
  );
};

export default memo(SpeedDetails);  