import React, { useEffect, useRef } from 'react';
import { WellWatchList } from '../../interfaces/ControlRoomWells.interface';

interface WatchListCardProps {
    title: string;
    description: string;
    count: number;
    isActive?: boolean;
    onClick: (well: WellWatchList) => void;
    isDropdownOpen: boolean;
    onDropdownClick: (well: WellWatchList) => void;
    onCloseDropdown: () => void;
    handleDeleteWatchList: (well: WellWatchList) => void;
    handleEditWatchList: (well: WellWatchList) => void;
    well: WellWatchList
}

const WatchListCard = ({
    title,
    description,
    count,
    isActive = false,
    onClick,
    isDropdownOpen,
    onDropdownClick,
    onCloseDropdown,
    handleDeleteWatchList,
    handleEditWatchList,
    well
}: WatchListCardProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onCloseDropdown();
            }
        };

        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDropdownOpen, onCloseDropdown]);

    const handleDropdownClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, well: WellWatchList) => {
        e.stopPropagation();
        onDropdownClick(well);
    };

    return (
        <div
            className={`watch-list-card ${isActive ? 'watch-list-card-active' : ''}`}
            onClick={() => onClick(well)}
        >
            {!well?.isDefault &&<div className='header-icon'>
                <div className='dropdown-container p-2' ref={dropdownRef}>
                    <button className='dropdown-btn' onClick={(e) => handleDropdownClick(e, well)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                        <div className='dropdown-options'>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                // e.preventDefault();
                                handleEditWatchList(well);
                                onCloseDropdown();
                            }} className='dropdown-option show-element'>
                                Edit watchlist
                            </button>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                // e.preventDefault();
                                handleDeleteWatchList(well);
                                onCloseDropdown();
                            }} className='dropdown-option show-element'>
                                Remove watchlist
                            </button>
                        </div>
                    )}
                </div>
            </div>}
            <div>
                <h4 className='watch-list-card-title'>{title}</h4>
                <p className='watch-list-card-description'>{description}</p>
            </div>
            <div className='watch-list-count'>
                <h3>{count}</h3>
            </div>
        </div>
    );
};

export default WatchListCard;
