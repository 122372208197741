import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import { TrendsDetailsProps } from './TrendsDetails';
import { defaultStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../../images/calendar-icon.svg';
import filterIcon from '../../../../../images/filter-icon.svg';
import downIcon from '../../../../../images/chevron-down.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
// import noDataJson from './noData.json';
import '../trends.scss';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { AppUser } from '../../../../user/model/AppUser';
import axios from 'axios';
import { clearAssetEventSchedule, fetchAssetListById } from '../../../../dashboard/DashboardSlice';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';
import { showToaster } from '../../../../dashboard/components/asset-location/AssetList';
import CardsDesign from './CardsDesign';
import { fetchSleTimeSeriesData, fetchSleVariables, updateSleTrendTimeSeriesDataSet, SleTimeSeriesData } from '../../sle-time-series/SleSlice';
import { TrendsDetailsProps } from '../TrendsDetails';
import { GetAggregation } from '../../../../../utilities/CommonFunctions';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { fetchUserPrefrence, LayoutDataItem, updateUserPreferenceData, updateWidgetLayout, UserPreferenceDataItem } from '../TrendsSlice';
import { debounce } from 'lodash';


const ResponsiveGridLayout = WidthProvider(Responsive);

const SleTrends = () => {

    const dispatch = useAppDispatch();
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const sleVariable = useAppSelector((state) => state.sleTimeSeries.variable)

    const { sleTimeSeriesData, sleTimeSeriesDataSet, variableLoading, initialDataLoading } = useAppSelector((state) => state.sleTimeSeries);
    const { userPreference } = useAppSelector((state) => state.trends)
    const [preferenceItems, setPreferenceItems] = useState<UserPreferenceDataItem[]>(userPreference);

    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: startOfDay(addDays(new Date(), -6)),
        endDate: endOfDay(new Date()),
        key: 'selection',
    });
    const [trendsData, setTrendsData] = useState<SleTimeSeriesData[]>([]);
    const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
    const [filteredVal, setFilteredVal] = useState<string[]>([]);
    const [prevFilterVal, setPrevFilterVal] = useState<string[]>([]);
    const [noTrendsData, setNoTrendsData] = useState<TrendsDetailsProps[]>([]);
    const assetControlData = useAppSelector((state) => state?.assetControl);
    const [loadingIcon, setLoadingIcon] = useState(true)
    const sleCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
    const aggregateValues = useAppSelector((state) => state?.assetDetail?.aggregateValue)
    const currentAggregation = useRef('')

    const [trendsMinMaxValue, setTrendsMinMaxValue] = useState<{
        [key: string]: {
            minValue: number;
            mean: number;
            maxValue: number;
            lastData: number;
            lastDataDate: string;
        };
    }>({});
    const [layout, setLayout] = useState<LayoutDataItem[]>([]);


    useEffect(() => {
        const selectedAssetName = selectedAsset?.selectedAssetName
        if (!selectedAssetName) return
        dispatch(fetchSleVariables(selectedAssetName))
            .unwrap()
            .then((response: any) => {
                const filterSelectedData = response?.data?.map((item: any) => item.variable);
                setFilteredVal(filterSelectedData);
                setSelectedVariables(filterSelectedData);
                setPrevFilterVal(filterSelectedData)
            })
            .catch((error) => {
                setFilteredVal([])
                setSelectedVariables([]);
                setPrevFilterVal([]);
                console.error('Failed to fetch Filter data:', error);
            });

        if (!userPreference?.length) {
            dispatch(fetchUserPrefrence('asset-detail'))
        }
    }, [])

    useEffect(() => {
        if (userPreference && userPreference.length > 0) {
            setPreferenceItems(userPreference);
        }
    }, [JSON.stringify(userPreference)]);

    useEffect(() => {
        prepareNoData(selectedVariables)
    }, [selectedVariables])

    useEffect(() => {
        setSelectedVariables([...filteredVal])

        setTrendsMinMaxValue({})
    }, [selectedDateRange])

    useEffect(() => {
        if (sleTimeSeriesDataSet?.length > 1) {
            dispatch(updateSleTrendTimeSeriesDataSet([{ ...sleTimeSeriesDataSet[0] }]))
        }
        setTrendsMinMaxValue({})
    }, [prevFilterVal])

    useEffect(() => {
        if (selectedVariables?.length !== 0) {
            if (sleTimeSeriesDataSet?.length)
                dispatch(updateSleTrendTimeSeriesDataSet([]))

            setLoadingIcon(true)

            if (sleCancelTokenSource.current) {
                sleCancelTokenSource.current.cancel('canceled');
            }

            sleCancelTokenSource.current = axios.CancelToken.source();

            const wellName = selectedAsset?.selectedAssetName ?? '';
            if (wellName === '') return

            const startDate = new Date(selectedDateRange.startDate.getFullYear(), selectedDateRange.startDate.getMonth(), selectedDateRange.startDate.getDate(), 0, 0, 0, 0).toISOString();
            const endDate = new Date(selectedDateRange.endDate.getFullYear(), selectedDateRange.endDate.getMonth(), selectedDateRange.endDate.getDate(), 23, 59, 59, 999).toISOString();
            const aggregateValue = GetAggregation(new Date(startDate), new Date(endDate), aggregateValues)
            currentAggregation.current = aggregateValue

            dispatch(fetchSleTimeSeriesData({ wellName: wellName, startDate: startDate, endDate: endDate, variables: selectedVariables.toString() || '', aggregate: aggregateValue, cancelToken: sleCancelTokenSource?.current?.token, granularData: false }))
                .finally(() => {
                    setLoadingIcon(false);
                })

        }

        if (assetControlData?.assetControlScanStatus)
            dispatch(updateAssetControlScanStatus(false))

        return (() => {
            if (sleCancelTokenSource.current) {
                sleCancelTokenSource.current.cancel('canceled');
            }
        })
    }, [selectedVariables, assetControlData?.assetControlScanStatus === true])

    const prepareNoData = (variableArray: string[]) => {
        const noDataArray = variableArray?.map((filteredItem) => (
            {
                "trendName": sleVariable.find((item) => item.variable === filteredItem)?.variableName || '',
                "unitOfMeasure": sleVariable.find((item) => item.variable === filteredItem)?.shortUnitOfMeasure || '',
                "value": "0",
                "date": null,
                "max": "0",
                "maxThreshold": "0",
                "minThreshold": "0",
                "min": "0",
                "medean": "0",
                "dataPoints": null
            }
        ))

        const sortedData = sleVariable?.map(item => noDataArray?.find(trend => trend?.trendName === item?.variableName)).filter((trend) => trend !== undefined) as TrendsDetailsProps[]
        setNoTrendsData(sortedData)
    }

    const prepareTrendData = (timeSeriesData: SleTimeSeriesData[]) => {
        const convertedArray = timeSeriesData?.map((item) => ({ ...item, trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || '' }))
        setTrendsData(convertedArray)
    }

    useEffect(() => {
        if (sleTimeSeriesData?.length > 0) {
            prepareTrendData(sleTimeSeriesData)
        } else {
            setTrendsData([])
        }
    }, [sleTimeSeriesData])

    const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
    const storedUserData = localStorage.getItem('loggedInUser');
    const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
    const initialUserState = loggedInUser || storedUser || undefined;
    const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

    useEffect(() => {
        const selectedAssetName = selectedAsset?.selectedAssetName ?? '';
        const groupName = selectedAsset?.selectedGroup ?? '';

        if (initialUserState?.id && groupName) {
            cancelTokenSource.current = axios.CancelToken.source();
            dispatch(clearAssetEventSchedule());
            setTimeout(() => {
                dispatch(
                    fetchAssetListById({
                        userId: initialUserState.id,
                        groupName,
                        startIndex: 0,
                        endIndex: 1,
                        searchQuery: selectedAssetName ? '?filter=' + selectedAssetName : '',
                        cancelToken: cancelTokenSource?.current?.token,
                    }),
                );
            });
        }

        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel('Component unmounted or route changed.');
            }
        };
    }, [dispatch, selectedAsset, initialUserState?.id]);

    useEffect(() => {
        const layout = preferenceItems
            .filter(preference =>
                noTrendsData?.some(data =>
                    preference.title?.toLowerCase() === data.trendName?.toLowerCase()
                )
            )?.map(preference => {
                return {
                    i: preference.title,
                    x: preference.positions?.lg?.x,
                    y: preference.positions?.lg?.y,
                    w: 12,
                    h: 3,
                }
            }).sort((a, b) => a.y - b.y);// Extract position from filtered results
        setLayout(layout)
    }, [JSON.stringify(preferenceItems), JSON.stringify(noTrendsData)])

    const handleSlideOutClick = (e: any) => {
        if (e.target.id === 'range-calendar-input') return;
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
    };

    const isFilterChanged = prevFilterVal?.some((item) => !filteredVal?.includes(item)) || filteredVal?.some((item) => !prevFilterVal?.includes(item));

    const handleFilterOutClick = (e: any) => {
        if (e?.target?.id === 'delete-btn') return;
        if (showFilterPopup) {
            setShowFilterPopup(false);
            isFilterChanged && setFilteredVal([...prevFilterVal])
        }
    };

    const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
    const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

    const handleClearButton = () => {
        setFilteredVal([]);
    };
    const handleApplyButton = () => {
        if (filteredVal?.length === 0) {
            showToaster('Please select atleast one filter', 'error');
            return;
        }
        else {
            if (filteredVal?.every(value => selectedVariables?.includes(value))) {
                if (sleTimeSeriesData && sleTimeSeriesData?.length !== 0) {
                    const updatedTrendsData = sleTimeSeriesData?.filter((trend) => filteredVal?.includes(trend?.variable));
                    const convertedArray = updatedTrendsData?.map((item) => ({ ...item, trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || '' }))
                    setTrendsData(convertedArray);
                } else {
                    setTrendsData([])
                }
                prepareNoData(filteredVal)
            } else {
                setSelectedVariables(filteredVal)
            }
            setPrevFilterVal(filteredVal);
        }
        setShowFilterPopup(false)
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const checkFilterAvl = filteredVal?.includes(value);
        if (checkFilterAvl) {
            const updatedFilteredCheckedValues = filteredVal?.filter((filteredValue) => filteredValue !== value);
            setFilteredVal(updatedFilteredCheckedValues);
        } else {
            setFilteredVal((prev) => [...prev, value]);
        }
    };

    const debouncedLayoutChangeRef = useRef(
        debounce((mergedItems: UserPreferenceDataItem[]) => {
            dispatch(updateWidgetLayout({ dashboard: "asset-detail", data: mergedItems })).then((res) => {
                if (updateWidgetLayout.fulfilled.match(res)) {
                    showToaster('Layout updated successfully', 'success');
                } else {
                    showToaster('Failed to update layout', 'error');
                }
            });
        }, 5000)
    );

    const onLayoutChange = (updatedLayout: LayoutDataItem[]) => {
        const mergedItems = userPreference && userPreference?.map(item => {
            const updatedItem = updatedLayout?.find(layout => layout.i === item.key);
            if (updatedItem) {
                return {
                    ...item,
                    positions: {
                        ...item.positions,
                        lg: {
                            x: updatedItem.x,
                            y: updatedItem.y,
                            w: updatedItem.w,
                            h: updatedItem.h,
                        },
                    },
                };
            }
            return item;
        });

        if (JSON.stringify(mergedItems) !== JSON.stringify(preferenceItems)) {
            setPreferenceItems(mergedItems); // Update local state with new items
            setLayout(updatedLayout); // Update layout
            dispatch(updateUserPreferenceData(mergedItems))
            debouncedLayoutChangeRef.current(mergedItems); // Call debounced API update
        }
    };

    const renderdateRange = () => {
        return (
            <div className='trends-container-date-range'>
                <div className='input-group'>
                    <img src={calenderIcon} alt='calendar-icon' className='absolute top-3 left-3' />
                    <input
                        id='range-calendar-input'
                        className='date-range-input'
                        value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                        onClick={() => setShowCalendar(!showCalendar)}
                    />
                </div>
                <div ref={ref}>
                    {showCalendar && (
                        <DateRangeSelector
                            setShowCalendar={setShowCalendar}
                            setSelectedDateRange={setSelectedDateRange}
                            staticRanges={defaultStaticRanges}
                            minDate={defineds?.startOfLastYear}
                            maxDate={defineds?.endOfToday}
                            selectedDateRange={selectedDateRange}
                        />
                    )}
                </div>

                <div style={{ position: 'relative', zIndex: '98' }}>
                    <div
                        id='filter-btn'
                        style={{
                            border: showFilterPopup
                                ? '1px solid var(--Dark-mode-400, #4A5463)'
                                : '1px solid var(--Dark-mode-400, #4A5463)',
                            background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowFilterPopup(!showFilterPopup);
                        }}
                    >
                        <img src={filterIcon} alt='filterIcon' />
                        Filter {`(${filteredVal?.length})`}
                        <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
                    </div>

                    {showFilterPopup && (
                        <div className='trends-filter-modal-container' ref={filterRef}>
                            <div className='trends-filter-modal-header'>Filter asset telemetry</div>

                            <div className='trends-filter-modal-body'>
                                {sleVariable?.map((trend) => {
                                    return (
                                        <>
                                            <div className='checkbox-main-container'>
                                                <label className='checkbox-container'>
                                                    <input
                                                        type='checkbox'
                                                        id='showCheckbox'
                                                        checked={filteredVal?.includes(trend.variable)}
                                                        onChange={handleCheckbox}
                                                        value={trend.variable}
                                                    />
                                                    <span className='checkbox-checkmark'></span>
                                                    <span className='inline-block pl-[29px]'>{trend.variableName}&nbsp;</span>
                                                </label>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div className='trends-filter-modal-footer'>
                                <button className={'footer-btn'} onClick={handleClearButton}>
                                    Clear
                                </button>
                                <button className={'footer-btn'} onClick={handleApplyButton}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const findTrendsData = (trendName: any) => {
        const data = trendsData?.find((item) => {
            return item?.trendName?.toLowerCase() === trendName?.toLowerCase();
        });
        return data;
    };



    return (
        <>
            <div
                className={
                    showCalendar || showFilterPopup ? 'trends-container trends-calender-filter-open' : 'trends-container'
                }
                style={{ position: 'relative' }}
            >
                {renderdateRange()}
                {variableLoading ? (
                    <div className='trend-loader flex items-center justify-center w-100'>
                        <Loader />
                    </div>
                ) :

                    <>
                        <ResponsiveGridLayout
                            className="layout"
                            layouts={{ lg: layout }} // Define layout for large screens
                            breakpoints={{ lg: 1200, md: 996 }}
                            cols={{ lg: 12, md: 12 }}
                            rowHeight={100}
                            isResizable={false}
                            useCSSTransforms={false}
                            margin={[0, 8]} // Disable default margins
                            containerPadding={[0, -6]}
                            onDragStop={onLayoutChange}
                        >
                            {layout?.map((item, index) => {
                                const matchedItem = noTrendsData?.find((data) => data.trendName?.toLowerCase() === item?.i?.toLowerCase()) as TrendsDetailsProps
                                const isDataAvl = findTrendsData(matchedItem?.trendName);
                                if (isDataAvl) {
                                    return (
                                        <div key={item.i} style={{ width: '100% !important' }}>
                                            <CardsDesign
                                                key={`${isDataAvl?.date}-${index}`}
                                                trend={isDataAvl}
                                                index={index}
                                                selectedCalendarDate={selectedDateRange}
                                                loadingIcon={loadingIcon || initialDataLoading}
                                                filteredValues={prevFilterVal}
                                                trendsMinMaxValue={trendsMinMaxValue}
                                                setTrendsMinMaxValue={setTrendsMinMaxValue}
                                                currentAggregateValue={currentAggregation.current}
                                            />
                                        </div>
                                    );
                                } else if (matchedItem) {
                                    return (
                                        <div key={item.i} style={{ width: '100% !important' }}>
                                            <CardsDesign
                                                key={`${matchedItem?.date}-${index}`}
                                                trend={matchedItem}
                                                index={index}
                                                selectedCalendarDate={selectedDateRange}
                                                loadingIcon={loadingIcon || initialDataLoading}
                                                filteredValues={prevFilterVal}
                                                trendsMinMaxValue={trendsMinMaxValue}
                                                setTrendsMinMaxValue={setTrendsMinMaxValue}
                                                currentAggregateValue={currentAggregation.current}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </ResponsiveGridLayout >
                    </>
                }
            </div>
        </>
    );
};

export default SleTrends;
