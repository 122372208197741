import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import championx from '../../../src/images/championxLogo.png';
import moment from "moment-timezone";
import { differenceInDays, differenceInYears, endOfDay, startOfDay } from "date-fns";

export const generatePDF = (
  roots: any,
  setIsDropdownOpen: any,
  currentUser: any,
  title: string,
  style: { width: number; hight: number; } = { width: 500, hight: 300 }
) => {
  if (roots.length > 0) {
    const exportingInstances = roots.map((rootRef: any) =>
      am5plugins_exporting.Exporting.new(rootRef?.current, {
        menu: am5plugins_exporting.ExportingMenu.new(rootRef.current, {}),
      })
    );

    const exportPromises = exportingInstances.map((exporting: any) => exporting.export('png'));

    exportPromises.unshift(exportingInstances[0].getPdfmake());

    Promise.all(exportPromises)
      .then((res) => {
        const pdfMake = res[0];
        const str = moment().format('MMM-DD-YYYY hh:mm:ss A');

        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
              {
                text: 'Generated on : ' + str,
                alignment: 'right',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
            ],
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 9.5,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
          },
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20,
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e'],
        };

        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20,
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848'],
        };

        doc.content.push({
          columns: [
            {
              stack: [
                { canvas: [rect] },
                {
                  columns: [
                    {
                      width: rect.w / 2,
                      image: championx,
                      fit: [55, 55],
                      margin: [5.5, 7.5, 3],
                      background: 'white',
                    },
                    {
                      text: "Control room",
                      width: rect.w / 2,
                      alignment: 'left',
                      bold: true,
                      margin: [35, 5],
                      fontSize: 13,
                      color: 'white',
                    },
                  ],
                  relativePosition: { x: rect.x, y: -rect.h },
                },
              ],
            },
            {
              stack: [
                { canvas: [rectHead] },
                {
                  columns: [
                    {
                      width: rectHead.w,
                      text: 'LOOKOUT',
                      color: 'white',
                      bold: true,
                      alignment: 'right',
                      fontSize: 10,
                    },
                  ],
                  relativePosition: { x: -80, y: -20 },
                },
              ],
            },
          ],
        });

        doc.content.push({ text: ' ', width: '100%' });

        doc.content.push(
          {
            text: title,
            width: '*',
            alignment: 'center',
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175,
                y1: 0,
                x2: 362,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 0, 0, 15],
          }
        );

        // Add images dynamically
        for (let i = 1; i < res.length; i++) {
          doc.content.push({
            image: res[i],
            alignment: 'center',
            width: style.width,
            height: style.hight,
            margin: [0, 0, 0, 20],
          });
        }

        const fileName = `Lookout-${title.replace(/\s+/g, '_')}_${str}.pdf`;

        pdfMake.createPdf(doc).download(fileName);
      })
      .finally(() => {
        setIsDropdownOpen(false);
      });
  }
}

export const generateGroupPDF = (
  roots: any,
  setIsDropdownOpen: any,
  currentUser: any,
  title: string,
  style: { width: number; hight: number; } = { width: 500, hight: 300 }
) => {
  if (roots.length > 0) {
    const exportingInstances = roots.map((rootRef: any) =>
      am5plugins_exporting.Exporting.new(rootRef?.current, {
        menu: am5plugins_exporting.ExportingMenu.new(rootRef.current, {}),
      })
    );

    const exportPromises = exportingInstances.map((exporting: any) => exporting.export('png'));

    exportPromises.unshift(exportingInstances[0].getPdfmake());

    Promise.all(exportPromises)
      .then((res) => {
        const pdfMake = res[0];
        const str = moment().format('MMM-DD-YYYY hh:mm:ss A');

        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
              {
                text: 'Generated on : ' + str,
                alignment: 'right',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
            ],
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 9.5,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
          },
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20,
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e'],
        };

        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20,
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848'],
        };

        doc.content.push({
          columns: [
            {
              stack: [
                { canvas: [rect] },
                {
                  columns: [
                    {
                      width: rect.w / 2,
                      image: championx,
                      fit: [55, 55],
                      margin: [5.5, 7.5, 3],
                      background: 'white',
                    },
                    {
                      text: "Control room",
                      width: rect.w / 2,
                      alignment: 'left',
                      bold: true,
                      margin: [35, 5],
                      fontSize: 13,
                      color: 'white',
                    },
                  ],
                  relativePosition: { x: rect.x, y: -rect.h },
                },
              ],
            },
            {
              stack: [
                { canvas: [rectHead] },
                {
                  columns: [
                    {
                      width: rectHead.w,
                      text: 'LOOKOUT',
                      color: 'white',
                      bold: true,
                      alignment: 'right',
                      fontSize: 10,
                    },
                  ],
                  relativePosition: { x: -80, y: -20 },
                },
              ],
            },
          ],
        });

        doc.content.push({ text: ' ', width: '100%' });

        doc.content.push(
          {
            text: title,
            width: '*',
            alignment: 'center',
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175,
                y1: 0,
                x2: 362,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 0, 0, 15],
          }
        );

        // Add images dynamically
        // for (let i = 1; i < res.length; i++) {
        //   doc.content.push({
        //     image: res[i],
        //     alignment: 'center',
        //     width: style.width,
        //     height: style.hight,
        //     margin: [0, 0, 0, 20],
        //   });
        // }

        // Group all images into a single row
          const imageRow = res.slice(1).map((image) => ({
            image,
            width: style.width,
            height: style.hight,
            margin: [5, 5, 5, 5],
          }));
          
          doc.content.push({
            columns: [
              { width: '*', text: '' },
              ...imageRow,
              { width: '*', text: '' },
            ],
            columnGap: 5,
          });

          // doc.content.push({
          //    columns: imageRow,
          //    columnGap: 5,
          //  });

        const fileName = `Lookout-${title.replace(/\s+/g, '_')}_${str}.pdf`;

        pdfMake.createPdf(doc).download(fileName);
      })
      .finally(() => {
        setIsDropdownOpen(false);
      });
  }
}



export const handleInputChange = (
  key: string,
  value: string,
  rawInput: { startDate: string; endDate: string },
  setRawInput: React.Dispatch<React.SetStateAction<{
    startDate: string;
    endDate: string;
  }>>,
  setCalendar: React.Dispatch<React.SetStateAction<{ startDate: Date | undefined; endDate: Date | undefined; key: string }>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  calendar: { startDate: Date | undefined; endDate: Date | undefined; key: string },
  minDate: Date,
) => {

  if (value.length > 11) {
    setError("Invalid Date");
    return;
  }

  setRawInput({
    ...rawInput,
    [key]: value,
  });

  const date = new Date(value);
  const today = endOfDay(new Date());

  if (isNaN(date.getTime())) {
    setError("Invalid Date");
    return;
  }

  setCalendar({
    ...calendar,
    [key]: key === "startDate" ? startOfDay(date) : endOfDay(date),
  });

  if (date > today) {
    setError("Date cannot be in the future.");
    return;
  }

  const daysDiff = differenceInDays(today, minDate);
  const yearsDiff = differenceInYears(today, minDate);

  let minDaysOrYearsMessage = "";
  if (yearsDiff >= 1) {
    minDaysOrYearsMessage = `within the last ${yearsDiff} year${yearsDiff > 1 ? "s" : ""}`;
  } else {
    minDaysOrYearsMessage = `within the last ${daysDiff + 1} day${daysDiff > 1 ? "s" : ""}`;
  }

  if (date < minDate) {
    setError(`Date range should be ${minDaysOrYearsMessage}.`);
    return;
  }

  if (key === "endDate" && new Date(rawInput.startDate) < minDate) {
    setError(`Date range should be ${minDaysOrYearsMessage}.`);
    return;
  }

  const updatedCalendar = {
    ...calendar,
    [key]: key === "startDate" ? startOfDay(date) : endOfDay(date),
  };

  const startDate = updatedCalendar.startDate;
  const endDate = updatedCalendar.endDate;

  if (startDate && endDate) {
    if (startDate > endDate) {
      setError("Start date cannot be after the end date.");
      return;
    }

    if (endDate > today) {
      setError("End date cannot be in the future.");
      return;
    }
  }

  setCalendar(updatedCalendar);
  setError(null);
};
