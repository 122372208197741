
import HealthScoreMainDashboard from './images/health-score-main-dash.svg';
import PeakRecommendationVital from './images/peak-recommendtations-vital.svg';
import DashboardPeakHealthscore from './images/dashboard-peak-healthscore.svg';
import ControlRoomWells from './images/control-room-wells.svg';
import BuildWatchlist from './images/build-watchlist.svg';
import DashboardManageAsset from './images/dashboard-manage-assets.svg';
import AssetLocations from './images/asset-locations.svg'

export interface WalkthroughDataProps {
    contentHeader: string,
    contentDescription: string,
    contentImage: string,
    imageCaption: string
}

export const WalkthroughData: WalkthroughDataProps[] = [
    {
        contentHeader: "See real-time data",
        contentDescription: "Make informed, data-driven decisions with control room.",
        contentImage: HealthScoreMainDashboard,
        imageCaption: "Introducing the new LOOKOUT features"
    },
    {
        contentHeader: "Monitor asset health",
        contentDescription: "Quickly check-in on the asset’s overall performance history based on 3 variables.",
        contentImage: PeakRecommendationVital,
        imageCaption: "Easily track an asset’s performance"
    },
    {
        contentHeader: "See your data in one place",
        contentDescription: "Get unmatched data visualization without needing to change screens. You can see your asset’s health score by clicking on the control bar’s health score button.",
        contentImage: DashboardPeakHealthscore,
        imageCaption: "View data and asset health side-by-side"
    },
    {
        contentHeader: "Get quick access to your data",
        contentDescription: "Create custom well watchlists to group wells by specific criteria and view key metrics at a glance.",
        contentImage: ControlRoomWells,
        imageCaption: "Group wells by categories"
    },
    {
        contentHeader: "Customize your watchlists",
        contentDescription: "Watchlists can be built based on operator, location, status, and parameters.",
        contentImage: BuildWatchlist,
        imageCaption: "Build a watchlist with specific criteria"
    },
    {
        contentHeader: "Get data that drives decisions",
        contentDescription: "See real-time insights with our widgets, allowing you to make informed decisions quickly. Our gauges display current well performance, while line charts provide trends from the past week.",
        contentImage: HealthScoreMainDashboard,
        imageCaption: "Real-time metrics"
    },
    {
        contentHeader: "Seamlessly make changes",
        contentDescription: `Adjusting your system’s operations is now simple. You can seamlessly navigate between operating modes, tweak setpoints, and document changes without leaving the asset details view.

Access SMARTEN™ XE control system in LOOKOUT™ by clicking the controller button on the bottom left of your manage asset panel.`,
        contentImage: DashboardManageAsset,
        imageCaption: "Make changes from Control Room"
    },
    {
        contentHeader: "Make changes remotely",
        contentDescription: " SMARTEN™ LIVE is for experts who like to have total control over every aspect of their systems. This powerful interface connects you in real-time with our SMARTEN portfolio of controllers and is compatible with other industry-standard HMI control interfaces.",
        contentImage: AssetLocations,
        imageCaption: "Quickly access additional controls"
    }
]

