import React, { useEffect, useState } from 'react'
import './BuildWatchlistFiltersModal.scss'
import CloseIcon from '../../../../../images/CloseIcon.svg'
import TrashIcon from '../../../../../images/trash-03.svg'
import ArrowUp from '../../../../../images/arrow-up-4.svg'
import ArrowDown from '../../../../../images/arrow-down-4.svg'
import { useAppSelector } from '../../../../../hooks/storeHooks'
import { SelectedFilters } from '../ControlRoomWells'
import { Parameter, WellWatchList } from '../../interfaces/ControlRoomWells.interface'

interface BuildWatchlistFiltersModalProps {
    closeModal: () => void;
    createBtnHandler: (payload: SelectedFilters) => void;
    updateBtnHandler: (payload: SelectedFilters) => void;
    activeWell: WellWatchList;
}

const BuildWatchlistFiltersModal = ({ closeModal, createBtnHandler, updateBtnHandler, activeWell }: BuildWatchlistFiltersModalProps) => {

    const assetFilters = useAppSelector((state) => ({
        operators: state.wellWatchList.assetOperators as Parameter[],
        locations: state.wellWatchList.assetLocations as Parameter[],
        statuses: state.wellWatchList.assetStatuses as Parameter[],
        parameters: state.wellWatchList.assetParameters as Parameter[],
        watchList: state.wellWatchList.wellsWatchListById as WellWatchList
    }));

    const [isFormValid, setIsFormValid] = useState(false);
    const [operators, setOperators] = useState<Parameter[]>(assetFilters.operators)
    const [locations, setLocations] = useState<Parameter[]>(assetFilters.locations)
    const [statuses, setStatuses] = useState<Parameter[]>(assetFilters.statuses)
    const [parameters, setParameters] = useState<Parameter[]>(assetFilters.parameters)

    const [expandedFilters, setExpandedFilters] = useState({
        operators: false,
        location: false,
        statuses: false,
        parameters: false,
    })

    const [selectedFilters, setSelectedFilters] = useState({
        operators: [] as Parameter[],
        locations: [] as Parameter[],
        statuses: [] as Parameter[],
        parameters: [] as Parameter[],
        name: '',
        description: '',
    });

    useEffect(() => {
        validateWatchlist();
    }, [selectedFilters]);

    const updateSelectedFilters = (items: Parameter[], type: 'operators' | 'locations' | 'statuses' | 'parameters') => {
        const selectedItems = items.filter(item => item.isChecked);

        const finalSelectedItems = type === 'parameters'
            ? selectedItems.map(item => ({
                ...item,
                from: selectedFilters.parameters.find(p => p.id === item.id)?.from || null,
                to: selectedFilters.parameters.find(p => p.id === item.id)?.to || null,
            }))
            : selectedItems;

        setSelectedFilters(prev => ({
            ...prev,
            [type]: finalSelectedItems,
        }));
    };


    const handleCheckboxChange = (
        id: string,
        isChecked: boolean,
        setItems: React.Dispatch<React.SetStateAction<Parameter[]>>,
        type: 'operators' | 'locations' | 'statuses' | 'parameters'
    ) => {
        setItems(items => {
            const updatedItems = items.map(item =>
                item.id === id ? { ...item, isChecked } : item
            );

            if (type === 'parameters') {
                const finalUpdatedItems = updatedItems.map(item => {
                    const existingParam = selectedFilters.parameters.find(p => p.id === item.id);
                    return {
                        ...item,
                        from: existingParam?.from || null,
                        to: existingParam?.to || null,
                    };
                });

                updateSelectedFilters(finalUpdatedItems, type);
            } else {
                updateSelectedFilters(updatedItems, type);
            }

            return updatedItems;
        });
    };

    const handleSelectAll = (setItems: React.Dispatch<React.SetStateAction<Parameter[]>>, select: boolean, type: 'operators' | 'locations' | 'statuses' | 'parameters') => {
        setItems(items => {
            const updatedItems = items.map(item => ({
                ...item,
                isChecked: select,
            }));
            updateSelectedFilters(updatedItems, type);
            return updatedItems;
        });
    };
    const toggleFilterSection = (section: 'operators' | 'location' | 'statuses' | 'parameters') => {
        setExpandedFilters(prev => ({
            operators: section === 'operators',
            location: section === 'location',
            statuses: section === 'statuses',
            parameters: section === 'parameters',
            [section]: !prev[section],
        }));

    };

    const renderFilterCheckboxes = (items: Parameter[], handleChange: (id: string, checked: boolean) => void) => {
        return <div className="filter-checkboxes">
            {items.map(item => (
                <label key={item.id} className="checkbox-container">
                    <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={(e) => handleChange(item.id, e.target.checked)}
                    />
                    <span className="checkbox-checkmark"></span>
                    <p>{item.name}</p>
                </label>
            ))}
        </div>
    }

    const getSelectedCount = (itemName: "locations" | "operators" | "statuses" | "parameters"): number => {
        return selectedFilters[itemName]?.length;
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: 'name' | 'description') => {
        setSelectedFilters(prev => ({
            ...prev,
            [type]: e.target.value
        }));
    }

    const CloseButtonHandler = () => {
        setSelectedFilters({} as SelectedFilters);
        closeModal();
    }

    useEffect(() => {

        if (activeWell && activeWell?.id) {
            setOperators(assetFilters.operators?.map(item => ({
                ...item,
                isChecked: activeWell?.operators?.includes(item?.id?.toString())
            })));
            setLocations(assetFilters.locations?.map(item => ({
                ...item,
                isChecked: activeWell?.locations?.includes(item?.id?.toString())
            })));
            setStatuses(assetFilters.statuses?.map(item => ({
                ...item,
                isChecked: activeWell?.statuses?.includes(item?.id?.toString())
            })));

            setParameters(assetFilters.parameters?.map(item => ({
                ...item,
                isChecked: activeWell?.parameters?.some(parameter => parameter?.id?.toString() === item?.id?.toString())
            })));

            const selectedParameters = assetFilters?.parameters?.map(item => {
                const parameter = activeWell?.parameters?.find(parameter => parameter?.id?.toString() === item.id.toString());
                return {
                    id: item?.id?.toString(),
                    from: parameter?.from || null,
                    to: parameter?.to || null,
                    name: item?.name,
                    isChecked: item?.isChecked
                }
            });
            const selectedParametersWithValues = selectedParameters.filter(parameter => parameter?.from !== null || parameter?.to !== null);


            setSelectedFilters({
                ...selectedFilters,
                operators: assetFilters.operators?.filter(item => activeWell?.operators?.includes(item.id.toString())),
                locations: assetFilters.locations?.filter(item => activeWell?.locations?.includes(item.id.toString())),
                statuses: assetFilters.statuses?.filter(item => activeWell?.statuses?.includes(item.id.toString())),
                name: activeWell?.name,
                description: activeWell?.description,
                parameters: selectedParametersWithValues,

            });
        }

    }, [activeWell?.id]);


    const handleParameterChange = (id: string, value: string, type: 'from' | 'to') => {

        setSelectedFilters(prev => ({
            ...prev,
            parameters: prev.parameters.map(parameter => parameter.id === id ? {
                ...parameter,
                [type]: value
            } : { ...parameter })
        }));
    }

    const validateWatchlist = () => {
        if (selectedFilters.name === '') {
            setIsFormValid(false);
            // showToaster('Please fill the name field', 'error');
            return false;
        } else if (selectedFilters.description === '') {
            setIsFormValid(false);
            // showToaster('Please fill the description field', 'error');
            return false;
        } else if (selectedFilters.parameters.length > 0) {
            const invalidParameters = selectedFilters.parameters.filter(parameter => !parameter.from || !parameter.to);
            if (invalidParameters.length > 0) {
                setIsFormValid(false);
                // showToaster('Please fill all the parameters values', 'error');
                return false;
            }
        }
        setIsFormValid(true);
        return true;
    }


    return (
        <div className="build-watchlist-filters-modal modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">Build Watchlist</h2>
                    <button className="close-btn" onClick={CloseButtonHandler}>
                        <img src={CloseIcon} alt="close" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="asset-count-div" style={{display: 'none'}}>
                        {/* <p className="asset-count">10</p> */}
                        <span className='asset-count-label !text-5xl'>0</span>
                        <p className="asset-count-label">Total Assets</p>
                    </div>

                    <div className="filters-form-container">
                        <div className="watchlist-filters">

                            <div className="watchlist-filters-heading">
                                Criteria
                            </div>

                            {/* Operators Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('operators')}>
                                <div className="watchlist-filters-title">
                                    Operators
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters?.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.operators ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setOperators, true, "operators")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setOperators, false, "operators")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(operators, (id, checked) => handleCheckboxChange(id, checked, setOperators, "operators"))}
                            </div>

                            {/* Location Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('location')}>
                                <div className="watchlist-filters-title">
                                    Location
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.location ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.location ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setLocations, true, "locations")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setLocations, false, "locations")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(locations, (id, checked) => handleCheckboxChange(id, checked, setLocations, "locations"))}
                            </div>

                            {/* Status Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('statuses')}>
                                <div className="watchlist-filters-title">
                                    Status
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.statuses ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.statuses ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setStatuses, true, "statuses")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setStatuses, false, "statuses")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(statuses, (id, checked) => handleCheckboxChange(id, checked, setStatuses, "statuses"))}
                            </div>

                            {/* Parameters Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('parameters')}>
                                <div className="watchlist-filters-title">
                                    Parameters
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.parameters ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.parameters ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setParameters, true, "parameters")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setParameters, false, "parameters")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(parameters, (id, checked) => handleCheckboxChange(id, checked, setParameters, "parameters"))}
                            </div>



                        </div>

                        <div className="watchlist-form">
                            <div className="name-description-form-group">
                                <div className="form-group name">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input value={selectedFilters.name} onChange={(e) => onChangeInput(e, 'name')} type="text" id="name" className="form-control" placeholder='Selected well' />
                                </div>
                                <div className="form-group description">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <input value={selectedFilters.description} onChange={(e) => onChangeInput(e, 'description')} type="text" id="description" className="form-control" placeholder='Description' />
                                </div>
                            </div>
                            <div className="selected-filters-sec">

                                {getSelectedCount("operators") ? <div className="selected-filter-div">
                                    <h4>Operators</h4>
                                    <div className="count-del-btn">
                                        <p>{getSelectedCount("operators")}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                setOperators(operators.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                })))
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    operators: []
                                                })
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                {getSelectedCount("locations") ? <div className="selected-filter-div">
                                    <h4>Location</h4>
                                    <div className="count-del-btn">
                                        <p>{getSelectedCount("locations")}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                setLocations(locations.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                }))
                                                )
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    locations: []
                                                })
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                {
                                    getSelectedCount("statuses") ? <div className="selected-filter-div">
                                        <h4>Status</h4>
                                        <div className="count-del-btn">
                                            <p>{getSelectedCount("statuses")}</p>
                                            <button className="del-btn" onClick={
                                                () => {
                                                    setStatuses(statuses.map(item => ({
                                                        ...item,
                                                        isChecked: false
                                                    }))
                                                    )
                                                    setSelectedFilters({
                                                        ...selectedFilters,
                                                        statuses: []
                                                    })
                                                }
                                            } >
                                                <img src={TrashIcon} alt="delete" />
                                            </button>
                                        </div>
                                    </div> : ""
                                }

                                {
                                    getSelectedCount("parameters") ? selectedFilters.parameters.map((item, index) => (
                                        <div key={index} className='selected-filter-div'>
                                            <h4>{item.name}</h4>
                                            <div className='count-del-btn fre-filter form-group'>
                                                <div>
                                                    <div className='flex items-center border-col px-3 rounded '>
                                                        <input type='text' id={`from${item}`} onChange={
                                                            (e) => { handleParameterChange(item.id, e.target.value, 'from') }}
                                                            value={item.from || ''}
                                                            className=' py-3  text-md border fre-input' placeholder='well'
                                                        />
                                                        <span className='unit'>Hz</span>
                                                    </div>
                                                    <p className='gap'>-</p>
                                                    <div className='flex items-center border-col px-3 rounded '>
                                                        <input type='text' id={`to${item}`}
                                                            onChange={
                                                                (e) => {
                                                                    handleParameterChange(item.id, e.target.value, 'to');
                                                                }}
                                                            value={item.to || ''}
                                                            className=' py-3 mr-3 border text-md fre-input '
                                                            placeholder=' well'
                                                        />
                                                        <span className='unit'>Hz</span>
                                                    </div>
                                                </div>
                                                <button className="del-btn" onClick={
                                                    () => {
                                                        setParameters(parameters.map(parameter => {
                                                            return parameter.id.toString() === item.id ? {
                                                                ...parameter,
                                                                isChecked: false
                                                            } : parameter
                                                        }))
                                                        setSelectedFilters(prev => ({
                                                            ...prev,
                                                            parameters: prev.parameters.filter(parameter => parameter.id !== item.id)
                                                        })
                                                        )
                                                    }

                                                }>
                                                    <img src={TrashIcon} alt="delete" />
                                                </button>
                                            </div>


                                        </div>
                                    )) : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={closeModal} className="btn btn-secondary btn-lg CR-btn">Clear all</button>
                    <button disabled={!isFormValid} onClick={() => {
                        validateWatchlist() &&
                            (activeWell?.id ? updateBtnHandler(selectedFilters) :
                                createBtnHandler(selectedFilters))
                    }
                    } className={`btn btn-primary btn-lg CR-btn ${isFormValid ? '' : 'btn-disabled'}`}>{activeWell?.id ? "Update Watchlist" : "Save watchlist"}</button>
                </div>
            </div>
        </div>
    )
}

export default BuildWatchlistFiltersModal
