import React, { useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import BackPressureModal from './modals/BackPressureModal';
import { BackPressureDetailsProps } from './interfaces/backPressure.interface';
import { bandsData } from './constants/backPressure.constants';
import BackPressureChart from './charts/BackPressureChart';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import { generatePDF } from '../../common';

const CHART_NAME = 'back-pressure';

const BackPressureDetails: React.FC<BackPressureDetailsProps> = ({ heading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const backPressureChartdiv1 = useRef<HTMLDivElement | null>(null);
  const backPressureChartdiv2 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');
  const [data, setData] = useState<{
    valveRating: TimeseriesData | AssetTimeseriesData;
  }>({
    valveRating: {} as TimeseriesData | AssetTimeseriesData,
  });
  const [showLoading, setShowLoading] = useState(true);

  const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const userPreference = useAppSelector((state) => state.controlroom.userPreference)?.find(item => item.key === CHART_NAME);
  const assetInfo = useAppSelector((state) => state.assetInfo.assetDetails);
  const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);

  useEffect(() => {
    if (loading) {
        setShowLoading(true);
    } else {
        const timer = setTimeout(() => setShowLoading(false), 100);
        return () => clearTimeout(timer);
    }
}, [loading]);

  const root = useRef<any>(null);
  const root2 = useRef<any>(null);

  const backPressureChannelData = {
    valveRating: useAppSelector(getChannelDataByKey(channelIDsObj.ambient || '')),
  }

  const backPressureTimeSeriesData = {
    valveRating: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.ambient || '')),
  }

  useEffect(() => {
    if (assetInfo?.isSleAsset) {
      setData(backPressureTimeSeriesData);
      setErrorType(userPreference?.mappings?.sle?.ambient ? false : true);
    } else {
      setData(backPressureChannelData);
      setErrorType(channelIDsObj.ambient ? false : true);
    }

  }, [])


  const openModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };
  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const printWidgetAsPDF = () => {
    generatePDF(
      [root],
      setIsDropdownOpen,
      currentUser,
      heading,
      { width: 400, hight: 160 }
    );
  };

  return (
    <>
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          {userPreference?.expandable && <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>}
          {userPreference?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName ${(data?.valveRating === undefined) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                {/* <button className={`btn btn-default cancelSelectorName ${(data?.valveRating === undefined) ? 'btn-disabled' : ''}`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button> */}
              </div>
            )}
          </div>}
        </div>
        <hr />
        <div id="backPressure-container" className='flex flex-wrap items-center justify-center wellChartSection backPressureChart-container-dashboard nodata'>

          {showLoading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : data?.valveRating ? (
            <>
              <BackPressureChart value={+data?.valveRating?.value || 0} chartID={'backPressureChartdiv1'} chartRef={backPressureChartdiv1} classValue={'w-[100%]'} minValue={0} maxValue={1500} clockHandValue={600} clockHandName={'55 %'} startAngle={-220} endAngle={40} innerCircleEndvalue={1500} bandsData={bandsData} spacingValue={25} root={root} />
            </>
          ) : (<NoData 
          errorImgType={`${errorType ? 'warning' : ''}`} 
          heading='Data not configured' 
          description="The data for this widget is not configured." />)}

        </div>
      </div>
      <BackPressureModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={'Back pressure'}
        loading={showLoading}
        modelWidth={'60rem'}
        modelClass={'wellChartModal backPressureModal'}
        innerComponent={
          <>
            {showLoading ? (
              <div className='flex items-center justify-center w-100'>
                <Loader />
              </div>
            ) : data?.valveRating ? (
              <>
                <BackPressureChart value={+data?.valveRating?.value || 0} chartID={'backPressureChartdiv2'} chartRef={backPressureChartdiv2} classValue={'w-[100%]'} minValue={0} maxValue={1500} clockHandValue={600} clockHandName={'55 %'} startAngle={-220} endAngle={40} innerCircleEndvalue={1500} bandsData={bandsData} spacingValue={25} root={root2} />
              </>
            ) : (<NoData errorImgType={`${errorType ? 'warning' : ''}`} heading='Data not configured' description="The data for this widget is not configured." />)}
          </>
        }
      />
    </>
  );
};

export default BackPressureDetails;