


import React, { useEffect, useState } from 'react'
import ChevronDown from '../../../images/chevron-down.svg'
import Heart from '../../../images/heart.svg'
import ArrowUp from '../../../images/arrow-up-black.svg'
import SpeedoMeter from '../../../images/speedometer-01.svg'
import LineChartUp from '../../../images/line-chart-up-01.svg'
import Lightning from '../../../images/lightning-01.svg'



import '../components/AssetControlRoomHeader.scss'
import Tooltip from '../../common/tooltip/ToolTip'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { updateAssetControlRoomDrawerProps } from '../../asset-control-room-navigation-drawer/AssetControlRoomDrawerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchHealthScore } from '../../health-score/HealthScoreSlice'

const AssetControlRoomHeader = () => {
    const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
    const [activeBtn, setActiveBtn] = useState('assetDetail');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { healthScore } = useAppSelector((state) => state.healthScore)
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const assetName = selectedAsset.selectedAssetName ?? (selectedWellName && JSON.parse(selectedWellName || '')?.assetName || '');


    useEffect(() => {
        if (assetName) {
            dispatch(fetchHealthScore(assetName))
        }
    }, [])

    const handleControlRoomsClick = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: 'controlRoom' }));
        navigate("/layout/well-control-room");
    }
    // const handleHealthScoreClick = () => {
    //     dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'healthScore' }))
    //     navigate("/layout/well-control-room")
    // }
    const handleAssetDetailsClick = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: null }))
        navigate("/layout/assets");
    }
    /*  const handlemanageAssetClick = () => {
         dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'manageAsset' }))
         navigate("/layout/well-control-room/")
     } */

    useEffect(() => {
        if (location.pathname === '/layout/well-control-room' && activeBtn !== 'manageAsset') {
            setActiveBtn('controlRoom');
        } else if (location.pathname === '/layout/assets' && activeBtn !== 'manageAsset') {
            setActiveBtn('assetDetail');
        }

    }, [location])

    return (
        <div className='asset-control-room-header m-0'>
            <div className='content card m-0 w-100 flex flex-wrap flex-row justify-between items-center'>
                <div className='left-header-section flex flex-wrap items-center'>
                    <div className='ticket-count-card card '>
                        <Tooltip content='Coming soon' direction='bottom'>
                            <div className="flex justify-between items-center">
                                <div className='ticket-number'>
                                    # 1291-23
                                </div>
                                <div className='ticket-count flex justify-center items-center'>
                                    5
                                </div>
                                <span><img src={ChevronDown} /></span>
                            </div>
                        </Tooltip>
                    </div>
                    <Tooltip content='Coming soon' direction='bottom'>
                        <div className='health-score-card card flex justify-between items-center' /*onClick={handleHealthScoreClick}*/>
                            <span><img src={Heart} /></span>
                            <span className='health-score'>{typeof healthScore?.healthScore === 'number' ? healthScore?.healthScore : ''}</span>
                            <span className='health-score-label'>Health score</span>

                            <div className='health-score-status justify-center flex items-center'>
                                {healthScore ? <img src={ArrowUp} /> : ''} {healthScore?.trendDisplayText || ''}
                            </div>
                        </div>
                    </Tooltip>
                </div>
                <div className={`right-header-section flex flex-row items-center mr-2 ${assetControlRoomDrawer.openState ? 'squeez-btngroup' : ""}`}>
                    <div className='toggle-section card flex flex-row items-center'>
                        <div className={`button ${activeBtn === 'controlRoom' ? 'active' : ''}`} onClick={() => { handleControlRoomsClick(); setActiveBtn('controlRoom') }}>
                            <img src={SpeedoMeter} />{assetControlRoomDrawer.openState ? '' : "Control room"}
                        </div>
                        <div className='vertical-divider'></div>
                        <div className={`button ${activeBtn === 'assetDetail' ? 'active' : ''}`} onClick={() => { handleAssetDetailsClick(); setActiveBtn('assetDetail') }}>
                            <img src={LineChartUp} /> {assetControlRoomDrawer.openState ? '' : 'Asset details'}
                        </div>
                    </div>
                    <Tooltip content='Coming soon' direction='bottom'>
                        <div className='manage-asset card opacity-[0.8]'>
                            <div className={`button ${activeBtn === 'manageAsset' ? 'active' : ''}`} /* onClick={() => { handlemanageAssetClick(); setActiveBtn('manageAsset') }} */>
                                <img src={Lightning} />{assetControlRoomDrawer.openState ? '' : 'Manage asset'}
                            </div>
                        </div>
                    </Tooltip>

                </div>
            </div>
        </div>
    )
}

export default AssetControlRoomHeader