import React, { useState, useEffect } from "react";
import { ReactComponent as AlertIcon } from "../../../../../images/alert-circle.svg";
import { UserItem } from "../userService";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { useFormChangeDetection } from "../../../common/formChangeDetection/formChangeDetection";
import Tooltip from "../../../common/tooltip/tooltip";
import Loader from "../../../common/loader/loader";
import Dropdown from "../../../common/dropdown/dropdown";
import ChangePassword from "../password/changePassword";
import SuccessfullToast from "../../../common/toast/successfulToast";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog";
import { fetchPrivileges } from "../userSlice";



interface AddUserProps {
  addUser?: (newUser: UserItem) => void;
  editUser?: (updatedUser: UserItem) => void;
  userToEdit?: UserItem | null;
}


const AddUser: React.FC<AddUserProps> = ({ userToEdit }) => {
  const dispatch = useAppDispatch();
  const privileges = useAppSelector((state) => state.OnboardingUser.privileges);
  const users = useAppSelector((state) => state.OnboardingUser.users);
  const userSaveLoading = useAppSelector(
    (state) => state.OnboardingUser.userSaveLoading
  );
  const customerNames = useAppSelector((state) => state.OnboardingCustomer.customer);


  const customerItems = customerNames
    .map((customer) => ({
      id: customer.id,
      name: customer.name || "",
    }))
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

  const initialFormData = {
    id: "",
    createdBy: "",
    createdOnDate: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    UserId: "",
    cnX_UserId: null,
    customerName: "",
    customerId: "",
    isEnabled: false,
    userType: "",
    userName: "",
    password: "",
    contactId: "",
    lastLogin: "",
    grafaEditor: "",
    license: {
      licenseType: "",
      expirationDate: "",
    },
    b2cdomainname: "",
    mostCommonPreferences: "",
    mustChangePassword: true,
    sso: true,
    userPreference: {
      id: "",
      createdBy: "",
      createdOnDate: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
      userId: "",
      preferenceItem: {
        groupName: "",
        propertyItem: [],
      },
    },
    userRoles: {
      id: "",
      createdBy: "",
      createdOnDate: "",
      lastModifiedBy: null,
      lastModifiedDate: "",
      roleIds: [],
      userId: "",
    },
    pumpChecker: {
      firstName: "",
      lastName: "",
      isEnabled: false,
      role: "",
      email: "",
    },
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<UserItem>(initialFormData);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const { setOriginalFormData, hasChanges } =
    useFormChangeDetection<UserItem>(null);

  useEffect(() => {

    if (!privileges)
      dispatch(fetchPrivileges());
  }, [privileges])


  useEffect(() => {
    if (userToEdit) {
      setFormData({
        ...userToEdit,
        id: userToEdit.id.toString(),
        license: userToEdit.license || [
          {
            licenseType: "",
            expirationDate: "",
          },
        ],
        // pumpChecker: {
        //   firstName: userToEdit?.firstName || "",
        //   lastName: userToEdit.lastName || "",
        //   isEnabled: userToEdit.isEnabled || false,
        //   role: userToEdit.pumpCheckerRole || "",
        //   email: userToEdit.userName || "",
        // },
        // pumpChecker: 
      });
      setOriginalFormData({ ...userToEdit });
      setIsEditMode(true);
      // openModal();
    }
  }, [userToEdit, setOriginalFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};


    if (!isEditMode) {
      isValid =
        formData.userName.trim() !== "" &&
        formData.customerName?.trim() !== "" &&
        formData.pumpChecker.firstName.trim() !== "" &&
        formData.pumpChecker.lastName.trim() !== "" &&
        formData.pumpChecker.role.trim() !== "" &&
        formData.userPreference?.preferenceItem?.groupName.trim() !== "";

      // if (!formData.sso && isXSPOCAuthorization) {
      if (!formData.sso) {

        isValid =
          isValid &&
          formData.password.trim() !== "" &&
          confirmPassword.trim() !== "";

        if (formData.password && formData.password.length < 5) {
          newErrors.password =
            "Password needs to be at least 5 characters long";
        }

        if (confirmPassword && formData.password !== confirmPassword) {
          newErrors.confirmPassword = "Passwords do not match";
        }
      }

      if (
        formData.userName &&
        users.some(
          (user) =>
            user.userName &&
            user.userName.toLowerCase() === formData.userName.toLowerCase()
        )
      ) {
        newErrors.userName = "User name already exists";
      }

      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // if (isCloud) {
      //   if (
      //     formData.userName &&
      //     users.some(
      //       (user) =>
      //         user.userName &&
      //         user.userName.toLowerCase() === formData.userName.toLowerCase()
      //     )
      //   ) {
      //     newErrors.userName = "User name already exists";
      //   }
      // } else {
      //   if (formData.userName && !emailPattern.test(formData.userName)) {
      //     newErrors.userName = "Please enter a valid email address";
      //   }
      // }
    }

    if (formData.sso && !formData.b2cdomainname) {
      newErrors.b2cdomainname =
        "B2CDomain Name is required when SSO is enabled";
    }

    setErrors(newErrors);
    setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [
    formData,
    confirmPassword,
    users,
    isEditMode,
  ]);


  // const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    // setIsModalOpen(false);
    setFormData(initialFormData);
    setConfirmPassword("");
    setErrors({});
    setIsEditMode(false);
    // setUserSearchDropdownItems([]);
    // setInputValue("");
  };

  // const openAddUserModal = () => {
  //   setIsEditMode(false);
  //   setOriginalFormData(initialFormData);
  //   setFormData(initialFormData);
  //   openModal();
  //   // setUserSearchDropdownItems([]);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => {
      let updatedFormData: any = { ...prevState };

      if (name.startsWith("pumpChecker.")) {
        const pumpCheckerField = name.split(".")[1];
        updatedFormData = {
          ...prevState,
          pumpChecker: {
            ...prevState.pumpChecker,
            [pumpCheckerField]: type === "checkbox" ? checked : value.trim(),
          },
        };
      } else {
        updatedFormData[name] = type === "checkbox" ? checked : value.trim();
      }

      if (name === "userName") {
        updatedFormData.pumpChecker.email = value.trim();
      }

      if (name === "isEnabled") {
        updatedFormData.pumpChecker.isEnabled = checked;
      }

      if (name === "sso") {
        updatedFormData.b2cdomainname = checked
          ? ""
          : updatedFormData.b2cdomainname;
        if (checked) {
          updatedFormData.password = "";
          setConfirmPassword("");
        }
      }

      return updatedFormData;
    });
  };

  // const customNoResultsMessage = (
  //   <>
  //     <p className="no-customer-found">Customer not listed?</p>
  //     <span className="add-new-customer">
  //       <AddCustomer
  //         // addCustomer={addCustomerHandler}
  //         triggerType="button" />
  //     </span>
  //   </>
  // );

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();


  };



  const openPasswordModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };



  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeModal();
  };

  const shouldShowPasswordFields = !formData.sso

  return (
    <>
      {/* <Tooltip content="Add new user" direction="top">
        <span className="add-user-icon" onClick={openAddUserModal}>
          <AddUserIcon />
        </span>
      </Tooltip> */}

      {/* <Modal isOpen={isModalOpen} onClose={closeModal}> */}
      <div style={{ position: "relative" }} className="onboard-modal">
        {userSaveLoading && (
          <div className="spinner-overlay">
            <Loader isOverlay={true} />
          </div>
        )}
        <form className="add-user-form onboarding-form" onSubmit={handleUserSubmit}>
          <div className="onboard-form-row onboard-modal-header" style={{ marginBottom: "10px" }}>
            <label style={{ fontSize: "20px" }}>
              {isEditMode ? "Edit user" : "Add new user"}
            </label>
          </div>
          <div className="onboard-modal-content">
            <div className="onboard-form-row grid grid-cols-2">

              <div className="onboard-form-group ">
                <label>
                  <span>Customer name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <Dropdown
                  items={customerItems}
                  defaultOption={
                    customerItems.length > 0
                      ? customerItems[0].name
                      : "Select customer"
                  }
                // onChange={(item) =>
                //   handleDropdownChange(item, "customerName")
                // }
                // disabled={isEditMode : true}
                // noResultsMessage={isCloud ? customNoResultsMessage : <></>}
                />
              </div>
              <div className="onboard-form-group ">

                <label>
                  <span>User name:</span>
                </label>
                <input
                  type="text"
                  // name="pumpChecker.lastName"
                  // value={formData.pumpChecker.lastName}
                  // onChange={handleInputChange}
                  autoComplete="off"
                />

              </div>
              <div className="onboard-form-group">
                <label>
                  <span>First name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="pumpChecker.firstName"
                  value={formData.pumpChecker.firstName}
                  // onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="onboard-form-group">
                <label>
                  <span>Last name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="pumpChecker.lastName"
                  value={formData.pumpChecker.lastName}
                  // onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="onboard-form-group">
                <label>
                  <span>Job title:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <input
                  type="text"
                  id='jobTitle'
                  autoComplete="off"
                />
              </div>

              <div className="onboard-form-group">
                <label>
                  <span>Email address:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <input
                  type="text"
                  id='email'
                  autoComplete="off"
                />
              </div>
              <div className="onboard-form-group">
                <label>
                  <span>Mobile phone number:</span>
                </label>
                <div className="onboard-number-input">
                  <input
                    type='text'
                    id='countryCodeMobile'
                    autoComplete="off"
                    className="code-input"
                  />
                  <input
                    type="text"
                    id='mobileNumber'
                    autoComplete="off"
                    className="number-input"
                  />
                </div>
              </div>


              <div className="onboard-form-group">
                <label>
                  <span>Office phone number:</span>
                </label>
                <div className="onboard-number-input">
                  <input
                    type='text'
                    id='officeCodeMobile'
                    autoComplete="off"
                    className="code-input"
                  />
                  <input
                    type="text"
                    id='officeNumber'
                    autoComplete="off"
                    className="number-input"
                  />
                </div>
              </div>
              <div className="onboard-form-group ">
                <label>
                  <span>Extension:</span>
                </label>
                <input
                  type="text"
                  id='extension'
                  autoComplete="off"
                />
              </div>

              <div className="onboard-form-group " >
                <label>
                  <span>Pager:</span>
                </label>
                <input
                  type="text"
                  id='pager'
                  autoComplete="off"
                />
              </div>


              <div className="onboard-form-group form-group-inline">
                <label htmlFor="sso">SSO : </label>
                <input
                  type="checkbox"
                  id="sso"
                  name="sso"
                  checked={
                    formData.sso
                  }
                  onChange={handleInputChange}
                  style={{ width: "15px" }}
                  className="settings-checkbox-input ms-2"
                />
              </div>

              {shouldShowPasswordFields && (
                <>
                  {isEditMode ? (
                    <span className="form-change-password-button">
                      <div className="onboard-form-group">
                        <button
                          className="change-password-button"
                          onClick={openPasswordModal}
                        >
                          Change password
                        </button>
                        <ChangePassword
                          isOpen={isPasswordModalOpen}
                          onClose={closePasswordModal}
                          id={formData.id}
                          userName={formData.userName}
                          cnX_UserId={formData.cnX_UserId || null}
                        />
                        <div className="form-group-password-check">
                          <input
                            type="checkbox"
                            id="changePassword"
                            name="mustChangePassword"
                            checked={formData.mustChangePassword}
                            // onChange={handleInputChange}
                            className="checkbox-input"
                            disabled={
                              formData.sso ||
                              formData.b2cdomainname?.trim() !== ""
                            }
                          />
                          <label htmlFor="changePassword">
                            User must change password at first login
                          </label>
                        </div>
                      </div>
                    </span>
                  ) : (
                    <>
                      <div className="onboard-form-group">
                        <label>
                          <span>Password:</span>
                          {!formData.sso && (
                            <span className="required-asterisk">*</span>
                          )}
                        </label>
                        <div className="alert-icon-container">
                          <input
                            type="password"
                            name="password"
                            value={formData.password}
                          // onChange={handleInputChange}
                          />
                          {errors.password && (
                            <span className="error-icon">
                              <Tooltip content={errors.password} direction="top">
                                <AlertIcon />
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      </div>
                      {!isEditMode ? (
                        <div className="onboard-form-group">
                          <label>
                            <span>Confirm password:</span>
                            {!formData.sso && (
                              <span className="required-asterisk">*</span>
                            )}
                          </label>
                          <div className="alert-icon-container">
                            <input
                              type="password"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {errors.confirmPassword && (
                              <span className="error-icon">
                                <Tooltip
                                  content={errors.confirmPassword}
                                  direction="top"
                                >
                                  <AlertIcon />
                                </Tooltip>
                              </span>
                            )}
                          </div>
                          <div className="form-group-password-check">
                            <input
                              type="checkbox"
                              id="changePassword"
                              name="mustChangePassword"
                              checked={formData.mustChangePassword}
                              // onChange={handleInputChange}
                              className="checkbox-input"
                              disabled={formData.sso}
                            />
                            <label htmlFor="changePassword">
                              User must change password at first login
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </div>


            <div className="onboard-user-security" >
              <label>User security settings:</label>
              <span className="form-user-roles ">
                {privileges?.map((item) => {
                  const roleKey = item.value.replace(" ", "")
                  // const role = Role[roleKey as keyof typeof Role] as number;
                  return (
                    <div className="settings-form-group flex gap-x-1" key={roleKey}>
                      <input
                        id={`roles-${roleKey}`}
                        type="checkbox"
                        className="settings-checkbox-input"
                      />
                      <label
                        htmlFor={`roles-${roleKey}`}
                        className="settings-label"
                      >
                        {item.value}
                      </label>
                    </div>
                  );
                })}

              </span>
            </div>
            <div className="form-mandatory-message">
              <p className="mandatory-fields-message">
                Fields marked with<span className="required-asterisk">*</span>{" "}
                are mandatory.
              </p>
            </div>
          </div>
          <div
            className="onboard-form-row onboard-modal-footer">
            <button
              type="button"
              className="cancel-button"
            // onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="add-user-button"
              disabled={
                isEditMode
                  ? !isFormValid || !hasChanges(formData)
                  : !isFormValid
              }
            >
              {isEditMode ? "Save Changes" : "Add User"}
            </button>
          </div>
        </form>
      </div>
      {/* </Modal> */}
      {showToast && (
        <SuccessfullToast
          message={"toastMessage"}
          isShowEnabled={showToast}
          setShow={setShowToast}
          // colorText={textcolor}
          colorText={"green"}
        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </>
  );
};

export default AddUser;
