import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { updateWidgetLayout } from './slices/CustomizeControlroomSlice';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import './CustomizeControlRoom.scss';
import { fetchUserPrefrence, setIsToggleGetTriggered } from '../../controlroom.slice';
import { UserPreferenceDataItem } from '../interfaces/userPreference.interface';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';

const CustomizeControlRoom = () => {
  const dispatch = useAppDispatch();
  const userPrefrenceData = useAppSelector((state) => state.controlroom.userPreference);
  const [items, setItems] = useState<UserPreferenceDataItem[]>(userPrefrenceData);

  useEffect(() => {
    if (userPrefrenceData && userPrefrenceData.length > 0) {
      setItems(userPrefrenceData);
    }
  }, [JSON.stringify(userPrefrenceData)]);

  const handleToggleChange = async (itemKeyText: string) => {
    try {
      const itemKey = itemKeyText.toLowerCase();

      const updatedItems = items && items?.map((item: any) => {
        if (item.key.toLowerCase() === itemKey) {
          return {
            ...item,
            show: !item.show, // Toggle visibility
          };
        }

        return item;

      });

      dispatch(setIsToggleGetTriggered(true))
      // Dispatch updated layout items to the API
      const response = await dispatch(updateWidgetLayout({ dashboard: "asset-control-room", data: updatedItems }));
      // if update was successful (response status is 200)
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        await dispatch(fetchUserPrefrence("asset-control-room"));
        showToaster("Layout updated successfully", "success");
      } else {
        showToaster("Failed to update layout", "error");
        console.error("Update failed with non-200 status.");
      }
    } catch (error) {
      console.error("Error updating layout:", error);
    }
  };


  return (
    <div className='customizeControlRoom'>
      {
        items?.map((item, index) => (
          <div key={index}>
            <div className='flex justify-start px-4 py-2 mb-3 rounded-md text-[#B8C5CC] bg-[#131F2E] controlRoomSwitcher-box'>
              <div className='pr-3 controlRoomSwitcher-content flex-grow'>
                <h3 className='text-left text-[16px] font-semibold p-0 m-2 mb-2 text-[#F7F9F9] heading-h3'>{item.title}</h3>
                {/* <p className='text-left text-[#B8C5CC] text-sm  p-0 m-0 mb-5 description'>{item.description}</p> */}
              </div>
              <div className='min-w-[60px] flex justify-center items-center controlRoomSwitcher-switcher'>
                <ToggleSwitch checked={item.show} onChange={() => handleToggleChange(item.key)} label={''} />
              </div>
            </div>

          </div>
        ))
      }

    </div>
  )
}

export default CustomizeControlRoom;