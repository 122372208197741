import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { useAppSelector } from '../../../../hooks/storeHooks';
import weatherImage from '../../../../images/Weather image.png';
import alarmIcon from '../../../../images/alarm-icon.png';
import warningIcon from '../../../../images/warning-icon.png';
import defaultIcon from '../../../../images/default-map-icon.png';
import Close from '../../../../images/x-close.svg';
import { AssetEventSchedule } from '../../model/AssetEventSchedule';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoLocation from '../../../../images/no-location.png';
import { ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';

interface TabsProps {
  groupName?: string;
}
const AssetMap: React.FC<TabsProps> = () => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyBdGrNpaLM_WTXPpNGrBjCkTyVk-c9zyOU',
  });

  const assetEventSchedule = useAppSelector((state) => state.dashboard.assetEventSchedule) || [];
  const { showAlarms, showWeather } = useAppSelector((state) => state.alarms);
  const [selectedMarker, setSelectedMarker] = useState<AssetEventSchedule | null>(null);
  const listLoading = useAppSelector((state) => state.dashboard.assetListLoading);
  const newMarkers: AssetEventSchedule[] = assetEventSchedule.map((event) => ({
    ...event,
    lat: parseFloat(event?.latitude),
    lng: parseFloat(event?.longitude),
    assetType: event.assetType,
  }));

  const validMarkers = newMarkers.filter(
    (marker) => !isNaN(marker.lat ?? 0) && !isNaN(marker.lng ?? 0)
  );

  const [markers, setMarkers] = useState<AssetEventSchedule[]>(validMarkers);
  const weatherLatitude = assetEventSchedule?.length > 0 ? parseFloat(assetEventSchedule[4]?.latitude) : 0;
  const weatherLongitude = assetEventSchedule?.length > 0 ? parseFloat(assetEventSchedule[4]?.longitude) : 0;

  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    setMarkers(validMarkers);
  }, [newMarkers?.length]);

  useEffect(() => {
    const visibleMarkers = showAlarms ? validMarkers.filter((m) => m.assetType === 'Alarm') : validMarkers;
    setMarkers(visibleMarkers);
  }, [showAlarms]);

  useEffect(() => {
    assetEventSchedule.length == 0 && setSelectedMarker(null);
  }, [assetEventSchedule.length]);

  const fitBounds = () => {
    if (mapRef.current && markers.length > 0 && isLoaded) {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend({ lat: marker.lat || 0, lng: marker.lng || 0 });
      });
      mapRef.current.fitBounds(bounds);
      google.maps.event.addListenerOnce(mapRef.current, 'bounds_changed', () => {
        const currentZoom = mapRef.current?.getZoom();
        if (currentZoom !== undefined) {
          mapRef.current?.setZoom(currentZoom > 10 ? 10 : currentZoom);
        }
      });
    }
  }

  useEffect(() => {
    fitBounds();
  }, [JSON.stringify(fitBounds), markers]);

  const getMarkerIcon = (assetType: string) => {
    switch (assetType) {
      case 'Alarm':
        return alarmIcon;
      case 'Warning':
        return warningIcon;
      default:
        return defaultIcon;
    }
  };

  const handleMarkerClick = (marker: AssetEventSchedule) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className='card assetList-map' style={{ border: '1px solid #ddd', padding: '5px' }}>
          {listLoading ?
            <div style={{ height: 400 }}>
              <Loader />
            </div>
            :
            markers.length === 0 && !listLoading ? (
              <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={NoLocation} alt='No data' />
              </div>
            )
              : (!listLoading && markers.length > 0 && isLoaded) &&
              <GoogleMap
                mapContainerStyle={{ height: '400px', width: '100%', display: !listLoading && markers.length > 0 ? "block" : "none" }}
                onLoad={(map) => {
                  mapRef.current = map;
                  fitBounds();
                }}
                zoom={2}
              >
                {markers?.map((marker, index) => (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
                    icon={getMarkerIcon(marker.assetType)}
                    onClick={() => handleMarkerClick(marker)}
                  />
                ))}

                {showWeather && (
                  <OverlayView
                    position={{ lat: weatherLatitude, lng: weatherLongitude }}
                    mapPaneName={OverlayView.OVERLAY_LAYER}
                  >
                    <img src={weatherImage} alt='Weather' style={{ height: '100px', width: '100px' }} />
                  </OverlayView>
                )}
              </GoogleMap>
          }
        </div>
        {selectedMarker && <div className='marker-detail-container'>
          <div className='head-container'>
            <p className='wellname'>{selectedMarker?.wellName}</p>
            <img src={Close} alt='close' style={{ cursor: 'pointer' }} onClick={handleInfoWindowClose} />
          </div>

          <hr />

          <div className='marker-info-container'>
            <span className='api-text'>
              API: <span className='api-value'>{selectedMarker?.api}</span>
            </span>
            <span className='api-text'>
              Lat: <span className='api-value'>{selectedMarker?.lat?.toFixed(4)}</span>
            </span>
            <span className='api-text'>
              Long: <span className='api-value'>{selectedMarker?.lng?.toFixed(4)}</span>
            </span>
          </div>

          <div className='marker-table'>
            <div className='marker-table-column'>
              <div>Last good scan</div>
              <div>{selectedMarker?.lastGoodScan ? ConvertTimeToLocal(selectedMarker?.lastGoodScan) : ""}</div>
            </div>

            <div className='marker-table-column'>
              <div>Operator</div>
              <div>{selectedMarker?.operator}</div>
            </div>

            <div className='marker-table-column'>
              <div>Alarms</div>
              <div>{selectedMarker?.alarms}</div>
            </div>

            <div className='marker-table-column'>
              <div>Run status</div>
              <div>{selectedMarker?.runStatus}</div>
            </div>

            <div className='marker-table-column'>
              <div>Current</div>
              <div>{selectedMarker?.current}</div>
            </div>

            <div className='marker-table-column'>
              <div>Frequency</div>
              <div>{selectedMarker?.frequency}</div>
            </div>

            <div className='marker-table-column'>
              <div>Temperature</div>
              <div>{selectedMarker?.temperature}</div>
            </div>

            <div className='marker-table-column'>
              <div>High winding temperature</div>
              <div>{selectedMarker?.highWindingTemperature}</div>
            </div>

            <div className='marker-table-column'>
              <div>Intake pressure</div>
              <div>{selectedMarker?.intakePressure}</div>
            </div>

            <div className='marker-table-column'>
              <div>Tubing pressure</div>
              <div>{selectedMarker?.tubingPressure}</div>
            </div>

            <div className='marker-table-column'>
              <div>Vibration</div>
              <div>{selectedMarker?.vibration}</div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default AssetMap;
