import React, { useState, useEffect } from 'react';
import CustomModal from '../ExpandModel';
import Close from '../../../../../images/x-close.svg';
import Info_Circle from '../../../../../images/error-info-icon.svg';
import calendarIcon from '../../../../../images/calendar-icon.svg';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { DataPoint, WellProductionTrend } from '../../../model/WellProductionTrend';
import WellProductionTrendDropdown from './WellProductionTrendDropdown';
import { WellData } from '../../../model/WellProductionTrend';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { useNavigate } from 'react-router-dom';
import { updateAssetList, updateSelectedAsset } from '../../../../navigation/AssetGroupsSlice';
import NoData from '../../no-data/NoData';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
import { customStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface WellProductionTrendsModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  heading: string;
  data: WellProductionTrend;
  detailsData: WellData[];
  selectedDateRange: any;
  setSelectedDateRange: any;
  selectCategoryOptions: string[];
  selectRegionOptions: string[];
}

// type SortableKeys = keyof WellProductionTrendDetails;
const WellProductionTrendsModel: React.FC<WellProductionTrendsModelProps> = ({
  isOpen,
  onRequestClose,
  heading,
  data,
  detailsData,
  selectedDateRange,
  setSelectedDateRange,
  selectCategoryOptions,
  selectRegionOptions,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const hElement = document.getElementById('main-root');
  const [showCalendar, setShowCalendar] = useState(false);
  const loading = useAppSelector((state) => state.dashboard.wellByProductionDataLoading);

  /*   const [isChecked, setIsChecked] = useState<boolean>(true); */
  const [isSelectOpen1, setIsSelectOpen1] = useState(false);
  const [isSelectOpen2, setIsSelectOpen2] = useState(false);
  const [error, setError] = useState({ show: false, message: '' });
  const [tableData, setTableData] = useState(detailsData);
  const [defaultFilterCategory, setDefaulFilterCategory] = useState<string[]>(selectCategoryOptions);
  const [defaultFilterRegion, setDefaultFilterRegion] = useState<string[]>(selectRegionOptions);
  const [prevFilterCategory, setPrevFilterCategory] = useState<string[]>([]);
  const [prevFilterRegion, setPrevFilterRegion] = useState<string[]>([]);

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDateRange.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDateRange.endDate);

  const initializeChart = (chartId: string, data: WellProductionTrend) => {
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    // Create a main container to hold both the chart and the legend
    const mainContainer = root.container.children.push(
      am5.Container.new(root, {
        layout: root.verticalLayout,
        width: am5.percent(100),
        height: am5.percent(100),
      }),
    );

    // Create the chart container
    const chartContainer = mainContainer.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(80), // Adjust height to leave space for the legend
      }),
    );

    const chart = chartContainer.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: false,
        panY: false,
        wheelY: 'none',
        wheelX: 'none',
        pinchZoomX: false,
        pinchZoomY: false,
        paddingBottom: 0,
      }),
    );

    // Additionally, you can also disable zoomOutButton
    chart.zoomOutButton.set('forceHidden', true);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        extraMax: 0,
        extraMin: 0,
        baseInterval: { timeUnit: 'day', count: 1 },
        min: startDate.getTime(),
        max: endDate.getTime(),
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 100,
          minorGridEnabled: true,
          stroke: am5.color('#487aa6'),
        }),
        dateFormats: {
          day: 'MM/dd',
          week: 'MM/dd',
          month: 'MMM',
          year: 'yyyy',
        },
        periodChangeDateFormats: {
          day: 'MM/dd',
          week: 'MM/dd',
          month: 'MMM',
          year: 'yyyy',
        },
        start: 0,
        end: 1,
      }),
    );
    xAxis.get('renderer').labels.template.set('fill', am5.color('#ffffff'));
    xAxis.get('renderer').labels.template.set('paddingTop', 15);

    const yRenderer = am5xy.AxisRendererY.new(root, {
      opposite: false,
      stroke: am5.color('#487aa6'),
    });
    yRenderer.labels.template.set('fill', am5.color('#fff'));
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0,
        extraMin: 0,
        maxDeviation: 0,
        renderer: yRenderer,
      }),
    );
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        behavior: 'none',
        showTooltipOn: 'hover',
      }),
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.setAll({
      visible: true,
      stroke: am5.color('#ADD8E6'),
      strokeWidth: 2,
    });

    cursor.lineY.setAll({
      strokeDasharray: [2, 2],
    });
    function createSeries(data: DataPoint[], strokeColor: string, fillColor: string, name: string) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: `{name}: {valueY} BBL`,
          }),
        }),
      );
      series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
        stops: [{
        opacity: 1
        }, {
        opacity: 0
        }],
        rotation: 90
    }));
    
    series.fills.template.setAll({
        visible: true,
        fillOpacity: 1
    });
      series.set('stroke', am5.color(strokeColor));
      series.set('fill', am5.color(strokeColor));
      series.bullets.push(() => {
        const circle = am5.Circle.new(root, {
          radius: 0,
          fill: am5.color('#0000'),
          stroke: am5.color('#fff'),
          strokeWidth: 5,
          visible: true,
        });

        circle.states.create('hover', {
          radius: 7,
        });

        return am5.Bullet.new(root, {
          locationX: 0.5,
          sprite: circle,
        });
      });

      series.data.setAll(data);

      cursor.events.on('cursormoved', cursorMoved);

      let previousBulletSprites: any = [];
      function cursorMoved() {
        for (let i = 0; i < previousBulletSprites?.length; i++) {
          previousBulletSprites[i].unhover();
        }
        previousBulletSprites = [];
        chart.series.each(function (series) {
          const dataItem = series?.get('tooltip')?.dataItem;
          if (dataItem) {
            const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
            bulletSprite && bulletSprite.hover();
            previousBulletSprites.push(bulletSprite);
          }
        });
      }
    }

    createSeries(data.gasData, '#F97066', '#001326', 'Gas');
    createSeries(data.oilData, '#32D583', '#001326', 'Oil');
    createSeries(data.waterData, '#4294FF', '#001326', 'Water');

    // Create the legend container below the chart
    const legendContainer = mainContainer.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(15), // Adjust height to match the space left by the chart
        layout: root.verticalLayout,
      }),
    );

    const legend = legendContainer.children.push(
      am5.Legend.new(root, {
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true,
        }),
        centerX: am5.percent(50),
        x: am5.percent(50),
        setStateOnChildren: false,
        paddingTop: 10,
        useDefaultMarker: true,
      }),
    );

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 12,
      height: 12,
    });

    legend.labels.template.set('fill', am5.color(0xffffff));
    legend.data.setAll(chart.series.values);
    legend.itemContainers.template.states.create('hover', {});
  };

  useEffect(() => {
    let root: any;
    if (isOpen && (data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0)) {
      setTimeout(() => {
        root = initializeChart('wellProductionTrendsChartModaldiv', data);
      }, 0);
    }
    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [isOpen, data]);

  useEffect(() => {
    if (detailsData?.length > 0) {
      setTableData([...detailsData]);
      setDefaulFilterCategory(selectCategoryOptions);
      setDefaultFilterRegion(selectRegionOptions);
      setPrevFilterCategory(selectCategoryOptions);
      setPrevFilterRegion(selectRegionOptions);
    } else {
      setTableData([]);
      setDefaulFilterCategory([]);
      setDefaultFilterRegion([]);
      setPrevFilterCategory([]);
      setPrevFilterRegion([]);
    }
  }, [detailsData, selectCategoryOptions, selectRegionOptions, isOpen]);

  useEffect(() => {
    setError({ show: false, message: '' });
    setIsSelectOpen1(false);
    setIsSelectOpen2(false);
  }, [isOpen]);

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    hElement && hElement?.classList.remove('modal-open');
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = tableData
      ? tableData?.map((well) => {
        return {
          assetId: '',
          assetName: well?.wellName,
          industryApplicationId: 4,
        };
      })
      : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate('/layout/assets/?tab=details');
  };

  const toggleDropdown1 = () => {
    setError({ show: false, message: '' });
    setIsSelectOpen1(!isSelectOpen1);
  };
  const toggleDropdown2 = () => {
    setError({ show: false, message: '' });
    setIsSelectOpen2(!isSelectOpen2);
  };

  const handleItemClick1 = (value: any) => {
    if (value !== '') {
      setError({ show: false, message: '' });
    }
  };
  const handleItemClick2 = (value: any) => {
    if (value !== '') {
      setError({ show: false, message: '' });
    }
  };

  const handleApplyCategory = (selected: any) => {
    if (selected?.length > 0) {
      const filterCategoryData = detailsData?.filter((item: any) => selected?.includes(item.operator) && defaultFilterRegion?.includes(item?.region));
      if (filterCategoryData?.length > 0) {
        setTableData([...filterCategoryData]);
        setError({ show: false, message: '' });
        setIsSelectOpen1(false);
        setPrevFilterCategory([...selected]);
      }
    } else {
      setError({ show: true, message: 'Please select atleast one option.' });
    }
  };
  const handleApplyRegion = (selected: any) => {
    if (selected?.length > 0) {
      const filterRegionData = detailsData?.filter((item: any) => selected?.includes(item.region) && defaultFilterCategory?.includes(item?.operator));
      if (filterRegionData?.length > 0) {
        setTableData([...filterRegionData]);
        setError({ show: false, message: '' });
        setIsSelectOpen2(false);
        setPrevFilterRegion([...selected]);
      }
    } else {
      setError({ show: true, message: 'Please select atleast one option.' });
    }
  };

  const handleClearCategory = () => {
    setDefaulFilterCategory([]);
  };

  const handleClearRegion = () => {
    setDefaultFilterRegion([]);
  };

  const onClose = () => {
    setError({ show: false, message: '' });
  };

  const handleOperatorOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn' || e?.target?.className.includes("modalToaster")) return;
    if (isSelectOpen1) {
      setError({ show: false, message: '' });
      setIsSelectOpen1(false);
      setDefaulFilterCategory(selectCategoryOptions);

    }
  };

  const handleRegionOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn' || e?.target?.className.includes("modalToaster")) return;
    if (isSelectOpen2) {
      setError({ show: false, message: '' });
      setIsSelectOpen2(false);
      setDefaultFilterRegion(selectRegionOptions);
    }
  };

  const operatorRef = useDetectClickOutside({ onTriggered: handleOperatorOutClick });
  const regionRef = useDetectClickOutside({ onTriggered: handleRegionOutClick });

  return (
    <>
      <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width='65rem' classname='wellProTrends'>
        {error?.show && (
          <div className='modal-toaster modalToaster'>
            <div className='flex justify-center items-center'>
              <img src={Info_Circle} alt='error-icon' className='error-icon modalToaster' />
              <p className='normal-text modalToaster'>{error.message}</p>
            </div>
            <div>
              <button onClick={onClose} className='modalToaster'>
                <img src={Close} alt='Close-icon' />
              </button>
            </div>
          </div>
        )}
        <div className='modal-header'>
          <div className='flex'>
            <div className='grow'>
              <h3>{heading}</h3>
            </div>
            <div className='flex-none'>
              <button onClick={onRequestClose}>
                <img src={Close} alt='Expand' />
              </button>
            </div>
          </div>
        </div>
        <div className='modal-content'>
          <div className='well-production-trends__modal-calendar-container'>
            {showCalendar && (
              <DateRangeSelector
                setShowCalendar={setShowCalendar}
                setSelectedDateRange={setSelectedDateRange}
                staticRanges={customStaticRanges}
                minDate={defineds?.last180Days}
                maxDate={defineds?.endOfToday}
                selectedDateRange={selectedDateRange}
              />
            )}
          </div>
          <div className='well-production-trend-modal_dropdown-container'>
            <div className='flex gap-8'>
              <WellProductionTrendDropdown
                isSelectOpen={isSelectOpen1}
                options={selectCategoryOptions}
                handleToggleDropdown={toggleDropdown1}
                handleItemClick={handleItemClick1}
                handleApplyButton={handleApplyCategory}
                handleClearButton={handleClearCategory}
                filterName='operators'
                defaultFilterValue={defaultFilterCategory}
                setDefaultFilterValue={setDefaulFilterCategory}
                dropdownRef={operatorRef}
                prevFilter={prevFilterCategory}
                key={"category"}
              />
              <WellProductionTrendDropdown
                isSelectOpen={isSelectOpen2}
                options={selectRegionOptions}
                handleToggleDropdown={toggleDropdown2}
                handleItemClick={handleItemClick2}
                handleApplyButton={handleApplyRegion}
                handleClearButton={handleClearRegion}
                filterName='regions'
                defaultFilterValue={defaultFilterRegion}
                setDefaultFilterValue={setDefaultFilterRegion}
                dropdownRef={regionRef}
                prevFilter={prevFilterRegion}
                key={"region"}
              />
            </div>

            <div className='well-production-trends__modal-input-group'>
              <img src={calendarIcon} alt='Range Calendar' />
              <input
                id='range-calendar-input'
                className='input-date'
                value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                onClick={() => setShowCalendar(!showCalendar)}
              />
            </div>
          </div>
          {loading ? (
            <div className='flex justify-center items-center w-100 h-full'>
              <Loader />
            </div>
          ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ? (
            <div>
              <div className='chart-wrap'>
                <div className='pie-container-dashboard'>
                  <div id='wellProductionTrendsChartModaldiv' style={{ width: '100%' }}></div>
                </div>
              </div>
              <div className='toggle-switch'>
                {/* <label className="switch">
                   <input type="checkbox" checked={isChecked} onChange={handleToggle} />
                   <span className="slider"></span>
                   </label>
                   <h2 className='toggle-switch-label'>Virtual Flow Meter</h2> */}
              </div>
              <div className='table-section'>
                <div className='widget-modal' style={{ height: '150px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th className='well-name'>Well name</th>
                        <th className='oil'>Oil</th>
                        <th className='gas'>Gas</th>
                        <th className='water'>Water</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.length > 0 ? (
                        <>
                          {tableData?.map((wellProductionTrend, index) => (
                            <tr key={index}>
                              <td
                                style={{ cursor: 'pointer' }}
                                className='well-name'
                                onClick={() => handleWellName(wellProductionTrend.wellName ?? '', '')}
                              >
                                {wellProductionTrend.wellName}
                              </td>
                              <td className='oil'>{wellProductionTrend.oilData}</td>
                              <td className='gas'>{wellProductionTrend.gasData}</td>
                              <td className='water'>{wellProductionTrend.waterData}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr className='flex items-center justify-center'>
                          <td>No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <NoData heading='No data found' />
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default WellProductionTrendsModel;
