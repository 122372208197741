import React, { useEffect, useState, useRef } from 'react';
import './AssetLocation.scss';
import AssetTabs from './AssetTabs';
import AssetMap from './AssetMap';
import AssetAlarms from './AssetAlarms';
import AssetList, { showToaster } from './AssetList';
import download from '../../../../../src/images/download-icon.svg';
import {  downloadDashboardCSV } from '../../DashboardServices';
import { AppUser } from '../../../user/model/AppUser';
import { ToastContainer } from 'react-toastify';
import { useAppSelector } from '../../../../hooks/storeHooks';
import moment from 'moment-timezone';
import Loader from '../../../common/page-loader/ComponentLoader';

interface WellProductionTrendsProps {
  groupName: string;
}

const AssetLocation: React.FC<WellProductionTrendsProps> = ({ groupName }) => {
  const tabs = [
    { label: 'List', component: AssetList },
    { label: 'Map', component: AssetMap },
    { label: 'Alarms', component: AssetAlarms },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const filterTerm = useAppSelector((state) => state.alarms.filterTerm);
  const assetlistData = useAppSelector((state) => state.dashboard.assetEventSchedule || []);
  const listTimeseriesLoading = useAppSelector((state) => state.dashboard.assetTimeseriesLoading);


  const handleDownloadCSV = () => {
    const userId = storedUser?.id;
    const widgetName = 'AssetEventSchedule';
    const data = { userId, widgetName, groupName, ...filterTerm && { filterTerm } };
    showToaster('Assets CSV download is in progress.!', 'success', false);
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    downloadDashboardCSV(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Lookout-Landing_Page_AssetEventSchedule_' + currentTime + '.csv');
      document.body.appendChild(link);
      link.click();
      showToaster('Assets CSV download is completed.!', 'success');
    }).catch((error) => {
      if (error?.message !== '') {
        showToaster('Assets CSV download is failed. Please try after sometime!', 'error');
      }
    });
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='performanceSnapshot'>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='dashboard-first-block'>
        <div className='card-performance dashboard-card-first'>
          <div className='block-wrap'>
            <div className='dash-content'>
              <div className='para-dashboard '>
                <p className='text-para-dash'>Assets</p>
              </div>
            </div>
            <div className='dashboard-count'>
              { listTimeseriesLoading && (
                <div className='asseListTimeseries'>
                  <Loader />
                </div>
              )}
              <div className='header-icon'>
                <div className='dropdown-container' ref={dropdownRef}>
                  <button className='dropdown-btn' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                    <span className='dot'></span>
                    <span className='dot'></span>
                    <span className='dot'></span>
                  </button>
                  {isDropdownOpen && (
                    <div className='dropdown-options'>
                      <button>Actions</button>
                      <button className={`btn btn-default ${assetlistData.length === 0 ? 'btn-disabled' : ''} `} onClick={handleDownloadCSV}>
                        <img src={download} alt='icon' className='btn-icon' />
                        Download CSV
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='second-header'>
        <AssetTabs tabs={tabs} assetDetails={false} groupName={groupName} />
      </div>
    </div>
  );
};

export default AssetLocation;
