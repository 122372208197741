import { configureStore } from '@reduxjs/toolkit';
import assetsReducer from './features/asset/AssetSlice';
import rodPumpWellStatusReducer from './features/asset-status/AssetStatusSlice';
import uiButtonToolbarReducer from './features/navigation/UIButtonToolbarSlice';
import assetGroupReducer from './features/navigation/AssetGroupsSlice';
import cardDateSlice from './features/rod-lift-analysis/CardDateSlice';
import analysisSlice from './features/rod-lift-analysis/AnalysisSlice';
import notificationSlice from './features/navigation/NotificationsSlice';
import notificationsTypesSlice from './features/navigation/NotificationTypesSlice';
import tableViewSlice from './features/group-status/TableViewSlice';
import groupStatusSlice from './features/group-status/GroupStatusSlice';
import espAnalysisSlice from './features/esp-analysis/ESPAnalysisSlice';
import espWellTestSlice from './features/esp-analysis/WellTestSlice';
import glAnalysisSlice from './features/gl-analysis/GLAnalysisSlice';
import gLAnalysisSurveyDateSlice from './features/gl-analysis/GLAnalysisSurveyDateSlice';
import glAnalysisWellTestSlice from './features/gl-analysis/GLAnalysisWellTestSlice';
import notificationFilterReducer from './features/navigation/NotificationFilterSlice';
import UserSlice from './features/user/UserSlice';
import DashboardSlice from './features/dashboard/DashboardSlice';
import AlarmSlice from './features/dashboard/components/asset-location/AlarmSlice';
import AssetDocumentSlice from './features/asset-details/components/asset-documents/AssetDocumentsSlice';
import SchduledSpeedChangeSlice from './features/asset-details/components/schduled-speed-change/ScheduleSpeedChangeSlice';
import SetPointsSlice from './features/asset-details/components/setpoints/SetPointsSlice';
import navigationSlice from './features/navigation/components/NavigationSlice';
import assetGroupSlice from './features/asset-hierarchy/AssetGroupSlice';
import analysisTabSlice from './features/asset-details/components/analysis/AnalysisSlice';
import EventsSlice from './features/asset-details/components/events/EventsSlice';
import TrendsSlice from './features/asset-details/components/trends/TrendsSlice';
import assetDetailSlice from './features/asset-details/components/details-table/AssetDetailSlice';
import HistorySlice from './features/asset-details/components/history/HistorySlice';
import historyFilterSlice from './features/asset-details/components/history/HistoryFilterSlice';
import AssetControlSlice from './features/asset/AssetControlSlice';
import AssetControlRoomDrawerSlice from './features/asset-control-room-navigation-drawer/AssetControlRoomDrawerSlice';
import healthScoreHistoryReducer from './features/health-score/HealthScoreSlice';
import assetInfoReducer from './features/asset-info/AssetInfoSlice';
import wellWatchListReducer from './features/control-room-dashboard/components/Wells/ControlRoomWellsSlice';
import AssetListReducer from './features/control-room-dashboard/components/Wells/all-wells-component/AllWellSlice';
import CustomizeControlroomReducer from './features/controlroom/components/customize-control-room/slices/CustomizeControlroomSlice';
import sleTimeSeriesSlice from './features/asset-details/components/sle-time-series/SleSlice';
import CommentsSlice from './features/controlroom/components/comments/slices/CommentsSlice';
import wellControlroomChartsReducer from './features/well-controlroom-charts/wellControlroomSlice';
import controlRoomReducer from './features/controlroom/controlroom.slice';
import EquipmentSlice from './features/asset-details/components/equipment/EquipmentSlice';
import SupportSlice from './features/support/supportSlice';
import OnboardingAssetSlice from './features/on-boarding/components/asset-details/onboardingAssetSlice'
import OnboardingCustomerSlice from './features/on-boarding/components/customer-details/customerSlice'
import OnboardingUserSlice from './features/on-boarding/components/user-details/userSlice'

export const store = configureStore({
  reducer: {
    assets: assetsReducer,
    rodliftwellstatus: rodPumpWellStatusReducer,
    uiButtons: uiButtonToolbarReducer,
    assetGroups: assetGroupReducer,
    notificationButtons: uiButtonToolbarReducer,
    notificationType: notificationsTypesSlice,
    rodLiftAnalysis: cardDateSlice,
    rodLiftAnalysisAnalysis: analysisSlice,
    notificationsList: notificationSlice,
    tableView: tableViewSlice,
    groupStatus: groupStatusSlice,
    espAnalysisWellTest: espWellTestSlice,
    espAnalysisResult: espAnalysisSlice,
    glAnalysisData: glAnalysisSlice,
    gLAnalysisSurveyDate: gLAnalysisSurveyDateSlice,
    glAnalysisWellTest: glAnalysisWellTestSlice,
    notificationFilter: notificationFilterReducer,
    navigation: navigationSlice,
    user: UserSlice,
    dashboard: DashboardSlice,
    setPoint: SetPointsSlice,
    alarms: AlarmSlice,
    assetDocument: AssetDocumentSlice,
    assetGroup: assetGroupSlice,
    scheduleSpeedChange: SchduledSpeedChangeSlice,
    analysisTab: analysisTabSlice,
    events: EventsSlice,
    assetDetail: assetDetailSlice,
    trends: TrendsSlice,
    history: HistorySlice,
    historyFilter: historyFilterSlice,
    assetControl: AssetControlSlice,
    sleTimeSeries: sleTimeSeriesSlice,
    assetControlRoomDrawer: AssetControlRoomDrawerSlice,
    healthScore: healthScoreHistoryReducer,
    assetInfo: assetInfoReducer,
    wellWatchList: wellWatchListReducer,
    AssetList: AssetListReducer,
    CustomizeControlroom: CustomizeControlroomReducer,
    Comments: CommentsSlice,
    wellControlroomCharts: wellControlroomChartsReducer,
    controlroom: controlRoomReducer,
    equipmentSlice: EquipmentSlice,
    SupportSlice: SupportSlice,
    OnboardingAsset: OnboardingAssetSlice,
    OnboardingCustomer: OnboardingCustomerSlice,
    OnboardingUser: OnboardingUserSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
