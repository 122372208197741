import { fetchTimeSeriesChannelData, fetchTimeSeriesDataVariables } from "../wellControlroomSlice";

interface FetchDynamicDataParams {
    dispatch: any;
    assetName: string;
    startDate: string;
    endDate: string;
    userPreference: any;
    channelIDsObj: any;
    isSleAsset: boolean;
    chartName: string;
    keys: string[];
}

export const fetchAndProcessTimeSeriesData = async ({
    dispatch,
    assetName,
    startDate,
    endDate,
    userPreference,
    channelIDsObj,
    isSleAsset,
    chartName,
    keys,
}: FetchDynamicDataParams): Promise<{
    result: Record<string, { date: number; value: number }[]>;
    response: Record<string, any>;
}> => {
    const variables = keys.map((key) => (isSleAsset ? userPreference?.mappings?.sle?.[key] : channelIDsObj[key]));

    if (variables.length === 0) return { result: {}, response: {} };

    const action = isSleAsset ? fetchTimeSeriesDataVariables : fetchTimeSeriesChannelData as any;

    const response = await dispatch(
        action({
            assetName,
            startDate,
            endDate,
            ...(isSleAsset
                ? { variables: variables as string[] }
                : { channelIds: variables as string[] }),
            chartName,
            isExpandedView: true,
        })
    );

    if (action.fulfilled.match(response)) {
        const fetchedData = response.payload?.data?.data || response.payload?.data || [];

        const groupedData = keys.reduce((acc: Record<string, any>, key) => {
            const variableKey = isSleAsset
                ? userPreference?.mappings?.sle?.[key]
                : channelIDsObj[key];

            // Find the item corresponding to the variableKey
            const matchedItem = fetchedData.find(
                (item: any) => item.variable === variableKey || item.trendName === variableKey
            );

            if (matchedItem) {
                acc[key] = matchedItem;
            }
            return acc;
        }, {});

        const processedData: Record<string, { date: number; value: number }[]> = {};

        keys.forEach((key) => {
            const variableKey = isSleAsset
                ? userPreference?.mappings?.sle?.[key]
                : channelIDsObj[key];

            processedData[key] = fetchedData
                .filter(
                    (item: any) =>
                        item.variable === variableKey || item.trendName === variableKey
                )
                .flatMap((item: any) =>
                    item.dataPoints.map((dataPoint: any) => ({
                        date: new Date(dataPoint.date).getTime(),
                        value: Number(dataPoint.value),
                    }))
                );
        });

        return { result: processedData, response: groupedData };
    } else {
        // If the action fails, return an empty structure for all keys
        const emptyResult = keys.reduce((acc, key) => {
            acc[key] = [];
            return acc;
        }, {} as Record<string, { date: number; value: number }[]>);

        return { result: emptyResult, response: {} };
    }
};
