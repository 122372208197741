import { apiInstance } from '../../../../../config/HttpCommon';

export const getCommentsList = async (
  assetId: string,
  SearchQuery: string,
  SortOrder: string,
  SortBy: string,
  PageNumber: number,
  PageSize: number,
) => {
  const response = await apiInstance.get(
    `/v1/comments?AssetId=${assetId}&SearchQuery=${SearchQuery}&SortOrder=${SortOrder}&SortBy=${SortBy}&PageNumber=${PageNumber}&PageSize=${PageSize}`,
  );
  return response.data;
};


export const uploadFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await apiInstance.post('/v1/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const createComment = async (commentData: {
  assetId: string;
  subject: string;
  content: string;
  attachments: {
    size: number;
    sizeSuffix: string;
    extension: string;
    fileName: string;
    blobFile: string;
    blobFileUrl: string;
  }[];
}) => {
  try {
    const response = await apiInstance.post('/v1/comments', commentData);
    return response.data;
  } catch (error) {
    console.error('Error creating comment:', error);
    throw error;
  }
};

export const deleteComment = async (commentId: string) => {
  const response = await apiInstance.delete(`/v1/comments/${commentId}`);
  return response.data;
};

export const replyToComment = async (commentId: string, requestBody: any) => {
  const response = await apiInstance.post(`/v1/comments/${commentId}/reply`, requestBody);
  return response.data;
};

export const deleteReply = async (commentId: string, replyId: string) => {
  const response = await apiInstance.delete(`/v1/comments/${commentId}/reply/${replyId}`);
  return response.data;
};
