import React, { useState, useMemo } from "react";
import { ReactComponent as Edit } from "../../../../images/edit-icon.svg";
import { ReactComponent as Delete } from "../../../../images/trash-icon.svg";
// import { useAppDispatch } from "../../../../hooks/storeHooks";
import SuccessfullToast from "../../common/toast/successfulToast";
import CustomTable from "../../common/table/customTable";
import { formatDateTime } from "../../common/dateConversion/dateUtils";
import ConfirmationDialog from "../../common/confirmationDialog/confirmationDialog";
import '../../pageLayout.scss'
import AddAssetDetails from "./addAssetDetails/addAssetDetails";
import Tooltip from "../../common/tooltip/tooltip";
// import {
//   addAsset,
//   deleteAsset,
//   editAsset,
//   fetchAssetById,
//   loadAsset,
//   resetAssetToEdit,
// } from "./assetSlice.ts";
// import SuccessfullToast from "../../common/toast/successfulToast.tsx";
// import ConfirmationDialog from "../../common/confirmationDialog/confirmationDialog.tsx";
// import { AssetItem } from "./assetService.tsx";
// import { formatDateTime } from "../../common/dateConversion/dateUtils.tsx";
// import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks.ts";

const AssetDetails: React.FC = () => {
  // const dispatch = useAppDispatch();
  // const { asset, assetToEdit, assetLoading, assetDeleteLoading } =
  //   useAppSelector((state) => state.assets);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [assetToDelete, setAssetToDelete] = useState<string | null>(null);
  // const [assetToDeleteName, setAssetToDeleteName] = useState<string | null>(
  //   null
  // );
  // const [textcolor, setTextColor] = useState("");

  // useEffect(() => {
  //   dispatch(loadAsset());
  // }, [dispatch]);

  // const handleEdit = useCallback(
  //   async (asset: AssetItem) => {
  //     try {
  //       await dispatch(fetchAssetById(asset.id));
  //       dispatch(resetAssetToEdit());
  //     } catch (error) {
  //       console.error("Failed to fetch asset by id", error);
  //     }
  //   },
  //   [dispatch]
  // );

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Customer", accessor: "customerName" },
      { Header: "Node address", accessor: "nodeAddress" },
      {
        Header: "Created on",
        accessor: "createdOnDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      {
        Header: "Last modified on",
        accessor: "lastModifiedDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      // {
      //   Header: "Enabled",
      //   accessor: "isEnabled",
      //   Cell: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
      // },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div className="actions">
            <Tooltip content="Edit asset" direction="top">
              <div /*onClick={() => handleEdit(row.original)}*/>
                <Edit style={{ stroke: "#fff" }} />
              </div>
            </Tooltip>
            {row.original.isEnabled ? (
              <Tooltip content="Delete asset" direction="left">
                <div
                // onClick={() => {
                //   setAssetToDelete(row.original.id);
                //   setAssetToDeleteName(row.original.name);
                //   setShowDeleteModal(true);
                // }}
                >
                  <Delete style={{ stroke: "#fff" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip content="Cannot delete disabled asset" direction="left">
                <Delete style={{ stroke: "#6c757d" }} />
              </Tooltip>
            )}
          </div>
        ),
      },
    ],
    []
    // [handleEdit]
  );

  const handleDelete = async () => {
    // if (assetToDelete) {
    //   try {
    //     const action = deleteAsset(assetToDelete);
    //     await dispatch(action).unwrap();
    //     setToastMessage("Asset deleted successfully");
    //     setTextColor("green");
    //     dispatch(loadAsset());
    //   } catch (error) {
    //     setToastMessage("Failed to delete asset");
    //     setTextColor("red");
    //   } finally {
    //     setShowToast(true);
    //     setShowDeleteModal(false);
    //     setAssetToDelete(null);
    //     setAssetToDeleteName(null);
    //   }
    // }
  };

  // const addAssetHandler = (AssetItem: AssetItem) => {
  //   dispatch(addAsset(AssetItem));
  // };

  // const editAssetHandler = (updatedAsset: AssetItem) => {
  //   dispatch(editAsset({ assetId: updatedAsset.id, updatedAsset }));
  // };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPageIndex(0);
  };

  return (
    <div className="onboarding-page-layout">
      {/* <div className="main-header">
        <div className="header-name">Asset List</div>
      </div> */}
      <div className="main-content">
        {showToast && (
          <SuccessfullToast
            message={''}
            isShowEnabled={showToast}
            setShow={setShowToast}
            colorText={'green'}
          />
        )}
        <CustomTable
          headerComponent={
            <AddAssetDetails
            // addAsset={addAssetHandler}
            // editAsset={editAssetHandler}
            // assetToEdit={assetToEdit}
            />
          }
          data={[]}
          columns={columns}
          // handleEdit={handleEdit}
          // handleDelete={handleDelete}
          searchQuery={searchQuery}
          onSearch={handleSearch}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          // isLoading={assetLoading}
          csvFileName="asset_data.csv"
        />
        <ConfirmationDialog
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          message={`Are you sure you want to delete: ?`}
        // message={`Are you sure you want to delete: ${assetToDeleteName} ?`}
        // isLoading={assetDeleteLoading}
        />
      </div>
    </div>
  );
};

export default AssetDetails;
