import React, { useState, useRef, useEffect } from "react";
import { defaultStaticRanges, defineds, formateDate } from "./WellPerormanceTrendRanges";
import { endOfDay, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./wellperformance-trend-datepicker.scss";
import PropTypes from "prop-types";
import { DateRangePicker } from 'react-date-range';
import { showToaster } from "../../../asset-location/AssetList";
import { handleInputChange } from "../../../../../well-controlroom-charts/common";
import InfoIcon from "../../../../../../images/error-info-icon.svg";

interface WellPerormanceTrendDateRangeSelectorProps {
     isOpen?: boolean;
     ranges?: any;
     onChange?: any;
     onSubmit?: any;
     setShowCalendar?: any;
     rest?: any;
     setSelectedDateRange: any;
     selectedDateRange: {
          startDate: Date;
          endDate: Date;
          key: string;
     };
}

const WellPerormanceTrendDateRangeSelector: React.FC<WellPerormanceTrendDateRangeSelectorProps> = ({ setShowCalendar, setSelectedDateRange, selectedDateRange }) => {
     const [calendar, setCalendar] = useState<{
          startDate: Date | undefined;
          endDate: Date | undefined;
          key: string;
     }>({
          startDate: selectedDateRange?.startDate || startOfDay(new Date()),
          endDate: selectedDateRange?.endDate || endOfDay(new Date()),
          key: selectedDateRange?.key || "selection"
     });

     const [rawInput, setRawInput] = useState<{
          startDate: string;
          endDate: string;
     }>({
          startDate: formateDate(selectedDateRange?.startDate || startOfDay(new Date())),
          endDate: formateDate(selectedDateRange?.endDate || endOfDay(new Date())),
     });

     const [error, setError] = useState<string | null>(null);

     const dateRef = useRef<HTMLDivElement>(null);


     const handleSelect = (ranges: any) => {
          setCalendar(ranges.selection);
          setRawInput({
               startDate: formateDate(ranges.selection.startDate),
               endDate: formateDate(ranges.selection.endDate),
          });
          setError(null);
     };

     const handleApply = () => {
          if (!error) {
               setSelectedDateRange({
                    startDate: calendar.startDate,
                    endDate: calendar.endDate,
                    key: calendar.key
               });
               setShowCalendar(false);
          } else {
               showToaster(error, "error");
          }
     };

     const handleOnClose = () => {
          setShowCalendar(false);
     };

     const handleClickOutside = (event: MouseEvent) => {
          if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
               setShowCalendar(false);
          }
     };

     useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
               document.removeEventListener("mousedown", handleClickOutside);
          };
     }, []);

     return (
          <React.Fragment>
               <div className="date-picker__container noDrag" ref={dateRef}>
                    <DateRangePicker
                         onChange={handleSelect}
                         moveRangeOnFirstSelection={false}
                         months={2}
                         ranges={[calendar]}
                         direction="horizontal"
                         staticRanges={defaultStaticRanges}
                         minDate={defineds?.last90Days}
                         maxDate={defineds?.endOfToday}
                    />
                    <div className="text-right position-relative rdr-buttons-position d-flex">
                         <div className="date-picker__footer-container">
                              <div className="date-picker__footer-left-container"></div>
                              <div className="date-picker__footer-rigth-container">
                                   <div className="date-picker__input-container">
                                        <input
                                             className={`date-picker__input pl-10 ${error ? "!border-red-400 focus-visible:!outline-none" : ""}`}
                                             value={rawInput.startDate}
                                             onChange={(e) => handleInputChange("startDate", e.target.value, rawInput, setRawInput, setCalendar, setError, calendar, defineds?.last90Days)}
                                        />
                                        <span className="mx-2">-</span>
                                        <div className="relative mt-0" >
                                             <input
                                                  className={`date-picker__input pl-10 ${error ? "!border-red-400 focus-visible:!outline-none !w-[130px]" : ""}`}
                                                  value={rawInput.endDate}
                                                  onChange={(e) => handleInputChange("endDate", e.target.value, rawInput, setRawInput, setCalendar, setError, calendar, defineds?.last90Days)}
                                             />
                                             {error && <img
                                                  src={InfoIcon}
                                                  alt="Info Icon"
                                                  className="absolute left-[100px] top-[13px] w-[20px]"
                                             />}
                                             {error && <p className="!text-red-400 mt-3">{error}</p>}
                                        </div>
                                   </div>
                                   <div className="date-picker__button-container">
                                        <button
                                             className="date-picker__btn-secondary"
                                             onClick={handleOnClose}
                                        >
                                             Cancel
                                        </button>
                                        <button
                                             disabled={error ? true : false}
                                             className={`date-picker__btn-primary ${error ? "opacity-50 cursor-not-allowed" : ""}`}
                                             onClick={handleApply}
                                        >
                                             Apply
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </React.Fragment>
     );
};

WellPerormanceTrendDateRangeSelector.defaultProps = {
     ranges: defaultStaticRanges
};

WellPerormanceTrendDateRangeSelector.propTypes = {
     onSubmit: PropTypes.func
};

export default WellPerormanceTrendDateRangeSelector;
