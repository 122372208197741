import React, { useEffect, useRef, useState } from 'react';
import EquipmentList from './components/EquipmentList';
import EquipmentDetails from './components/EquipmentDetails';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { AppUser } from '../../../user/model/AppUser';
import { clearEquipmentDetails, fetchEquipment, fetchEquipmentDetails, fetchEquipmentVolume } from './EquipmentSlice';
import ESP from '../../../../images/ESP.svg';
import './Equipment.scss';
import Loader from '../../../common/page-loader/ComponentLoader';
import axios from 'axios';

interface AssetProps {
  assetId: string | undefined;
  assetName: string | undefined;
}

export interface EquipmentItem {
  key: string;
  value: string;
}

export interface EquipmentDetail {
  description: string;
  title: string;
  minimumDailyVolume: string;
  maximumDailyVolume: string;
  idealDailyVolume: string;
  housingPressureLimit: string;
  pullRating: string;
  oracleItemNumber: string;
}

const Equipment: React.FC = () => {
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const equipmentDetails = useAppSelector((state) => state.equipmentSlice.equipmentDetails)
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const [selectedEquipment, setSelectedEquipment] = useState<string | null>(null);
  const [selectedEquipmentIndex, setSelectedEquipmentIndex] = useState<number | null>(null);
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [currentAsset, setCurrentAsset] = useState<AssetProps>({
    assetName: '',
    assetId: '',
  });
  const [equipmentNames, setEquipmentNames] = useState<EquipmentItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [volumeDetails, setVolumeDetails] = useState();

  useEffect(() => {
    if (selectedAsset.selectedAssetName) {
      const data = {
        assetId: selectedAsset.selectedAssetId,
        assetName: selectedAsset?.selectedAssetName,
        industryApplicationId: 0,
      };
      setCurrentAsset(data);
    }
  }, [selectedAsset?.selectedAssetName]);

  useEffect(() => {
    if (!storedUser?.id || !currentAsset.assetName) return;
    // const assetId = selectedAsset?.selectedAssetId;
    const assetName = selectedAsset?.selectedAssetName
    if (storedUser?.id && assetName) {
      // setLoading(true);
      dispatch(fetchEquipment({ userId: storedUser.id, assetName: assetName }))
        .unwrap()
        .then((response: EquipmentItem[]) => {
          // setLoading(false);
          setEquipmentNames(response);
        })

        .catch((error) => {
          console.error('Failed to fetch equipment:', error);
          // setLoading(false);
        });
    }
  }, [dispatch, storedUser?.id, currentAsset.assetName]);

  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

  const handleEquipmentSelect = async (index: number, equipmentId: string, equipmentName: string) => {
    cancelTokenSource.current?.cancel('Operation canceled by the user.');
    const wellName = selectedAsset?.selectedAssetName ?? '';
     setTimeout(() => {
      setLoading(true);
     })
    setSelectedEquipment(equipmentName);
    setSelectedEquipmentIndex(index);
    cancelTokenSource.current = axios.CancelToken.source();
    if (storedUser?.id && wellName && equipmentId) {
     
      await dispatch(
        fetchEquipmentDetails({
          userId: storedUser.id,
          wellName: wellName,
          equipmentId,
          cancelToken: cancelTokenSource?.current?.token,
        }),
      ).then(() => {
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    dispatch(clearEquipmentDetails());
  }, [])


  const handleDetailSelect = (detail: EquipmentDetail) => {
    if (detail.oracleItemNumber) {
      dispatch(fetchEquipmentVolume({ oracleItemNumber: detail.oracleItemNumber }))
        .unwrap()
        .then((volumeDetails) => {
          console.log('Volume details:', volumeDetails);
          setVolumeDetails(volumeDetails);
        })
        .catch((error) => {
          console.error('Failed to fetch volume details:', error);
        });
    }
  };

  return (
    <>
      <div className='equipment-container flex flex-row flex-grow p-9'>
        <div className='card profile-card m-0'>
          <span className='title equipment-color'>ESP equipment</span>
          <div className='equipment-main-container'>
            {equipmentNames?.length > 0 && (
              <div className='equipment-list'>
                <EquipmentList
                  selectedEquipmentIndex={selectedEquipmentIndex}
                  onSelectEquipment={handleEquipmentSelect}
                  equipmentItems={equipmentNames}
                  currentAsset={currentAsset}
                />
              </div>
            )}
            <div className='equipment-details'>
              {loading ? (
                <div className='equipment-loader flex items-center justify-content loader-margin'>
                  <Loader />
                </div>
              ) : equipmentDetails?.length > 0 ? (
                <EquipmentDetails
                  selectedEquipment={selectedEquipment}
                  equipmentDetails={equipmentDetails}
                  volumeDetails={volumeDetails}
                  onSelectDetail={handleDetailSelect}
                />
              ) : (
                <div className='error-image-container'>
                  <img src={ESP} alt='No Data Found' />
                  <p className='main-text'>No equipment found</p>
                  <p className='normal-text'>Your equipment will show here once it is installed.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Equipment;
