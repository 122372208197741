import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clearServerCacheAction, fetchSupportAssetList, getLookups, scheduleServiceStatusAction, updateLookupsById } from './supportService';  // Assuming supportAssetsLists is properly defined now
import { ConfigurationItem, Schedule } from './supportInterface';
import { SupportAssetApiResponse } from './model/AssetListInterface';


interface UserState {
  lookups?: ConfigurationItem[];
  scheduleServiceStatus?: Schedule[];
  lookupsLoading?: boolean;
  downloadAssetCsvLoading?: boolean
  clearServerCacheLoading?: boolean;
  scheduleServiceStatusLoading?: boolean;
  lookupsError?: string;
  loading?: boolean;
  error?: string;
  assetListLoading: boolean;
  assetsLists: SupportAssetApiResponse | null;
  assetListTimeSeries: SupportAssetApiResponse | null;
  assetTimeSeriesLoading: boolean;
  assetSupportListErrorMessage: string | null;
  filterTerm: string;
}

const initialState: UserState = {
  lookups: [],
  scheduleServiceStatus: [],
  lookupsLoading: false,
  downloadAssetCsvLoading: false,
  clearServerCacheLoading: false,
  scheduleServiceStatusLoading: false,
  lookupsError: '',
  loading: false,
  error: '',
  assetListLoading: false,
  assetsLists: null,
  assetListTimeSeries: null,
  assetTimeSeriesLoading: false,
  assetSupportListErrorMessage: null,
  filterTerm: ''


};

export const fetchLookups = createAsyncThunk(
  'support/fetchLookups',
  async () => {
    const response = await getLookups();
    return response;
  },
);

export const supportAssetsList = createAsyncThunk(
  'support/supportAssetsList',
  async (payload: { searchQuery: string, pageNumber: number, pageSize: number, signal?: AbortSignal }) => {
    const response = await fetchSupportAssetList(payload.searchQuery.trim(), false, payload.pageNumber, payload.pageSize, payload.signal);
    return response;
  },
);

export const fetchAssetTimeSeries = createAsyncThunk(
  'support/fetchAssetTimeSeries',
  async (payload: { searchQuery: string, pageNumber: number, pageSize: number, signal?: AbortSignal }) => {
    const response = await fetchSupportAssetList(payload.searchQuery.trim(), true, payload.pageNumber, payload.pageSize, payload.signal);
    return response;
  },
);

export const updateLookups = createAsyncThunk(
  'support/updateLookups',
  async (payload: { id: string, payload: any }) => {
    const response = await updateLookupsById(payload.id, payload.payload,
    );
    return response;
  },
);

export const clearServerCache = createAsyncThunk(
  'support/clearServerCache',
  async () => {
    const response = await clearServerCacheAction();
    return response;
  },
);

export const scheduleSeviceStatus = createAsyncThunk(
  'support/scheduleServiceStatus',
  async () => {
    const response = await scheduleServiceStatusAction();
    return response;
  },
);

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    clearAssetSupportList: (state) => {
      state.assetsLists = null;
      state.assetListTimeSeries = null;

    },
    clearAssetSupportListError: (state) => {
      state.assetSupportListErrorMessage = null;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLookups.pending, (state) => {
      state.lookupsLoading = true;
      state.lookupsError = '';
    });
    builder.addCase(fetchLookups.fulfilled, (state, action: any) => {
      state.lookupsLoading = false;
      state.lookups = action.payload.data;
    });
    builder.addCase(fetchLookups.rejected, (state, action) => {
      state.lookupsLoading = false;
      state.lookupsError = action.error.message || '';
    });
    builder.addCase(updateLookups.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateLookups.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateLookups.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || '';
    });
    builder.addCase(clearServerCache.pending, (state) => {
      state.loading = true;
      state.clearServerCacheLoading = true;
      state.error = '';
    });
    builder.addCase(clearServerCache.fulfilled, (state) => {
      state.loading = false;
      state.clearServerCacheLoading = false;
    });
    builder.addCase(clearServerCache.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || '';
      state.clearServerCacheLoading = false;
    });
    builder.addCase(scheduleSeviceStatus.pending, (state) => {
      state.loading = true;
      state.scheduleServiceStatusLoading = true;
      state.error = '';
    });
    builder.addCase(scheduleSeviceStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.scheduleServiceStatusLoading = false;
      state.scheduleServiceStatus = action.payload.data;
    });
    builder.addCase(scheduleSeviceStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || '';
      state.scheduleServiceStatusLoading = false;
    });

    builder.addCase(supportAssetsList.pending, (state) => {
      state.assetListLoading = true;
      state.error = '';
      state.assetSupportListErrorMessage = null;
    });
    builder.addCase(supportAssetsList.fulfilled, (state, action) => {
      state.assetListLoading = false;
      state.assetsLists = state.assetsLists?.results ? {
        ...action.payload.data,
        results: [...state.assetsLists.results, ...action.payload.data.results],
      } : action.payload.data;
      state.assetSupportListErrorMessage = null;
    });
    builder.addCase(supportAssetsList.rejected, (state, action) => {
      state.assetListLoading = false;
      state.error = action.error.message || '';
      state.assetSupportListErrorMessage = action.error.message || '';
      if (action.meta.arg.searchQuery && action.error.message?.includes("404")) {
        state.assetsLists = null;
      }
    });

    builder.addCase(fetchAssetTimeSeries.pending, (state) => {
      state.assetTimeSeriesLoading = true;
      state.error = '';
      state.assetSupportListErrorMessage = null;
    });
    builder.addCase(fetchAssetTimeSeries.fulfilled, (state, action) => {
      state.assetTimeSeriesLoading = false;
      state.assetListTimeSeries = state.assetListTimeSeries ? {
        ...action.payload.data,
        results: [...state.assetListTimeSeries.results, ...action.payload.data.results],
      } : action.payload.data;
      state.assetSupportListErrorMessage = null;
    });
    builder.addCase(fetchAssetTimeSeries.rejected, (state, action) => {
      state.assetTimeSeriesLoading = false;
      state.error = action.error.message || '';
      state.assetSupportListErrorMessage = action.error.message || '';
      if (action.meta.arg.searchQuery && action.error.message?.includes("404")) {
        state.assetListTimeSeries = null;
      }
    });

  },
});

export const { clearAssetSupportList, clearAssetSupportListError, setFilterTerm } = supportSlice.actions;

export default supportSlice.reducer;


