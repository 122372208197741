import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChangePassword, Group, Role, UserItem, UserSearchResponse } from "./userService";
import { getPrivileges } from "../../../user/UserService";


interface Privilege {
  lookupId: string,
  value: string,
  displayLabel: string,
  description: string,
}

interface UserState {
  users: UserItem[];
  searchResults: UserSearchResponse[];
  userToEdit: UserItem | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  userLoading: boolean;
  error: string | null;
  groups: Group[];
  roles: Role[];
  changePassword: ChangePassword | null;
  userSearchLoading: boolean;
  userSaveLoading: boolean;
  userDeleteLoading: boolean;
  privileges: Privilege[] | null;
  priviledgeLoading: boolean

}

const initialState: UserState = {
  users: [],
  searchResults: [],
  userToEdit: null,
  status: "idle",
  userLoading: false,
  error: null,
  groups: [],
  roles: [],
  changePassword: null,
  userSearchLoading: false,
  userSaveLoading: false,
  userDeleteLoading: false,
  privileges: null,
  priviledgeLoading: false
};


export const fetchPrivileges = createAsyncThunk('user/fetchPrivileges', async () => {
  const response = await getPrivileges();
  return response;
});
// export const loadUsers = createAsyncThunk(
//   "users/fetchUserDataAsync",
//   async () => {
//     const response = await fetchUserDataAsync();
//     return response;
//   }
// );

// export const loadGroups = createAsyncThunk(
//   "users/fetchGroupDataAsync",
//   async () => {
//     const response = await fetchGroupDataAsync();
//     return response;
//   }
// );

// // Async thunk for fetching roles
// export const loadRoles = createAsyncThunk(
//   "users/fetchRoleDataAsync",
//   async () => {
//     const response = await fetchRoleDataAsync();
//     return response;
//   }
// );

// // Async thunk for fetching a user by ID
// export const fetchUserById = createAsyncThunk(
//   "customer/fetchUserDataByIdAsync",
//   async (id: string) => {
//     const response = await fetchUserDataByIdAsync(id);
//     return response;
//   }
// );

// export const searchUserByName = createAsyncThunk(
//   "users/searchUserByNameAsync",
//   async (name: string) => {
//     const response = await searchUserByNameAsync(name);
//     return response;
//   }
// );

// export const addUser = createAsyncThunk(
//   "users/addNewUserAsync",
//   async (newUser: UserItem, { rejectWithValue }) => {
//     try {
//       const response = await addNewUserAsync(newUser);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(
//         error.message || "An unexpected error occurred.",
//       );
//     }
//   }
// );
// export const editUser = createAsyncThunk(
//   "users/editUserAsync",
//   async (
//     payload: { userId: string; updatedUser: UserItem },
//     { rejectWithValue }
//   ) => {
//     const { userId, updatedUser } = payload;
//     try {
//       const finalUpdatedUser = {
//         ...updatedUser,
//       };

//       const response = await editUserAsync(userId, finalUpdatedUser);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(
//        error.message || "An unexpected error occurred.",
//       );
//     }
//   }
// );

// export const changeUserPassword = createAsyncThunk(
//   "users/changeUserPassword",
//   async (changePassword: ChangePassword, { rejectWithValue }) => {
//     try {
//       const response = await changePasswordAsync(changePassword);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(
//        error.message || "An unexpected error occurred while changing the password."
//       );
//     }
//   }
// );

// export const deleteUser = createAsyncThunk(
//   "customer/deleteCustomerAsync",
//   async (userId: string, { rejectWithValue }) => {
//     try {
//       const user = await fetchUserDataByIdAsync(userId);
//       const updatedUser = {
//         ...user,
//         isEnabled: false,
//         lastModifiedDate: user?.lastModifiedDate
//           ? user.lastModifiedDate
//           : new Date().toISOString(),
//         license: {
//           licenseType: "string",
//           expirationDate: "2024-06-25T07:26:41.060Z",
//         },
//       };
//       const response = await editUserAsync(userId, updatedUser);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(
//         error.message || "An unexpected error occurred while deleting the user."
//       );
//     }
//   }
// );

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserToEdit(state) {
      state.userToEdit = null;
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(loadUsers.pending, (state) => {
    //     state.userLoading = true;
    //   })
    //   .addCase(loadUsers.fulfilled, (state, action) => {
    //     state.userLoading = false;
    //     state.users = action.payload;
    //   })
    //   .addCase(loadUsers.rejected, (state, action) => {
    //     state.userLoading = false;
    //     state.error = action.error.message || "Failed to load users";
    //   })
    //   .addCase(fetchUserById.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(fetchUserById.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.userToEdit = action.payload;
    //   })
    //   .addCase(fetchUserById.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message ?? "Failed to fetch user by ID";
    //   })
    //   .addCase(searchUserByName.pending, (state) => {
    //     state.userSearchLoading = true;
    //   })
    //   .addCase(searchUserByName.fulfilled, (state, action) => {
    //     state.userSearchLoading = false;
    //     state.searchResults = action.payload;
    //   })
    //   .addCase(searchUserByName.rejected, (state, action) => {
    //     state.userSearchLoading = false;
    //     state.error = action.error.message || "Failed to search user";
    //   })
    //   .addCase(addUser.pending, (state) => {
    //     state.status = "loading";
    //     state.userSaveLoading = true;
    //   })
    //   .addCase(addUser.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.userSaveLoading = false;
    //     state.users.push(action.payload as UserItem);
    //   })
    //   .addCase(addUser.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.userSaveLoading = false;
    //     state.error = action.error.message || "Failed to add user";
    //   })
    //   .addCase(editUser.pending, (state) => {
    //     state.status = "loading";
    //     state.userSaveLoading = true;
    //   })
    //   .addCase(editUser.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.userSaveLoading = false;
    //     if (action.payload) {
    //       const updatedUser = action.payload;
    //       const index = state.users.findIndex(
    //         (user) => user.id === updatedUser.id
    //       );
    //       if (index !== -1) {
    //         state.users[index] = updatedUser;
    //       } else {
    //         state.users.push(updatedUser);
    //       }
    //     }
    //   })
    //   .addCase(editUser.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.userSaveLoading = false;
    //     state.error = action.error.message || "Failed to edit user";
    //   })
    //   .addCase(deleteUser.pending, (state) => {
    //     state.status = "loading";
    //     state.userDeleteLoading = true;
    //   })
    //   .addCase(deleteUser.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.userDeleteLoading = false;
    //     if (action.payload) {
    //       const updatedUser = action.payload;
    //       const index = state.users.findIndex(
    //         (user) => user.id === updatedUser.id
    //       );
    //       if (index !== -1) {
    //         state.users[index] = updatedUser;
    //       }
    //     }
    //   })
    //   .addCase(deleteUser.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.userDeleteLoading = false;
    //     state.error = action.error.message || "Failed to delete user";
    //   })
    //   .addCase(loadGroups.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(loadGroups.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.groups = action.payload;
    //   })
    //   .addCase(loadGroups.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message || "Failed to load groups";
    //   })
    //   .addCase(loadRoles.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(loadRoles.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.roles = action.payload;
    //   })
    //   .addCase(loadRoles.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message || "Failed to load roles";
    //   })
    //   .addCase(changeUserPassword.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(changeUserPassword.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.changePassword = action.payload;
    //   })
    //   .addCase(changeUserPassword.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message || "Failed to change password";
    //   });
    builder.addCase(fetchPrivileges.pending, (state) => {
      state.priviledgeLoading = true;
    });
    builder.addCase(fetchPrivileges.fulfilled, (state, action) => {
      state.priviledgeLoading = false;
      state.privileges = action.payload[0]?.group ?? null
    });
    builder.addCase(fetchPrivileges.rejected, (state) => {
      state.priviledgeLoading = false;
      state.privileges = null;
    });
  },
});

export const { resetUserToEdit } = userSlice.actions;

export default userSlice.reducer;
