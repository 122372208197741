import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as AlertIcon } from "../../../../../images/alert-circle.svg";

import "./addCustomerDetails.scss";
import { CustomerItem } from "../customerService";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import SearchDropdown, { searchDropdownItem } from "../../../common/dropdown/searchDropdown";
import { useFormChangeDetection } from "../../../common/formChangeDetection/formChangeDetection";
import Tooltip from "../../../common/tooltip/tooltip";
import Modal from "../../../common/modal/modal";
import Loader from "../../../common/loader/loader";
import SuccessfullToast from "../../../common/toast/successfulToast";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog";
import { searchCustomerByName } from "../customerSlice";
import axios from "axios";
import plus from '../../../../../images/plus.svg';


interface AddCustomerProps {
  customerToEdit?: CustomerItem | null;
  triggerType?: "icon" | "button";
  addCustomer?: (newCustomer: CustomerItem) => void;
  editCustomer?: (updatedCustomer: CustomerItem) => void;
}

const AddCustomer: React.FC<AddCustomerProps> = ({
  customerToEdit,
}) => {
  const dispatch = useAppDispatch();
  const searchResults = useAppSelector(
    (state) => state.OnboardingCustomer.searchResults
  );
  const customers = useAppSelector((state) => state.OnboardingCustomer.customer);
  const customerSearchLoading = useAppSelector(
    (state) => state.OnboardingCustomer.customerSearchLoading
  );
  const customerSaveLoading = useAppSelector(
    (state) => state.OnboardingCustomer.customerSaveLoading
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialFormData: CustomerItem = {
    id: "",
    cnX_CustomerId: 0,
    cnX_CustomerName: "",
    name: "",
    isActive: false,
    b2Cdomainname: "",
  };

  const [formData, setFormData] = useState<CustomerItem>(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchDropdownItems, setSearchDropdownItems] = useState<
    searchDropdownItem[]
  >([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const { setOriginalFormData, hasChanges } =
    useFormChangeDetection<CustomerItem>(null);



  useEffect(() => {
    if (customerToEdit) {
      setFormData({
        ...customerToEdit,
        id: customerToEdit.id.toString(),
      });
      setOriginalFormData({ ...customerToEdit });
      setIsEditMode(true);
      setSearchDropdownItems([]);
      openModal();
    }
  }, [customerToEdit, setOriginalFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    isValid = formData.name.trim() !== "";

    if (
      formData.name &&
      customers.some(
        (customer) =>
          customer.name &&
          customer.name.toLowerCase() === formData.name.toLowerCase() &&
          (!isEditMode || customer.id !== formData.id)
      )
    ) {
      newErrors.name = "Customer name already exists";
    }

    setErrors(newErrors);
    setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData, isEditMode, customers]);

  useEffect(() => {
    setInputValue(formData.cnX_CustomerName);
  }, [formData.cnX_CustomerName]);

  useEffect(() => {
    if (inputValue.length > 2) {
      const filteredItems = searchResults.filter((result) =>
        result.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSearchDropdownItems(
        filteredItems.map((result, index) => ({
          id: result.id,
          name: result.name,
          displayName: result.name,
          key: `${result.id}-${index}`,
        }))
      );
    }
  }, [searchResults, inputValue]);

  const openModal = () => setIsModalOpen(true);
  const closeCustomerModal = () => {
    setInputValue("");
    setIsModalOpen(false);
    setFormData(initialFormData);
    setErrors({});
    setIsEditMode(false);
  };

  const openAddCustomerModal = () => {
    setIsEditMode(false);
    setOriginalFormData(initialFormData);
    setFormData(initialFormData);
    openModal();
    setSearchDropdownItems([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value.replace(/^\s+/, ""),
    }));
  };

  const handleDropdownChange = (selectedCustomer: searchDropdownItem) => {
    setFormData((prevState: any) => ({
      ...prevState,
      cnX_CustomerId: selectedCustomer.id,
      cnX_CustomerName: selectedCustomer.name,
      name: !isEditMode ? selectedCustomer.name : prevState.name,
      isActive: true,
    }));
    setInputValue(selectedCustomer.name);
  };

  const handleCustomerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // const currentDate = new Date().toISOString();

    // const updatedFormData: CustomerItem = {
    //   ...formData,
    //   createdOnDate: formData.createdOnDate
    //     ? formData.createdOnDate
    //     : currentDate,
    //   lastModifiedDate: currentDate,
    //   isActive: isEditMode ? formData.isActive : true,
    // };

    // if (Object.keys(errors).length === 0) {
    //   try {
    //     const action = isEditMode
    //       ? editCustomer({
    //         customerId: formData.id,
    //         updatedCustomer: updatedFormData,
    //       })
    //       : addCustomer(updatedFormData);

    //     await dispatch(action).unwrap();

    //     setToastMessage(
    //       `Customer ${isEditMode ? "updated" : "added"} successfully`
    //     );
    //     setTextColor("green");
    //     dispatch(loadCustomer());
    //   } catch (error) {
    //     setToastMessage(
    //       `Failed to ${isEditMode ? "update" : "add"
    //       } customer. Please try again.`
    //     );
    //     setTextColor("red");
    //   } finally {
    //     setShowToast(true);
    //     closeCustomerModal();
    //   }
    // }
  };

  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleInputChangeLogging = (value: string) => {
    const trimmedValue = value.replace(/^\s+/, "");
    setInputValue(trimmedValue);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('canceled');
    }
    if (trimmedValue.length >= 3) {
      cancelTokenSource.current = axios.CancelToken.source();

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(async () => {
        await dispatch(searchCustomerByName({
          name: trimmedValue,
          cancelToken: cancelTokenSource.current?.token
        }));
      }, 1000);
    }
  };

  const handleCancelClick = () => {
    if (hasChanges(formData)) {
      setIsConfirmationDialogOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeCustomerModal();
  };

  return (
    <div className="onboarding-container">
      <button className='btn btn-primary flex gap-x-2' style={{ fontSize: '14px' }} onClick={openAddCustomerModal}>
        <img src={plus} alt='plus icon' />
        <span>Add customer</span>
      </button>
      <Modal isOpen={isModalOpen} onClose={closeCustomerModal}>
        <div style={{ position: "relative" }} className="onboard-modal">
          {customerSaveLoading && (
            <div className="spinner-overlay">
              <Loader isOverlay={true} />
            </div>
          )}
          <form className="add-customer-form onboarding-form" onSubmit={handleCustomerSubmit}>
            <div className="onboard-form-row onboard-modal-header" >
              <label style={{ fontSize: "20px" }}>
                {isEditMode ? "Edit customer" : "Add new customer"}
              </label>
            </div>
            <div className="onboard-modal-content">
              <div className="onboard-form-row grid grid-cols-1">
                <div className="onboard-form-group col-auto">
                  <label>
                    <span>CNX name:</span>
                  </label>
                  <SearchDropdown
                    items={searchDropdownItems}
                    defaultOption={
                      formData.cnX_CustomerName || "Select a customer"
                    }
                    onChange={handleDropdownChange}
                    onInputChange={handleInputChangeLogging}
                    inputValue={inputValue}
                    isLoading={customerSearchLoading}
                    infoMessage="Type 3 or more characters to search"
                  />
                </div>
                <div className="onboard-form-group col-auto">
                  <label>
                    <span>Name:</span>
                    <span className="required-asterisk">*</span>
                  </label>
                  <div className="alert-icon-container">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                    {errors.name && (
                      <span className="error-icon">
                        <Tooltip content={errors.name} direction="top">
                          <AlertIcon />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </div>

              </div>
              <div className="form-mandatory-message">
                <p className="mandatory-fields-message mt-5 ">
                  Fields marked with<span className="required-asterisk">*</span>{" "}
                  are mandatory.
                </p>
              </div>
            </div>

            <div className="onboard-form-row onboard-modal-footer" >
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="add-customer-button"
                // onClick={handleCustomerSubmit}
                disabled={
                  isEditMode
                    ? !isFormValid || !hasChanges(formData)
                    : !isFormValid
                }
              >
                {isEditMode ? "Save changes" : "Add customer"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showToast && (
        <SuccessfullToast
          // message={toastMessage}
          message={"toastMessage"}

          isShowEnabled={showToast}
          setShow={setShowToast}
          // colorText={textcolor}
          colorText={"green"}

        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </div>
  );
};

export default AddCustomer;
