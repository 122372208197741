import { useEffect } from "react";
import { EquipmentItem } from "../Equipment";
import '../Equipment.scss'

interface EquipmentListProps {
  selectedEquipmentIndex: number | null;
  onSelectEquipment: (index:number, equipmentId: string, equipmentName: string) => void;
  equipmentItems: EquipmentItem[]; 
  currentAsset: any;
}

const EquipmentList: React.FC<EquipmentListProps> = ({ selectedEquipmentIndex, onSelectEquipment, equipmentItems, currentAsset }) => {
  
   useEffect(() => {
    if (selectedEquipmentIndex === null && equipmentItems.length > 0) {
      // Default to the first item if none is selected
      onSelectEquipment(0, equipmentItems[0].value, equipmentItems[0].key);
    } else if (selectedEquipmentIndex !== null && equipmentItems[selectedEquipmentIndex]) {
      const equipment = equipmentItems[selectedEquipmentIndex];
      onSelectEquipment(selectedEquipmentIndex, equipment.value, equipment.key);
    }
  }, [currentAsset.assetName]);
  
  return (   
    <ul className="equipment-list-container">
      {equipmentItems.map((equipment, index) => (
        <li
          key={index}
          className={`equipment-list-item ${selectedEquipmentIndex === index ? 'active-list' : ''}`}
          onClick={() => onSelectEquipment(index, equipment.value, equipment.key)} 
        >
          {equipment.key}</li>
        
      ))}
    </ul>
    
  );
};
export default EquipmentList;