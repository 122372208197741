import { apiInstance } from "../../config/HttpCommon";

export const getAssetInfo = async (assetName: string) => {
    const response = await apiInstance.get(`/v2/assets/${encodeURIComponent(assetName)}/info`);
    return response.data;
  };

export const getAssetInfoEquipments = async (assetName: string) => {
    const response = await apiInstance.get(`/v2/assets/${encodeURIComponent(assetName)}/equipments`);
    return response.data;
  };

export const getAssetInfoEquipmentId = async (assetName: string, equipmentId: string, cancelToken: any) => {
    const response = await apiInstance.get(`/v2/assets/${encodeURIComponent(assetName)}/equipments/${equipmentId}/data`, {cancelToken});
    return response.data;
  };

export const getAssetInfoEventTypes = async (assetName: string) => {
    const response = await apiInstance.get(`/v2/assets/${encodeURIComponent(assetName)}/events-types`);
    return response.data;
  };

export const getAssetInfoEvents = async (assetName: string, SearchQuery: string, FilterBy: string, SortOrder: string, SortBy: string, PageNumber: number, PageSize: number) => {
    const response = await apiInstance.get(`/v2/assets/${encodeURIComponent(assetName)}/events?SearchQuery=${SearchQuery}&FilterBy=${FilterBy}&SortOrder=${SortOrder}&SortBy=${SortBy}&PageNumber=${PageNumber}&PageSize=${PageSize}`);
    return response.data;
  };