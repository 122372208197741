import React, { useEffect, useState, useRef } from "react";
import "./dropdown.scss";
import { useLocation } from "react-router-dom";
import { ReactComponent as ChevronUp } from "../../../../images/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../images/chevron-down.svg";
import Loader from "../loader/loader";

export interface SearchDropdownProps {
  items: searchDropdownItem[];
  defaultOption?: string;
  inputValue: string;
  onChange?: (item: searchDropdownItem) => void;
  onInputChange?: (inputValue: string) => void;
  disabled?: boolean;
  error?: boolean;
  infoMessage?: string;
  isLoading?: boolean;
}

export interface searchDropdownItem {
  name: string;
  displayName: string;
  id: string;
  cnX_SiteId?: string;
  envWellID?: string;
  apI_UWI?: string;
  sso?: boolean;
  passwordHash?: string | null;
  isSelected?: boolean;
}

const SearchDropdown: React.FC<SearchDropdownProps> = (props) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState<searchDropdownItem | null>(null);
  const [visibleItems, setVisibleItems] = useState<searchDropdownItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<searchDropdownItem[]>([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(props.inputValue);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const itemsPerPage = 12;

  useEffect(() => {
    const defaultSelectedItem = props.items.find(
      (item) => item.name === props.defaultOption
    );
    setSelected(defaultSelectedItem || null);
  }, [props.defaultOption, props.items, location.pathname]);

  useEffect(() => {
    let filtered = [...props.items];

    if (selected) {
      filtered = filtered.filter((item) => item.id !== selected.id);
    }

    const sortedItems = filtered
      .map((item) => ({
        ...item,
        displayName: item.apI_UWI
          ? `${item.name} (${item.apI_UWI})`
          : item.name,
      }))
      .sort((a, b) => {
        const nameA = a.displayName || "";
        const nameB = b.displayName || "";
        return nameA.localeCompare(nameB, undefined, { numeric: true });
      });
    setVisibleItems(sortedItems.slice(0, itemsPerPage));
    setFilteredItems(sortedItems);
    setPage(1);
  }, [props.items, selected]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemChange = (item: searchDropdownItem) => {
    setSelected(item);
    setSearchTerm("");
    setIsActive(false);
    if (props.onChange) {
      props.onChange({ ...item, name: item.name });
    }
  };

  useEffect(() => {
    if (selectedRef.current && isActive) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selected, isActive]);

  const loadMoreItems = () => {
    const nextPage = page + 1;
    const newItems = filteredItems.slice(0, nextPage * itemsPerPage);
    setVisibleItems(newItems);
    setPage(nextPage);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop === clientHeight &&
      visibleItems.length < filteredItems.length
    ) {
      loadMoreItems();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    if (props.onInputChange) {
      props.onInputChange(inputValue);
    }
    const filtered = props.items.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredItems(filtered);
    setVisibleItems(filtered.slice(0, itemsPerPage));
    setPage(1);
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${props.disabled ? "disabled" : ""}`}
      style={{ width: "90%" }}
    >
      <div
        onClick={() => !props.disabled && setIsActive(!isActive)}
        className="dropdown-btn"
      >
        <span className="dropdown-input">
          {selected ? selected.displayName : props.defaultOption}
        </span>
        <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      {isActive && !props.disabled && (
        <div
          className="dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
          onScroll={handleScroll}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onClick={(e) => e.stopPropagation()}
            onChange={handleInputChange}
            className="dropdown-search"
            disabled={props.disabled}
          />
          {props.isLoading ? (
            <Loader />
          ) : searchTerm.length <= 3 && filteredItems.length === 0 ? (
            <div className="item">{props.infoMessage}</div>
          ) : filteredItems.length === 0 ? (
            <div className="item">No results found</div>
          ) : (
            visibleItems.map((item, index) => (
              <div
                key={`${item.id}-${index}`}
                onClick={() => handleItemChange(item)}
                className={`item ${selected?.id === item.id ? "item-selected" : ""
                  }`}
                ref={selected?.id === item.id ? selectedRef : null}
                data-testid={item.name}
              >
                {item.displayName}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
