import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { Column, ColumnInstance, useTable } from 'react-table';
import { User } from '../../model/User';
import './UserAction.scss';
import SearchIcon from '../../../../images/SearchIcon.png';
import { fetchUsers, updateUserStatus, fetchUserById, fetchOperators, fetchPrivileges } from '../../UserSlice';
import ConfirmationModel from '../confirmation-model/ConfirmationModel';
import thinArrow from '../../../../images/thinArrow.svg';
import chevronUp from '../../../../images/color-chevron-up.png';
import filterIcon from '../../../../images/filter-icon.svg';
import closeIcon from '../../../../images/x-close.svg';
import { capitalizeFirstInWord } from '../../../../utilities/CommonFunctions';
import { ReactComponent as SortIconUp} from '../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown} from '../../../../images/Chevron-down-icon.svg';

interface UserActionProps {
  actionStatus: string;
  onClose: () => void;
  isOpen: boolean;
}

const columns: Column<User>[] = [
  { Header: '#', accessor: 'selected' },
  { Header: '', accessor: 'profile' },
  { Header: 'User', accessor: 'name' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Privilege', accessor: 'rolesNames' },
  { Header: 'Company', accessor: 'company' },
  // { Header: 'Role', accessor: 'role' },
  { Header: 'Last active', accessor: 'lastLoginDate' },
];

interface FilterOption { label: string, option: string }

const UserAction: React.FC<UserActionProps> = ({ actionStatus, onClose, isOpen }) => {
  const allusers = useAppSelector((state) => state.user.users);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const [action, setAction] = useState<string>('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const hElement = document.getElementById('main-root');
  const operators = useAppSelector((state) => state.user.operators);
  const privileges = useAppSelector((state) => state?.user.privileges);

  const users =
    actionStatus === 'Active'
      ? allusers?.filter((x) => x.status === 'InActive')
      : actionStatus === 'InActive'
        ? allusers?.filter((x) => x.status === 'Active')
        : allusers;

  const [sortConfig, setSortConfig] = useState<{ key: keyof User; direction: 'asc' | 'desc' } | null>(null);
  const dispatch = useAppDispatch();
  const [selectedCategories, setSelectedCategories] = useState<FilterOption[]>([]);
  const [tempSelectedCategories, setTempSelectedCategories] = useState<FilterOption[]>([]);
  useEffect(() => {
    if (!operators) {
      dispatch(fetchOperators());
    }
    if (!privileges) {
      dispatch(fetchPrivileges())
    }

  }, [])
  const checkTheLastLoginDateValue = (timestamp: string, option: string): boolean => {
    const givenDate = new Date(timestamp); // Convert the given timestamp to a Date object
    const currentDate = new Date(); // Get the current time in UTC
    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate.getTime() - givenDate.getTime();
    // Convert time difference from milliseconds to days
    const daysDifference = (timeDifference / (1000 * 60 * 60 * 24));
    if (option?.includes('days')) {
      return (option?.includes('7') && daysDifference > 7)
        || (option?.includes('30') && daysDifference > 30)
        || (option?.includes('90') && daysDifference > 90)
        || (option?.includes('180') && daysDifference > 180)
    } else {
      const yearValue = daysDifference / 365
      return yearValue > 1
    }
  }

  const prepareFilter = (selectedFilter: FilterOption[]) => {
    const data: any = {};
    selectedFilter?.forEach((obj) => {
      if (data[obj?.label]) {
        data[obj?.label].push(obj.option);
      } else {
        data[obj?.label] = [obj.option];
      }
    });

    return data;
  };

  const filteredByCategory = users.filter(user => {
    if (selectedCategories.length === 0) return true;
    // Check if the user matches all criteria in the filter object
    const preparedFilteredData = prepareFilter(selectedCategories)
    return Object.keys(preparedFilteredData).every(key => {

      if (key === 'Last active') {
        return checkTheLastLoginDateValue(user.lastLoginDate.toString(), preparedFilteredData[key][0])
      } else if (key === 'Privilege') {
        return preparedFilteredData[key].some((value: string) => value.replace(" ", '').toLowerCase() === user.rolesNames.replace(" ", '').toLowerCase());
      } else {
        return preparedFilteredData[key].some((value: string) => value === user[key.toLowerCase() as keyof User]);
      }
      // Check if the user's value for the key matches one of the filter values (case-insensitive)
    });
  });


  const [searchTerm, setSearchTerm] = useState<string>('');

  const [noResultsFound, setNoResultsFound] = useState(false);
  const [displayCount, setDisplayCount] = useState(15);
  const [filteredData, setFilteredData] = useState<User[]>([])

  const displayedRecords = filteredData.slice(0, displayCount);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [subDropdown, setSubDropdown] = useState('');
  useEffect(() => {
    if (users) {
      const data = filteredByCategory.filter(
        (user) =>
          user.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.status?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.rolesNames?.replace(" ", '').toLowerCase().includes(searchTerm.replace(" ", '').toLowerCase()) ||
          user.company?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          // user.role?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastLoginDate?.toString().includes(searchTerm),
      );
      setFilteredData(data)
    }
  }, [selectedCategories, searchTerm])

  useEffect(() => {
    setNoResultsFound(filteredData.length === 0);
    if (selectedUserIds.size)
      setSelectedUserIds(new Set());

    if (selectAll)
      setSelectAll(false)
  }, [filteredData]);

  useEffect(() => {
    if (selectAll && displayedRecords?.length !== selectedUserIds?.size)
      setSelectAll(false)
  }, [displayedRecords])

  const toggleDashboardDropdown = () => {
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  };


  const filterOptions = [
    { label: 'Status', options: ['Active', 'InActive'] },
    { label: 'Last active', options: ['Over 7 days', 'Over 30 days', 'Over 90 days', 'Over 180 days', 'Over 1 year'] },
    { label: 'Privilege', options: privileges ? privileges?.map(item => item.value) : [] },
    { label: 'Company', options: operators ? operators?.map((item) => item.name) : [] },
  ];
  const dashboardOptions = filterOptions.filter((option) => {
    if (actionStatus !== 'Delete')
      return option.label !== 'Status'
    else return true
  })
  const sortedData = React.useMemo(() => {
    if (sortConfig === null) return displayedRecords;
    return [...displayedRecords].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue == null || bValue == null) return 0;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
      }
      return aValue < bValue
        ? sortConfig.direction === 'asc'
          ? -1
          : 1
        : aValue > bValue
          ? sortConfig.direction === 'asc'
            ? 1
            : -1
          : 0;
    });
  }, [displayedRecords, sortConfig]);

  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate} ${formattedTime}`;
  };

  const sortedFormattedData = sortedData.map((item: any) => ({
    ...item,
    name: capitalizeFirstInWord(item.name),
    lastLoginDate: formatDateTime(item.lastLoginDate),
  }));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: sortedFormattedData,
  });

  const handleUsers = async () => {
    if (selectedUserIds.size > 0) {
      setAction(actionStatus);
      setIsConfirmationModalOpen(true);
      hElement && hElement?.classList.add('modal-open');
    }
  };

  const handleConfirmationClose = () => {
    setIsConfirmationModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleConfirmationOk = async () => {
    setIsConfirmationModalOpen(false);
    setSelectedCategories([]);
    const message = actionStatus === 'Delete' ? 'Deleted successfully' : 'Inactive successfully';

    if (selectedUserIds) {
      const response = await dispatch(updateUserStatus({ ids: Array.from(selectedUserIds), status: actionStatus }));
      if (response?.payload?.isStatusChanged) {
        toast.success(`${message}. ${response?.payload?.count} user(s) affected.`);
        dispatch(fetchUsers());
        if (currentUser?.id) {
          dispatch(fetchUserById(currentUser?.id));
        }
        onClose();
      } else {
        toast.error('Failed to update user.');
        onClose();
      }
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<Set<string>>(new Set());

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedUserIds(new Set());
    } else {
      const newSelectedIds = new Set<string>();
      displayedRecords.forEach((doc) => newSelectedIds.add(doc.id!));
      setSelectedUserIds(newSelectedIds);
    }
    setSelectAll(!selectAll);
  };
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastUserElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayCount < filteredData.length) {
          setLoading(true);
          setTimeout(() => {
            setDisplayCount((prevDisplayCount) => prevDisplayCount + 15);
            setLoading(false);
          });
        }
      });
      if (node instanceof HTMLTableRowElement) observer.current.observe(node);
    },
    [loading, displayCount, filteredData.length],
  );
  const toggleUserSelection = (user: User) => {
    const newSelectedIds = new Set(selectedUserIds);
    if (newSelectedIds.has(user.id!)) {
      newSelectedIds.delete(user.id!);
    } else {
      newSelectedIds.add(user.id!);
    }
    setSelectedUserIds(newSelectedIds);
    setSelectAll(newSelectedIds.size === displayedRecords.length);
  };

  const handleSort = (column: ColumnInstance<User>) => {
    if (column.id !== 'selected' && column.id !== 'profile') {
      let direction: 'asc' | 'desc' = 'asc';
      if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      setSortConfig({ key: column.id as keyof User, direction });
    }
  };

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setTempSelectedCategories([])
    setSearchTerm('');
    setIsDashboardDropdownOpen(false); // Close dropdown when clearing filters
    setSubDropdown('')
  };

  const handleApplyFilters = () => {
    setSubDropdown('');
    setSelectedCategories([...tempSelectedCategories])
    setIsDashboardDropdownOpen(false);
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDashboardDropdownOpen) {

        const target = event.target as HTMLElement;
        if (!target.closest('.custom-dropdown')) {
          setIsDashboardDropdownOpen(false);
          setSubDropdown('')
          if (selectedCategories.every((value, index) => value === tempSelectedCategories[index])) {
            setTempSelectedCategories([...selectedCategories])
          }
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isDashboardDropdownOpen]);

  const handleCloseModal = () => {
    if (selectAll) setSelectAll(!selectAll)
    setSelectedUserIds(new Set());
    onClose()
    setSelectedCategories([]);
    setTempSelectedCategories([])
    setSearchTerm('');
    setSubDropdown('')
  }

  const handleClearSelectedOption = (index: number) => {
    const temp = [...selectedCategories]
    temp?.splice(index, 1)
    setSelectedCategories(temp)
    setTempSelectedCategories(temp)
  }


  const handleFilterOptionSelect = (selectedLabel: string, selectedOption: string) => {
    setTempSelectedCategories((prev) => {
      if (selectedLabel === 'Last active' || selectedLabel === 'Status') {
        const matchedOne = prev?.find(item => item.option === selectedOption)
        if (matchedOne) {
          return prev?.filter(item => item.option !== selectedOption)
        } else {
          const filteredArray = prev?.filter(item => item.label !== selectedLabel)
          return filteredArray ? [...filteredArray, { label: selectedLabel, option: selectedOption }] : [{ label: selectedLabel, option: selectedOption }]
        }
      } else {
        const updatedCategories = prev?.find(item => item.option === selectedOption) ? prev?.filter(item => item.option !== selectedOption) : [...prev, { label: selectedLabel, option: selectedOption }]
        return updatedCategories
      }
    })
  }

  return isOpen ? (
    <div className='modal-overlay'>
      <div className='modal user-modal'>
        <div className='card profile-card'>
          <div className='header'>
            <span className='title'>Bulk {actionStatus === 'Delete' ? 'remove' : actionStatus === 'Active' ? 'inactivate' : 'activate'} users</span>
          </div>
          <div className='body user-details-body user-actions-content'>
            <div className='flex flex-row items-center mb-2'>
              <div className='flex gap-8 basis-3/5 '>
                <div className='flex items-center selected-count'>{selectedUserIds?.size} selected</div>
                <div className='flex flex-wrap gap-2'>
                  {
                    selectedCategories?.map((item, index) => {
                      return (
                        <div className='selected-filter-option flex items-center'>
                          {`${item.label}: ${item.option}  `}
                          <img src={closeIcon} onClick={() => handleClearSelectedOption(index)} />
                        </div>
                      )
                    })
                  }
                </div>

              </div>
              <div className='basis-2/5'>
                <div className='flex place-content-end gap-2'>
                  <div className='flex'>
                    <div className='custom-drop w-64'>
                      <div
                        className={`custom-dropdown header-option${isDashboardDropdownOpen ? 'open' : ''}`}
                        onClick={toggleDashboardDropdown}
                      >
                        <div
                          className={`action-filter selected-option one flex items-center border-0  ${isDashboardDropdownOpen ? 'active-filter' : ''
                            }`}
                        >
                          <img src={filterIcon} alt='filter-icon' className='pr-3' />
                          Filter{` (${selectedCategories?.length})`}
                          <img
                            style={{ position: 'absolute', right: '10px' }}
                            src={thinArrow}
                            alt='chevron-down'
                            className={`user-arrow-icon ${isDashboardDropdownOpen ? 'rotate-180' : ''
                              }`}
                          />
                        </div>
                        {isDashboardDropdownOpen && (
                          <div className='category-checkboxes user-actions-filter-dropdown' onClick={stopPropagation}>
                            <div className='flex header'>Filter users</div>
                            <div className='card dropdown-list'>
                              <div className='card-body flex-column' style={{ overflowY: 'auto' }}>
                                <div className='drop-content'>
                                  {dashboardOptions.map((option, index) => (
                                    <div key={index} className='status-dropdown'>
                                      <div
                                        className={`custom-dropdown ${subDropdown === option.label ? 'open' : ''}`}
                                        onClick={(e) => {
                                          setSubDropdown(subDropdown === option.label ? '' : option.label);
                                          stopPropagation(e);
                                        }}
                                      >
                                        <div
                                          className={`selected-option flex justify-between items-center ${subDropdown === option.label && 'active-label'
                                            }`}
                                        >
                                          {option.label}
                                          {subDropdown === option.label ? (
                                            <img src={chevronUp} alt='color-chevron-up' className='user-arrow-color' />
                                          ) : (
                                            <img src={thinArrow} alt='chevron-down' className='user-arrow-icon' />
                                          )}
                                        </div>
                                        {subDropdown === option.label && (
                                          <ul className='dropdown-list'>
                                            {option.options.map((subOption, subIndex) => (
                                              <li key={subIndex} onClick={(e) => e.stopPropagation()} className='bulk-action-filter-checkbox'>
                                                <label
                                                  className={`checkbox-container ${tempSelectedCategories.find(item => item.option === subOption) ? 'active-label' : ''}`}
                                                >
                                                  <input
                                                    type='checkbox'                                                      // name={option.label}
                                                    checked={tempSelectedCategories.find(item => item.option === subOption) ? true : false}

                                                    onChange={() => {
                                                      handleFilterOptionSelect(option.label, subOption);
                                                    }}
                                                  />
                                                  <span className='checkbox-checkmark'></span>
                                                  {subOption}
                                                </label>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className='btn-notify'>
                              <button type='button' className='btn btn-secondary' onClick={handleClearFilters}>
                                Clear
                              </button>
                              <button type='button' className='btn btn-primary' onClick={handleApplyFilters}>
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='flex user-search header-option'>
                      <label className='relative block'>
                        <span className='sr-only'>Search</span>
                        <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                          <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                            <path
                              fill-rule='evenodd'
                              d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                              clip-rule='evenodd'
                            ></path>
                          </svg>
                        </span>
                        <input
                          className='custom-text-input'
                          placeholder='Search...'
                          type='text'
                          name='search'
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='adminuser-block'>

              <div className='flex flex-row'>
                <div className='body form-body'>
                  <div className='doc-wrapper'>
                    <div className='table-container w-full'>
                      <table className='document-table' {...getTableProps()}>
                        <thead className='document-head'>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                              {headerGroup.headers.map((column, index) => (
                                <th
                                  {...column.getHeaderProps()}
                                  onClick={() => handleSort(column as ColumnInstance<User>)}
                                  key={column.id}
                                >
                                  {index === 0 ? (
                                    <label className='checkbox-container'>
                                      <input type='checkbox' onChange={toggleSelectAll} checked={selectAll} />
                                      <span className='checkbox-checkmark'></span>
                                    </label>
                                  ) : (
                                    column.render('Header')
                                  )}
                                  {sortConfig && sortConfig.key === column.id && (
                                    <span style={{display: 'inline-block', verticalAlign: 'middle'}}>{sortConfig.direction === 'asc' ? <SortIconUp alt='sort-asc' className='sort-img' /> : <SortIconDown alt='sort-dsc' className='sort-img' />}</span>
                                  )}
                                </th>
                              ))}
                              {/* <th></th> */}
                            </tr>
                          ))}
                        </thead>
                        <tbody className='document-body' {...getTableBodyProps()}>
                          <>
                            {rows.map((row, rowIndex) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  ref={rows.length === rowIndex + 1 ? lastUserElementRef : null}
                                  key={row.id}
                                >
                                  {row.cells.map((cell, cellIndex) => (

                                    <td {...cell.getCellProps()} key={cell.column.id}>

                                      {cellIndex === 0 ? (
                                        <label className='checkbox-container'>
                                          <input
                                            type='checkbox'
                                            checked={selectedUserIds.has(row.original.id!)}
                                            onChange={() => toggleUserSelection(row.original)}
                                          />
                                          <span className='checkbox-checkmark'></span>
                                        </label>
                                      ) :
                                        cellIndex === 1 ?
                                          (
                                            <div className={`profile-image-container bulk-action-user-profile`}>
                                              <p className='profile-image-text'>
                                                {row.original?.firstName?.charAt(0).toUpperCase()}
                                                {row.original?.lastName?.charAt(0).toUpperCase()}
                                              </p>
                                            </div>
                                          )
                                          : cell.render('Cell')

                                      }
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                            {noResultsFound && displayedRecords.length === 0 && (
                              <tr>
                                <td colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                                  <img className='search-icon-lg' src={SearchIcon} alt='' />
                                  No results found
                                  <p>We couldn't find your search results. Try searching another keyword</p>
                                </td>
                              </tr>
                            )}
                            {loading && (
                              <tr>
                                <td colSpan={columns.length + 1}>
                                  <div className='loading-spinner'>Loading...</div>
                                </td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='footer flex items-center place-content-end'>
            <div className='btn-notify'>
              <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                Cancel
              </button>
              <button type='button' className='btn btn-color' onClick={handleUsers}>
                {actionStatus === 'Delete' ? 'Remove' : actionStatus === 'Active' ? 'Activate' : 'Inactivate'} users
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position='top-right' autoClose={3000} />
      <ConfirmationModel
        isValidAction={isConfirmationModalOpen}
        isModalClose={handleConfirmationClose}
        isModalOk={handleConfirmationOk}
        action={action}
      />
    </div>
  ) : null;
};

export default UserAction;
