import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Column, ColumnInstance, useTable } from 'react-table';
import { AllWellsTableType } from './AllWellsTableType';
import './AllWellsTable.scss';
import { ReactComponent as SortIconDefault } from '../../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../../images/Chevron-down-icon.svg';
import { ReactComponent as StarIconEmpty } from '../../../../../../images/star-empty.svg';
import { ReactComponent as StarIconGold } from '../../../../../../images/star-gold.svg';
import { ReactComponent as ArrowUp } from '../../../../../../images/arrow-narrow-up.svg';
import UpTimeChart from '../all-wells-chart/UpTimeChart';
import IntakePressureChart from '../all-wells-chart/IntakePressureChart';
import ProductionChart from '../all-wells-chart/ProductionChart';
import WellWatchlistModal from '../wellWatchlistModal/wellWatchlistModal';
import thinArrow from '../../../../../../images/thinArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { addAssetToWatchList, fetchAssetList, removeAssetFromWatchList } from '../AllWellSlice';
import { toast } from 'react-toastify';
import { AssetData } from '../../../interfaces/AssetList.interface';
import Loader from '../../../../../common/page-loader/ComponentLoader';
import PerformanceSnapshotModal from '../../performanceSnapshotModal/PerformanceSnapshotModal';
import { WellWatchList } from '../../../interfaces/ControlRoomWells.interface';
import { getDefaultWatchList, updateWatchListById } from '../../ControlRoomWellsSlice';
import WellControlroomCharts from '../../../../../well-controlroom-charts/WellControlroomCharts';
import { clearAssetDetails, fetchAssetInfo } from '../../../../../asset-info/AssetInfoSlice';
import { fetchUserPrefrence } from '../../../../../controlroom/controlroom.slice';
import html2canvas from 'html2canvas';

interface AllWellsTableProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
  selectedWatchList: WellWatchList;
}

interface SnapshotWellProps {
  assetName: string;
  assetGroupName: string;
  assetId: string | number;
  searchString: undefined | string;
}

const AllWellsTable = ({ toggleModal, selectedWatchList }: AllWellsTableProps) => {
  const navigate = useNavigate();
  const assetList = useAppSelector((state) => state.AssetList.AllWellAssetList)
    .results as unknown as AllWellsTableType[];
  const assetListData = useAppSelector((state) => state.AssetList.AllWellAssetList) as unknown as AssetData;
  const assetListLoading = useAppSelector((state) => state.AssetList.loading);
  const [tableData, setTableData] = useState<AllWellsTableType[]>(assetList);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AllWellsTableType; direction: 'asc' | 'desc' } | null>({
    key: 'healthScore',
    direction: 'asc',
  });
  const [selectedWell, setSelectedWell] = useState('');
  const [selectedIntakeOption, setSelectedIntakeOption] = useState('Intake Pressure');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [showLoading, setShowLoading] = useState(true);
  const [selectedSnapshotWell, setSelectedSnapshotWell] = useState<SnapshotWellProps>();
  const dispatch = useAppDispatch();
  const wellTableRef = React.createRef<HTMLDivElement>();
  const defaultWatchList = useAppSelector(getDefaultWatchList);
  //const widgetLoading = useAppSelector((state) => state.controlroom.loading);
  const wellControlLoading = useAppSelector((state) => state.wellControlroomCharts.loading);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');
  const [snapshot, setSnapshot] = useState<any>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 100 && !assetListLoading && assetListData.hasNextPage) {
          dispatch(
            fetchAssetList({ pageSize: 25, pageNumber: assetListData.nextPageNumber || assetListData.pageNumber + 1 }),
          );
        }
      }
    },
    [assetList?.length, assetListLoading, dispatch],
  );

  // const selectOptions = ['Intake Pressure', 'Production'];
  const selectOptions = ['Intake Pressure'];

  const toggleDropdwon = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedIntakeOption(item);
    setIsSelectOpen(!isSelectOpen);
  };

  const handleCloseModal = () => {
    setSelectedWell('');
  };

  const createBtnHandler = () => {
    setSelectedWell('');
    toggleModal('filters', true);
  };
  // Toggle star status for a particular row
  const handleStarClick = async (wellName: string, isStared: boolean) => {
    let response;
    if (isStared) {
      response = await dispatch(removeAssetFromWatchList(wellName));
    } else {
      response = await dispatch(addAssetToWatchList(wellName));
    }

    if (response.meta.requestStatus === 'fulfilled') {
      toast.success(isStared ? 'Well removed from watchlist' : 'Well added to watchlist');
      setTableData((prevData) =>
        prevData.map((item) => (item.wellName === wellName ? { ...item, isStared: !item.isStared } : item)),
      );
      dispatch(
        updateWatchListById({
          id: defaultWatchList?.id ?? '',
          count: (defaultWatchList?.count ?? 0) + (isStared ? -1 : 1),
        } as WellWatchList),
      );
    }

    // setSelectedWell(wellName);
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.wellName === wellName ? { ...item, isStared: !item.isStared } : item)),
    // );
  };

  useEffect(() => {
    setTableData(assetList);
  }, [assetList]);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsContextMenuOpen(null);
      }
    };
    if (isContextMenuOpen) {
      document.addEventListener('mousedown', handleOutSideClick);
    }
    return () => document.addEventListener('mousedown', handleOutSideClick);
  }, [isContextMenuOpen]);

  const columns: Column<AllWellsTableType>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'isStared' as keyof AllWellsTableType,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: 'Well Name',
        accessor: 'wellName' as keyof AllWellsTableType,
        width: 290,
        minWidth: 290,
        maxWidth: 290,
      },
      // {
      //   Header: 'HealthScore',
      //   accessor: 'healthScore' as keyof AllWellsTableType,
      //   width: 180,
      //   minWidth: 180,
      //   maxWidth: 180,
      // },
      {
        Header: 'Operator',
        accessor: 'operator' as keyof AllWellsTableType,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: 'Uptime',
        accessor: 'uptime' as keyof AllWellsTableType,
        width: 265,
        minWidth: 265,
        maxWidth: 265,
      },
      {
        Header: () => (
          <div>
            <div className='intake-pressure__dropdown'>
              <div className='intake-pressure__dropdown-input-container' onClick={toggleDropdwon}>
                <input type='text' className='intake-pressure__dropdown-input' value={selectedIntakeOption} readOnly />
                <img
                  src={thinArrow}
                  alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                  className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
                />
              </div>
              {isSelectOpen && (
                <ul className='intake-pressure__dropdown-list'>
                  {selectOptions.map((item, index) => (
                    <li key={index} onClick={() => handleItemClick(item)} className='intake-pressure__dropdown-item'>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ),
        accessor: 'intakePressure' as keyof AllWellsTableType,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      // {
      //   Header: '7 Day Changes',
      //   accessor: 'sevenDaysChangeTrend' as keyof AllWellsTableType,
      //   width: 110,
      //   minWidth: 110,
      //   maxWidth: 110,
      // },
      {
        Header: '',
        accessor: 'dropdown' as keyof AllWellsTableType,
        width: 65,
        minWidth: 65,
        maxWidth: 65,
      },
    ],
    [tableData, selectedIntakeOption, isSelectOpen],
  );

  const sortedData = useMemo(() => {
    if (assetListLoading) {
      return [];
    }
    if (sortConfig === null) return tableData;
    return (
      tableData &&
      [...tableData]?.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue == null || bValue == null) return 0;
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
        }
        return aValue < bValue
          ? sortConfig.direction === 'asc'
            ? -1
            : 1
          : aValue > bValue
          ? sortConfig.direction === 'asc'
            ? 1
            : -1
          : 0;
      })
    );
  }, [tableData, sortConfig]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns || [],
    data: sortedData || [],
  });

  const handleSort = (column: ColumnInstance<AllWellsTableType>) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column.id as keyof AllWellsTableType, direction });
  };

  const openContextMenuOpen = (index: number) => {
    setIsContextMenuOpen((prevIndex: any) => (prevIndex === index ? null : index));
  };

  const getBorderColor = (score: any) => {
    if (score < 50) return '#F97066';
    if (score >= 51 && score <= 75) return '#FFBF3F';
    if (score > 75) return '#12B76A ';
    return '#CCC';
  };

  const handleWellNameClick = (selectedWell: any) => {
    const selectedAsset = {
      assetName: selectedWell.wellName,
      assetGroupName: 'Well Groups',
      assetId: selectedWell.id,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
    navigate(`/layout/well-control-room`);
  };

  const captureSnapshot = async () => {
    if (hiddenRef.current) {
      const canvas = await html2canvas(hiddenRef.current);
      const image = canvas.toDataURL('image/svg');
      setSnapshot(image); // Save the snapshot as a base64 image
    }
  };

  const openModal = (event: React.MouseEvent, selectedWell: any) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedAsset = {
      assetName: selectedWell.wellName,
      assetGroupName: 'Well Groups',
      assetId: selectedWell.assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
    setSelectedSnapshotWell(selectedAsset);
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
    setSnapshot(null);
  };

  useEffect(() => {
    if (selectedSnapshotWell) {
      dispatch(fetchAssetInfo({ assetName: selectedSnapshotWell?.assetName || 'ADAMS 43-6 B 802JM' }));
      dispatch(fetchUserPrefrence('asset-control-room'));
    }
    return () => {
      dispatch(clearAssetDetails());
    };
  }, [selectedSnapshotWell]);

  useEffect(() => {
    if (!wellControlLoading) {
      const timer: any = setTimeout(() => {
        captureSnapshot();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [wellControlLoading]);

  interface RenderCellContentProps {
    cell: any;
    row: any;
  }

  const renderCellContent = ({ cell, row }: RenderCellContentProps) => {
    const { id } = cell.column;
    const { original } = row;

    switch (id) {
      case 'isStared':
        return (
          <span className='favourite-icon' onClick={() => handleStarClick(original.wellName, original.isStared)}>
            {original.isStared ? <StarIconGold /> : <StarIconEmpty />}
          </span>
        );

      case 'wellName':
        return (
          <div onClick={() => handleWellNameClick(original)} className='well-name-link'>
            {cell.render('Cell')}
          </div>
        );

      case 'healthScore':
        return (
          <div className='flex justify-left align-center'>
            <div
              className='health-score-circle flex justify-center align-center items-center'
              style={{ border: `2px solid ${getBorderColor(original.healthScore)}` }}
            >
              {original.healthScore}
            </div>
          </div>
        );

      case 'uptime':
        return <UpTimeChart data={original.uptimeData || []} />;

      case 'intakePressure':
        return selectedIntakeOption === 'Intake Pressure' ? (
          <IntakePressureChart data={original.intakePressureData || []} />
        ) : (
          <ProductionChart data={original.productionData || []} />
        );

      case 'sevenDaysChangeTrend':
        return (
          <div className='all-wells-day-change'>
            <div className='square-bracket-style'>
              <ArrowUp /> {original.sevenDaysChangeTrend}%
            </div>
          </div>
        );

      case 'dropdown':
        return (
          <div className='dropdown-container p-2'>
            <button className='dropdown-btn' onClick={() => openContextMenuOpen(row.index)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isContextMenuOpen === row.index && (
              <div className='dropdown-options' ref={dropdownRef}>
                <button className='dropdown-option show-element' onClick={(e) => openModal(e, row.original)}>
                  System dashboard snapshot
                </button>
                {selectedWatchList.id === defaultWatchList?.id &&
                  (row?.original?.isStared ? (
                    <button
                      onClick={() => handleStarClick(row?.original?.wellName, row?.original?.isStared)}
                      className='dropdown-option show-element'>Remove well from watchlist
                    </button>
                  ) : (
                    <button
                      onClick={() => handleStarClick(row?.original?.wellName, row?.original?.isStared)}
                      className='dropdown-option show-element'>Add well to watchlist
                    </button>
                  ))}
              </div>
            )}
          </div>
        );

      default:
        return cell.render('Cell');
    }
  };

  
  return (
    <>
      <div className='doc-wrapper multiwell-table-wrapper'>
        <div
          className='table-container w-full !rounded-[8px]'
          ref={wellTableRef}
          onScroll={() => fetchMoreOnBottomReached(wellTableRef.current)}
        >
          <table className='document-table' {...getTableProps()} key='table'>
            <thead className='document-head'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id + 'header'}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({
                        style: { width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth },
                      })}
                      onClick={() => {
                        if (column.id !== 'uptime' && column.id !== 'intakePressure' && column.id !== 'dropdown') {
                          handleSort(column as ColumnInstance<AllWellsTableType>);
                        }
                      }}
                      key={column.id}
                    >
                      <div>
                        {column.render('Header')}
                        {column.id !== 'uptime' && column.id !== 'intakePressure' && column.id !== 'dropdown' ? (
                          sortConfig?.key === column.id ? (
                            sortConfig.direction === 'asc' ? (
                              <SortIconUp alt='sort-asc' className='sort-img' />
                            ) : (
                              <SortIconDown alt='sort-dsc' className='sort-img' />
                            )
                          ) : (
                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </th>
                  ))}
                  {/* <th></th> */}
                </tr>
              ))}
            </thead>
            {assetListLoading && <Loader />}
            <tbody
              className={`document-body ${assetListLoading ? 'blur-background pointer-events-none' : ''}`}
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            minWidth: cell.column.minWidth,
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                        key={cell.column.id}
                      >
                        {renderCellContent({ cell, row })}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedWell && <WellWatchlistModal handleCloseModal={handleCloseModal} createBtnHandler={createBtnHandler} />}

      <div className='controlroom-snapblock mt-12' ref={hiddenRef}>
        {selectedSnapshotWell && <WellControlroomCharts />}
      </div>

      <PerformanceSnapshotModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={selectedSnapshotWell?.assetName ?? 'Performance Snapshot'}
        loading={wellControlLoading}
        modelWidth={'120rem'}
        modelClass={'performaceSnapshotModal'}
        innerComponent={
          <>
            {wellControlLoading ? (
              <Loader />
            ) : (
              <div className='w-full h-full'>
                {snapshot && <img src={snapshot} alt={'Screenshot'} className={`pb-2 w-full max-w-full`} />}
              </div>
            )}
          </>
        }
      />
    </>
  );
};

export default AllWellsTable;
