import React, { useState } from 'react'
import { WalkthroughData } from '../Walkthrough';
import './Walkthrough.scss'
import Logo from '../images/championx-logo.svg'
import LeftArrow from '../../../images/arrow-narrow-left.svg'
import RightArrow from '../../../images/arrow-narrow-right.svg'


interface WalkthroughProps {
    onComplete: () => void
}

const Walkthrough: React.FC<WalkthroughProps> = ({ onComplete }) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleSkipIntro = () => {
        onComplete();
    }

    const handleBack = () => {
        setSelectedIndex((prevIndex) => prevIndex - 1)
    }
    const handleContinue = () => {
        if (selectedIndex !== WalkthroughData?.length - 1)
            setSelectedIndex((prevIndex) => prevIndex + 1)

        if (selectedIndex === WalkthroughData?.length - 1)
            onComplete()
    }

    const handlSliderClick = (index: number) => {
        setSelectedIndex(index)
    }

    return (
        <div className='walkthrough-container flex w-full'>
            <div className='left-div w-1/2'>
                <div className='content'>
                    <div className='logo-div'>
                        <img src={Logo} />
                    </div>
                    <div className='walkthrough-header'>
                        {WalkthroughData[selectedIndex]?.contentHeader}
                    </div>
                    <div className='walkthrough-description'>
                        {WalkthroughData[selectedIndex]?.contentDescription}
                    </div>
                    <div className='button-div'>
                        <button className={`back-button button ${selectedIndex === 0 ? 'disable' : ''}`} onClick={handleBack}> <img src={LeftArrow} /> Back</button>
                        <span className='skip-button' onClick={handleSkipIntro}>Skip intro</span>
                        <button className='continue-button button' onClick={handleContinue}><img src={RightArrow} /> Continue</button>
                    </div>
                    <div className='slider-div'>
                        {
                            WalkthroughData?.map((_, index: number) => {
                                return (
                                    <span className={`slider-circle ${selectedIndex === index ? 'active' : ''}`} key={index} onClick={() => handlSliderClick(index)}></span>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='footer'>
                    <span>@ ChampionX 2024</span>
                </div>
            </div>
            <div className='right-div w-1/2'>
                <div className='walkthrough-image'>
                    <img src={WalkthroughData[selectedIndex]?.contentImage} alt={""} />
                </div>
                <div className='walkthrough-image-caption'>
                    {WalkthroughData[selectedIndex]?.imageCaption}
                </div>
            </div>
            {/* {
                WalkthroughData?.map((item: WalkthroughDataProps, index: number) => {
                    return (
                        <div className='walkthrough-content' key={index}>
                            <div className='left-div'>
                                <div className='walkthrough-header'>
                                    {item.contentHeader}
                                </div>
                                <div className='walkthrough-description'>
                                    {item.contentDescription}
                                </div>
                            </div>
                            <div className='right-div'>
                                <div className='walkthrough-image'>
                                    <img src={item.contentImage} alt={item.imageCaption} />
                                </div>
                                <div className='walkthrough-image-caption'>
                                    {item.imageCaption}
                                </div>
                            </div>
                        </div>
                    )
                })
            } */}

        </div>
    )
}

export default Walkthrough;