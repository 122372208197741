import React, { memo } from 'react';
import Close from '../../../../../images/x-close.svg';
import CustomModal from '../../../../dashboard/components/graph/ExpandModel';
import Loader from '../../../../common/page-loader/ComponentLoader';
import './PerformanceSnapshotModal.scss'

interface PerformanceSnapshotModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    heading: string;
    loading: boolean;
    modelWidth?: string; // give width like '65rem'
    modelClass?:string; // use class 'fullModelView' for full view.
    innerComponent?: any; 
    children?:any;
    selectedDateRange?: any;
    setSelectedDateRange?: any;
}

const PerformanceSnapshotModal: React.FC<PerformanceSnapshotModalProps> = ({ 
    isOpen,
    onRequestClose,
    heading,
    loading,
    modelWidth,
    modelClass,
    innerComponent
 }: PerformanceSnapshotModalProps) => {
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modelWidth} classname={modelClass}>
            <div className='modal-header'>
                <div className='flex'>
                    <div className='grow'>
                        <h3>{heading}</h3>
                    </div>
                    <div className='flex-none'>
                        <button onClick={onRequestClose}>
                        <img src={Close} alt='Expand' />
                        </button>
                    </div>
                </div>
            </div>
            <div className='modal-content'>
                <div className='flex flex-wrap items-center justify-center wellChart-container-dashboard nodata'>
                                
                    {loading ? (
                        <div className='flex items-center justify-center w-100'>
                            <Loader />
                        </div>
                    ) : (
                    <>
                        {innerComponent}               
                    </>
                    )}
                </div>
            </div>
        </CustomModal>
    )
}

export default memo(PerformanceSnapshotModal);