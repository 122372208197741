import React, { useEffect } from 'react';
import chevronLeftIcon from './../../../../images/chevron-left.svg';
import messageIcon from './../../../../images/message-dots-square.svg';
import barChartIcon from './../../../../images/bar-chart-square-plus.svg';
import infocircleIcon from './../../../../images/info-circle.svg';
import refreshIcon from './../../../../images/refresh-cw-002.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { useNavigate } from 'react-router-dom';
import { updateAssetControlRoomDrawerProps } from '../../../asset-control-room-navigation-drawer/AssetControlRoomDrawerSlice';
// import Tooltip from '../../../common/tooltip/ToolTip';
import { fetchAssetControl } from '../../../asset/AssetControlSlice';

interface WellDashboardHeaderProps {
  headerTitle?: string;
}
interface selectedWellName {  
  assetGroupName: string;
  assetId: string;
  assetName: string;
}

const WellDashboardHeader: React.FC<WellDashboardHeaderProps> = ({ headerTitle }) => {
  const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const wellStatus = assetControlData.wellStatus

  const selectedWellNameLS: any = sessionStorage.getItem('selectedWellName');
  const selectedWellName: selectedWellName | null = selectedWellNameLS ? JSON.parse(selectedWellNameLS) : null;

  useEffect(()=>{
    if(!wellStatus){
      if (selectedWellName?.assetName) {
        dispatch(fetchAssetControl(selectedWellName?.assetName));
      }
    }
  },[selectedWellName?.assetName]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCustomizeControlRoomClick = () => {
    dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'customizecontrolRoom' }));
    navigate("/layout/well-control-room");
  }

  const handleCommentsClick = () => {
    dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'comments' }));
  } 

  const handleAssetInfoDrawer = () => {
    dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'assetInfo' }));
    navigate("/layout/well-control-room");
  }

  const handleRefreshClick = () => {
    window.location.reload()
  };

  const backBtnHandle = () => {
    navigate(-1); 
  }

  return (
    <div className={`${assetControlRoomDrawer?.openState ? 'relative left-0' : 'fixed left-[68px]'} z-50 bg-[#131F2E] top-0 right-0 py-[15px] text-[#F7F9F9] px-8 flex items-center justify-between wellheader`}>
      <div className='wellheader-left'>
        <h3 className='flex items-center justify-start text-[18px] uppercase font-medium text-[#F7F9F9] page-title'>
          <span className='mr-3 p-2 hover:cursor-pointer'>

            <div onClick={backBtnHandle}>
            <img src={chevronLeftIcon} alt='chevron-left-icon' />
            </div>

          </span>
          {headerTitle}
          <span className={`text-[12px] rounded-[4px] py-[2px] px-[8px] ml-[7px] font-semibold text-center well-status ${assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ? (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run') ? 'running' : (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) ? 'idle' : 'blank') : 'blank'}`}>{assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ?? '-'}</span>
        </h3>
      </div>
      <div className='flex items-center wellheader-right'>
        {/* <Tooltip content='Coming soon' direction='bottom'> */}
          <span className='flex ml-2 p-2 hover:cursor-pointer' onClick={() => {handleCommentsClick()}} >
            <img src={messageIcon} alt='message-icon' />
          </span>
        {/* </Tooltip> */}
        <span className='ml-2 p-2 hover:cursor-pointer' onClick={() => { handleCustomizeControlRoomClick() }}>
          <img src={barChartIcon} alt='barchart-icon' />
        </span>
        <span className='ml-2 p-2 hover:cursor-pointer' onClick={() => { handleAssetInfoDrawer() }}>
          <img src={infocircleIcon} alt='info-icon' />
        </span>
        <span className='ml-3 mr-2 linebar'></span>
        <span className='ml-2 p-2 hover:cursor-pointer' onClick={handleRefreshClick}>
          <img src={refreshIcon} alt='refresh-icon' />
        </span>
      </div>
    </div>
  )
}

export default WellDashboardHeader;