import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface IntakePressureChartProps {
  data: { date: string; value: number }[];
}

const IntakePressureChart: React.FC<IntakePressureChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // Convert date strings to Date objects
    const transformedData = data.map(item => ({
      ...item,
      date: new Date(item.date).getTime() // Convert to timestamp
    }));

    const root = am5.Root.new(chartRef.current!);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
        }),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

       // Hide x-axis and y-axis labels and grid
       xAxis.get("renderer").labels.template.set("visible", false);
       xAxis.get("renderer").grid.template.set("visible", false);
       yAxis.get("renderer").labels.template.set("visible", false);
       yAxis.get("renderer").grid.template.set("visible", false);

   // Create Line Series
   const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: "Waveform",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
    })
  );

    // Set line style
    setTimeout(() => {
      series.strokes.template.setAll({
        strokeWidth: 2,
        stroke: am5.color("#DC4405"),
        lineJoin: "miter", // Sharp corners
      });
    }, 10);

    series.data.setAll(transformedData);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: "100%", height: "70px" }} />;
};

export default IntakePressureChart;
