import axios from "axios";
import { getFerrariAPIBaseURL, getMockAPIBaseURL, getWebSocketURL, getXspocAPIBaseURL } from "../utilities/BaseURLUtility";

// const userToken = sessionStorage.getItem("access_token");
// const userToken = localStorage.getItem('jwtAccessToken')
const baseUrl = getFerrariAPIBaseURL();
const wellControlUrl = getWebSocketURL();
const apiBaseUrl = getMockAPIBaseURL()
const xspocApiBaseUrl = getXspocAPIBaseURL()


const http = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
    }
});
http.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('jwtAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
http.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear()
            if (process.env.REACT_APP_LOGOUT_URL)
                window.location.href = process.env.REACT_APP_LOGOUT_URL;
        }
        // Return the error to be handled by the calling function
        return Promise.reject(error);
    },
);

const apiInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
    }
});
apiInstance.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('jwtAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
apiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear()
            if (process.env.REACT_APP_LOGOUT_URL)
                window.location.href = process.env.REACT_APP_LOGOUT_URL;
        }
        // Return the error to be handled by the calling function
        return Promise.reject(error);
    },
);

const wellControlInstance = axios.create({
    baseURL: wellControlUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});
wellControlInstance.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('xspocAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const xspocApiInstance = axios.create({
    baseURL: xspocApiBaseUrl,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        [process.env.REACT_APP_XSPOC_SUBSCRIPTION_KEY_NAME as string]:
            process.env.REACT_APP_XSPOC_SUBSCRIPTION_KEY_VALUE,
    },
});
xspocApiInstance.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('xspocAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
xspocApiInstance.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear()
            if (process.env.REACT_APP_LOGOUT_URL)
                window.location.href = process.env.REACT_APP_LOGOUT_URL;
        }
        // Return the error to be handled by the calling function
        return Promise.reject(error);
    },
);


export { http, wellControlInstance, apiInstance, xspocApiInstance };