import React from "react";
import "./confirmationDialog.scss";
import { ReactComponent as HelpIcon } from "../../../../images/help-circle.svg";
import Modal from "../modal/modal";
import Loader from "../loader/loader";

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  message?: string;
  isLoading?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  isLoading,
}) => {
  return (
    <div className="confirmation-content">
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="confirmation-dialog">
          <p className="confirmation-message">
            <HelpIcon />
            {message}
          </p>
          <div className="confirmation-buttons-content">
            <button onClick={onConfirm} className="confirmation-button">
              Yes
            </button>
            <button onClick={onClose} className="confirmation-button">
              No
            </button>
          </div>
          <div>
            {isLoading && (
              <div className="spinner-overlay">
                <Loader isOverlay={true} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationDialog;
