import React, { useState, useRef, ReactNode, FC } from "react";
import "./tooltip.scss";

interface TooltipProps {
  children: ReactNode;
  delay?: number;
  direction?: string;
  content: ReactNode;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [active, setActive] = useState<boolean>(false);

  const showTip = () => {
    timeoutRef.current = setTimeout(() => {
      setActive(true);
    }, props.delay || 300);
  };

  const hideTip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setActive(false);
  };

  return (
    <div
      className="onboarding-tooltip-wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      data-testid="tooltip-wrapper"
    >
      {props.children}
      {active && (
        <div
          data-testid="onboarding-tooltip-text"
          className={`onboarding-tooltip-tip ${props.direction || "top"}`}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
