import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomerItem, CustomerSearchResponse, searchCustomerByNameAsync } from "./customerService";


interface CustomerState {
    customer: CustomerItem[];
    searchResults: CustomerSearchResponse[];
    customerToEdit: CustomerItem | null;
    status: "idle" | "loading" | "succeeded" | "failed";
    customerLoading: boolean;
    customerSearchLoading: boolean;
    customerSaveLoading: boolean;
    customerDeleteLoading: boolean;
    error: string | null;
}

const initialState: CustomerState = {
    customer: [],
    searchResults: [],
    customerToEdit: null,
    status: "idle",
    customerLoading: false,
    customerSearchLoading: false,
    customerSaveLoading: false,
    customerDeleteLoading: false,
    error: null,
};

// Async thunk for searching customers by name
export const searchCustomerByName = createAsyncThunk(
    "customer/searchCustomerByNameAsync",
    async ({ name, cancelToken }: { name: string, cancelToken: any }) => {
        const response = await searchCustomerByNameAsync(name, cancelToken);
        return response;
    }
);


const CustomerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        resetCustomerToEdit(state) {
            state.customerToEdit = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchCustomerByName.pending, (state) => {
                state.customerSearchLoading = true;
            })
            .addCase(searchCustomerByName.fulfilled, (state, action) => {
                state.customerSearchLoading = false;
                state.searchResults = action.payload;
            })
            .addCase(searchCustomerByName.rejected, (state, action) => {
                state.customerSearchLoading = false;
                state.error = action.error.message || "Failed to search Customer";
            })
    }

});

export const { resetCustomerToEdit } = CustomerSlice.actions;

export default CustomerSlice.reducer;
