import { useCallback, useEffect, useRef, useState } from "react";
import WatchListCard from "../watchListCard/watchListCard";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { WellWatchList, WellWatchListData } from "../../interfaces/ControlRoomWells.interface";
import { deleteWatchList, fetchWellsWatchList, removeWatchListById } from "../ControlRoomWellsSlice";
import Loader from "../../../../common/page-loader/ComponentLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { showToaster } from "../../../../dashboard/components/asset-location/AssetList";

interface WatchWellsListProps {
    selectedWatchList: WellWatchList;
    setIsBuildWatchlistFiltersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedWatchList: React.Dispatch<React.SetStateAction<WellWatchList>>;
    setActiveWell: React.Dispatch<React.SetStateAction<WellWatchList>>;
    activeWell: WellWatchList;
}

const WatchWellsList = (
    { selectedWatchList, setIsBuildWatchlistFiltersModalOpen, setSelectedWatchList, setActiveWell, activeWell }: WatchWellsListProps
) => {
    const dispatch = useAppDispatch();
    const wellWatchList = useAppSelector((state) => state.wellWatchList).wellWatchList.results as WellWatchList[];
    const wellWatchListData = useAppSelector((state) => state.wellWatchList).wellWatchList as WellWatchListData;

    const loading = useAppSelector((state) => state.wellWatchList.loading);

    const [wells, setWells] = useState<
        WellWatchList[]
    >(wellWatchList);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const observer = useRef<IntersectionObserver | null>(null);

    const lastDocumentRef = useCallback((node: HTMLDivElement | null) => {
        if (loading) return;
        if (!wellWatchListData.hasNextPage) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && wellWatchListData.hasNextPage) {
                dispatch(fetchWellsWatchList({
                    pageSize: 25,
                    pageNumber: Math.floor(wells.length / 10) + 1
                }))
            }
        });

        if (node) observer.current.observe(node);
    }, [wells?.length, wellWatchListData.hasNextPage, loading, dispatch]);

    const handleCardClick = (well: WellWatchList) => {
        if (selectedWatchList?.id === well.id) {
            setSelectedWatchList({} as WellWatchList);
        } else {
            setSelectedWatchList(well);
        }
    };

    const handleDropdownClick = (well: WellWatchList) => {
        setActiveDropdownId(well.id);
    };

    const handleCloseDropdown = () => {
        setActiveDropdownId(null);
    };

    useEffect(() => {
        setWells(wellWatchList);
        setSelectedWatchList({} as WellWatchList);
    }, [wellWatchList]);

    const handleDeleteWatchList = (well: WellWatchList) => {
        setActiveWell(well);
        setIsDeleteModalOpen(true);
    };

    const handleEditWatchList = (well: WellWatchList) => {

        setActiveWell(well);
        setIsBuildWatchlistFiltersModalOpen(true)
    };

    const onDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const onDeleteWatchList = async () => {
        if (activeWell.id) {
            setIsDeleteModalOpen(false);
            await dispatch(deleteWatchList(activeWell.id));
            dispatch(removeWatchListById(activeWell.id));
            setActiveWell({} as WellWatchList);
            showToaster('Watchlist deleted successfully', 'success');
        }
    };

    return (
        <>
            {loading && <Loader />}
            <div className='flex items-center h-200 watch-wells-list-container'>
                {isDeleteModalOpen && <DeleteModal onOkClick={onDeleteWatchList} onCloseClick={onDeleteModalClose} />}
                <div className={`${loading ? "hidden blur-background pointer-events-none" : ""} flex watch-wells-list`}>
                    {wells?.map((well, index) => (
                        <div
                            key={well.id + index}
                            ref={index === wells.length - 1 ? lastDocumentRef : null}
                            className={index === 0 ? "sticky-card" : ""}
                        >
                            <WatchListCard
                                title={well.name}
                                description={well.description}
                                count={well.count}
                                isDropdownOpen={activeDropdownId === well.id}
                                onClick={handleCardClick}
                                onDropdownClick={handleDropdownClick}
                                onCloseDropdown={handleCloseDropdown}
                                isActive={well.id === selectedWatchList.id}
                                handleDeleteWatchList={handleDeleteWatchList}
                                handleEditWatchList={handleEditWatchList}
                                well={well}
                                key={well.id + index}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default WatchWellsList;
