import React, { useEffect, useState, useRef } from 'react';
import './DashboardHeader.scss';
// import user_pro from '../../../../images/bruce.jpg';
import FilterTicketDetails from '../ticket-details/FilterTicketDetails';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setFilterStatus, setFilterNewStatus } from '../../DashboardSlice';
import NewTicketDetails from '../ticket-details/NewTicketDetails';
import JobsSchedule from '../event-schdule/JobsSchedule';
import thinArrow from '../../../../images/thinArrow.svg';
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import { AppUser } from '../../../user/model/AppUser';
import { fetchUserById } from '../../../user/UserSlice';
import { GetLocalTimeFormat } from '../../../../utilities/CommonFunctions';

//const user_pro = '';
interface DashboardHeaderProps {
  showContent?: boolean;
  hadndleEvendSchedulePointerEvent?: any;
  groupName: string;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ showContent = true, hadndleEvendSchedulePointerEvent, groupName }) => {
  const [isAssignedTicketsOpen, setIsAssignedTicketsOpen] = useState(false);
  const [isNewTicketsOpen, setIsNewTicketsOpen] = useState(false);
  const [isJobsTodayOpen, setIsJobsTodayOpen] = useState(false);
  const [greeting, setGreeting] = useState('Loading...');
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('');
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  let currentUser = useAppSelector((state) => state?.user?.currentUser);
  const filterTicketsCount = useAppSelector((state) => state.dashboard.filterTicketsCount);


  if (currentUser?.id != storedUser?.id)
    currentUser = storedUser

  useEffect(() => {
    if (currentUser?.id != storedUser?.id) {
      dispatch(fetchUserById(storedUser?.id));
    }
  }, [currentUser?.id]);

  const dispatch = useAppDispatch();
  const myTicketsRef = useRef<HTMLDivElement>(null);
  const newTicketsRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);


  const refreshTime = () => {
    const now = new Date();
    const formattedGreeting = getGreeting(now.getHours());
    setGreeting(formattedGreeting);
    setLastUpdatedDateTime(GetLocalTimeFormat());
  };

  useEffect(() => {
    refreshTime();
  }, []);

  useEffect(() => {
    if (hadndleEvendSchedulePointerEvent)
      hadndleEvendSchedulePointerEvent(isJobsTodayOpen)
  }, [isJobsTodayOpen])

  const getGreeting = (hour: number): string => {
    if (hour < 12) {
      return 'Good morning';
    } else if (hour >= 12 && hour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };

  const handleRefreshClick = () => {
    window.location.reload()
  };

  const handleAssignedTickets = () => {
    setIsNewTicketsOpen(false); // Close New Tickets dropdown
    setIsJobsTodayOpen(false); // Close Jobs Today dropdown
    dispatch(setFilterStatus(['pending', 'solved', 'open', 'deleted']));
    setIsAssignedTicketsOpen(!isAssignedTicketsOpen);
  };

  const handleNewTickets = () => {
    setIsAssignedTicketsOpen(false); // Close My Tickets dropdown
    setIsJobsTodayOpen(false); // Close Jobs Today dropdown
    dispatch(setFilterNewStatus('new'));
    setIsNewTicketsOpen(!isNewTicketsOpen);
  };

  const handleJobsToday = () => {
    setIsAssignedTicketsOpen(false); // Close My Tickets dropdown
    setIsNewTicketsOpen(false); // Close New Tickets dropdown
    // dispatch(setJobScheduleFilter('today'));
    setIsJobsTodayOpen(!isJobsTodayOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {

    if (myTicketsRef.current && !myTicketsRef.current.contains(e.target as Node)) {
      setIsAssignedTicketsOpen(false);
    }

    if (newTicketsRef.current && !newTicketsRef.current.contains(e.target as Node)) {
      setIsNewTicketsOpen(false);
    }

    if (eventsRef.current && !eventsRef.current.contains(e.target as Node)) {
      setIsJobsTodayOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const newTicketsCount = useAppSelector((state) => state.dashboard.newTicketsCount);
  const jobsCount = useAppSelector((state) => state.dashboard.jobsCount);

  return (
    <>
      <div className='dashboard-first-block'>
        <div className='top-header grid grid-cols-2 gap-2'>
          <div>
            <h3 className='page-title'>{groupName}</h3>
          </div>
          <div className='flex justify-end' onClick={handleRefreshClick}>
            <p className='text-timestamp'>Last updated on {lastUpdatedDateTime}</p>
            <img src={refreshIcon} alt='refresh-icon' />
          </div>
        </div>
        {showContent && (
          <div className='w-100 pb-0 p-9'>
            <div className='card dashboard-card-first m-0' >
              <div className='block-wrap'>
                <div className='dash-content'>

                  {currentUser?.profilePic ? ( // If user_pro is available, render the image
                    <div className='img-pro'>
                      <img src={currentUser?.profilePic} alt='' />
                    </div>
                  ) : ( // If user_pro is not available, render the initials
                    <div className='p-5 dashboard-profile-image-container'>
                      <p className='profile-image-text'>{currentUser?.firstName?.charAt(0).toUpperCase()}{currentUser?.lastName?.charAt(0).toUpperCase()}</p>
                    </div>
                  )}
                  <div className='para-dashboard ml-5'>
                    <h1 className='h1-large text-para-dash'>
                      {greeting}, {currentUser?.firstName && currentUser?.firstName?.charAt(0)?.toUpperCase() + currentUser?.firstName.slice(1)}
                    </h1>
                    <p className='text-para-dash heding-margin' style={{ paddingTop: '8px' }}>Here is your latest status report.</p>
                  </div>
                </div>
                <div className='dashboard-count ui'>
                  <div className='section-first margin-right'>
                    <h1 className='text-para-dash mb-3'>{filterTicketsCount}</h1>
                    <div
                      className={`ticket-container ${isAssignedTicketsOpen ? 'active-button' : ''}`}
                      onClick={handleAssignedTickets}
                      ref={myTicketsRef}
                    >
                      <p className='text-para-dash link' style={{ minWidth: '30px' }}>My tickets</p>
                      <img
                        src={thinArrow}
                        alt={isAssignedTicketsOpen ? 'Up Arrow' : 'Down Arrow'}
                        className={`transition-transform ${isAssignedTicketsOpen ? 'rotate-180' : ''}`}
                      />
                    </div>
                  </div>
                  <div className='section-first margin-right'>
                    <h1 className='text-para-dash mb-3'>{newTicketsCount}</h1>
                    <div
                      className={`ticket-container ${isNewTicketsOpen ? 'active-button' : ''}`}
                      onClick={handleNewTickets}
                      ref={newTicketsRef}
                    >
                      <p className='text-para-dash link' style={{ minWidth: '30px' }}>New tickets</p>
                      <img
                        src={thinArrow}
                        alt={isNewTicketsOpen ? 'Up Arrow' : 'Down Arrow'}
                        className={`transition-transform ${isNewTicketsOpen ? 'rotate-180' : ''}`}
                      />
                    </div>
                  </div>
                  <div className='section-first margin-right'>
                    <h1 className='text-para-dash mb-3'>{jobsCount}</h1>
                    <div
                      className={`ticket-container ${isJobsTodayOpen ? 'active-button' : ''}`}
                      onClick={handleJobsToday}
                      ref={eventsRef}
                    >
                      <p className='text-para-dash link' style={{ minWidth: '30px' }}>Events today</p>
                      <img
                        src={thinArrow}
                        alt={isAssignedTicketsOpen ? 'Up Arrow' : 'Down Arrow'}
                        className={`transition-transform ${isJobsTodayOpen ? 'rotate-180' : ''}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isAssignedTicketsOpen && (
        <div className='overlay-section first' ref={myTicketsRef}>
          <FilterTicketDetails />
        </div>
      )}
      {isNewTicketsOpen && (
        <div className='overlay-section sec' ref={newTicketsRef} >
          <NewTicketDetails />
        </div>
      )}
      {isJobsTodayOpen && (
        <div className='overlay-section third' ref={eventsRef}>
          <JobsSchedule />
        </div>
      )}
    </>
  );
};

export default DashboardHeader;
