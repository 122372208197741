import React from 'react';
import { useAppSelector } from '../../hooks/storeHooks';
import Loader from '../common/page-loader/ComponentLoader';
import TabWithUrlComponent from '../../components/TabWithUrl';
import Actions from './actions/Actions';
import LookUps from './lookup/LookUps';
import Assets from './assets/Assets';
import { ToastContainer } from 'react-toastify';

const Support: React.FC = () => {
  const loading = useAppSelector((state) => state?.user?.userListLoading);
  const tabs = [
    { label: 'Actions', component: () => <Actions /> },
    { label: 'Lookups', component: () => <LookUps /> },
     { label: 'Assets', component: () => <Assets /> },
  ];

  return (
    <>
      <ToastContainer />
      <div className='group-status-container profile-container'>
        {loading ? (
          <div className='chart-loader flex items-center justify-center w-100'>
            <Loader />
          </div>
        ) : (
          <div className='profile-status-header profile-status-header-admin'>
            <div className='group-status-header group-status-header-admin'>
              <div className='group-status-content'>
                <div className='group-status-frame'>
                  <div className='text-and-badge'>
                    <div className='text'>Support tools</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='divider'></div>
            <TabWithUrlComponent initialTab={{ tab: 'Actions' }} tabs={tabs} value="Actions" />
          </div>
        )}
      </div>
    </>
  );
};

export default Support;
