import NoData from "../../../../dashboard/components/no-data/NoData";

const NoDataCardsDesign: React.FC<any> = ({ trend }) => {
    const colorCombination: any = {
        'System frequency': '#FEC84B',
        'Vibration Y': '#FFD072',
        'DC bus voltage': '#006DFA',
        'Drive voltage': '#12B76A',
        'Vibration X': '#3A7E73',
        'Intake pressure': '#004EB3',
        'Motor temperature': '#F97066',
        'Intake temperature': '#F04438',
        'Casing pressure': '#6BBBAE',
        'Tubing pressure': '#0094BD',
        'Motor current': '#FB743C',
        'Downhole current': '#F97066',
        'System RPM': '#B8C5CC',
        'Drive current': '#6CE9A6',
    };
    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='title'>{trend[0]?.unitOfMeasure}</div>
                </div>

                <div className='card-body' style={{ height: '250px' }}>
                    <div className='left-container-history'>
                        {trend?.map((item: any) => {
                            return (
                                <div style={{ margin: '8px 0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                marginRight: '4px',
                                                background: colorCombination[item?.trendName] ? colorCombination[item?.trendName] : 'red',
                                            }}
                                        ></div>
                                        <div>{item?.description || item?.variableName}</div>
                                    </div>
                                    {/* <div
                      style={{ marginLeft: '20px' }}
                    >{`${item?.medean} ${item?.short_UnitOfMeasure?.toUpperCase()}`}</div> */}
                                </div>
                            );
                        })}
                    </div>
                    <div className='rigth-container'>
                        <NoData heading='No data found' />
                        {/* <LineChart chartName={trend?.[0]?.trendName} unitOfMeasure={unitOfMeasure} chartData={trend} colorCombination={colorCombination} index={index} short_UnitOfMeasure={trend[0]?.short_UnitOfMeasure} selectedDate={selectedDate} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoDataCardsDesign;