import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchAssetInfo } from '../AssetInfoSlice';
import '../AssetInfo.scss';
import { ConvertTimeToLocal,CalculateRunDays } from '../../../utilities/CommonFunctions';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';
import WIP from '../../../images/wip1.svg';

const Details = () => {
  const dispatch = useAppDispatch();
  const assetDetails = useAppSelector((state) => state.assetInfo.assetDetails);
  const assetAlarmSubscriptions = useAppSelector((state) => state.assetInfo.alarmSubscriptions);
  const detailsLoading = useAppSelector((state) => state.assetInfo.detailsLoading);
  // const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName);
  // const assetInfoLoading = useAppSelector((state) => state.assetInfo.loading);
  //const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName);
  const [attributeResponseData, setAttributeResponseData] = useState<any[]>([]);
  const [controlAndStatusResponseData, setControlAndStatusResponseData] = useState<any[]>([]);

  const attributeStaticData = [
    'Company',
    'State',
    'Country',
    'Field',
    'Formation',
    'Basin',
    'Pad ID',
    'Pad type',
    'API number',
    'Production start date',
    'Installation date',
    'Total measurment depth',
    'True vertical depth',
    'Well direction',
    'Longitude',
    'Lattitude',
  ];
  const controlAndStatusStaticData = [
    'Current run status',
    'Time in status',
    'Restart delay remaining',
    'Operating mode',
    'Last scan',
    'Run Days',
  ];

  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
    dispatch(fetchAssetInfo({ assetName: selectedAssetNames?.assetName }));
  }, []);

  useEffect(() => {
    if (assetDetails && typeof assetDetails === 'object') {
      const attributeDataSlice = [
        assetDetails.company,
        assetDetails.state,
        assetDetails.county,
        assetDetails.field,
        assetDetails.formation,
        assetDetails.basin,
        assetDetails.padId,
        assetDetails.padType,
        assetDetails.apiNumber,
        assetDetails.productionStartDate,
        assetDetails.installationDate,
        assetDetails.totalMeasurementDepth,
        assetDetails.trueVerticalDepth,
        assetDetails.wellDirection,
        assetDetails.longitude,
        assetDetails.latitude,
      ];

      const controlAndStatusDataSlice = [
        assetDetails.currentRunStatus,
        assetDetails.timeInStatus,
        assetDetails.restartDelayRemaining,
        assetDetails.operatingMode,
        assetDetails.lastScan,
        CalculateRunDays(assetDetails.installationDate), // Calculate Run Days here
      ];

      setAttributeResponseData(attributeDataSlice);
      setControlAndStatusResponseData(controlAndStatusDataSlice);
    }
  }, [assetDetails]);

  return (
    <div className='details-main-section'>
      {detailsLoading ? (
        <div className='flex items-center justify-center w-100 chart-loader'>
          <Loader />
        </div>
      ) : ((assetDetails && Object.keys(assetDetails).length > 0) || (assetAlarmSubscriptions && Object.keys(assetAlarmSubscriptions).length > 0) ? (
        <>
          <div className='details-section-heading'>Attributes</div>
          <table className='details-section-table'>
            <tbody>
              {attributeResponseData.map((item, index) => (
                <tr key={index}>
                  <td className='left'>{attributeStaticData[index]}</td>
                  <td className='right'>{item}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='details-section-heading'>Controls and status</div>
          <table className='details-section-table'>
            <tbody>
              {controlAndStatusResponseData.map((item, index) => (
                <tr key={index}>
                  <td className='left'>{controlAndStatusStaticData[index]}</td>
                  <td className='right'>
                    { controlAndStatusStaticData[index] === 'Last scan'? ConvertTimeToLocal(item)
                    : controlAndStatusStaticData[index] === 'Run Days'? item // Run Days value is already calculated in the effect
                    : item}
        </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='details-section-heading'>Alarms and subscriptions</div>
          <div className='body'>
          <center>
            <img className='h-[170px]' src={WIP} alt='work in progress' />
            <p className='mt-6'>Coming soon</p>
            
          </center>
          </div>
          {/* <table className='details-section-table'>
            <tbody>
              {assetAlarmSubscriptions.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.firstName.charAt(0).toUpperCase() + item.firstName.slice(1)}{' '}
                    {item.lastName.charAt(0).toUpperCase() + item.lastName.slice(1)}
                  </td>
                  <td>{item.jobTitle}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </>
      ) : (
        <div className='flex flex-column items-center justify-center no-data-found'>
          <NoData heading='No data found' />
        </div>
      )
      )}
    </div>
  );
};

export default Details;
