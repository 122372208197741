import { createSlice } from "@reduxjs/toolkit";
import { Applications, AssetItem, AssetSearchResponse, ControllerPort, ControllerType } from "./assetService";
import { CustomerItem } from "../customer-details/customerService";
import { Timezone } from "@amcharts/amcharts5";


interface AssetState {
    asset: AssetItem[];
    customer: CustomerItem[];
    searchResults: AssetSearchResponse[];
    assetToEdit: AssetItem | null;
    applications: Applications[];
    port: ControllerPort[];
    type: ControllerType[];
    timezone: Timezone[];
    status: "idle" | "loading" | "succeeded" | "failed";
    assetLoading: boolean;
    assetSearchLoading: boolean;
    assetSaveLoading: boolean;
    assetDeleteLoading: boolean;
    error: string | null;
}

const initialState: AssetState = {
    asset: [],
    searchResults: [],
    assetToEdit: null,
    customer: [],
    applications: [],
    port: [],
    type: [],
    timezone: [],
    status: "idle",
    assetLoading: false,
    assetSearchLoading: false,
    assetSaveLoading: false,
    assetDeleteLoading: false,
    error: null,
};


const OnBoardingAssetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {
        resetAssetToEdit(state) {
            state.assetToEdit = null;
        },
    },
});

//   export const { resetAssetToEdit } = AssetSlice.actions;

export default OnBoardingAssetSlice.reducer;