import { useState } from "react";

export const useFormChangeDetection = <FormValues extends object>(initialFormData: FormValues | null) => {
  const [originalFormData, setOriginalFormData] = useState<any>(initialFormData);

  const hasChanges = (formData: FormValues): boolean => {
    if (!originalFormData) return false;
    return Object.keys(formData).some(
      (key) => formData[key as keyof FormValues] !== originalFormData[key as keyof FormValues]
    );
  };

  return { originalFormData, setOriginalFormData, hasChanges };
};
