import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CommentsState } from '../interfaces/commentsInterfaces';
import {
  getCommentsList,
  uploadFile,
  createComment,
  deleteComment,
  replyToComment,
  deleteReply,
} from '../services/commentsService';

const initialState: CommentsState = {
  comments: [],
  uploadResponse: null,
  createCommentResponse: null,
  deleteResponse: null,
  replyResponse: null,
  loading: false,
  error: null,
  commnetsLoading: true,
  pagination: {
    totalResultsCount: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    nextPageNumber: null,
    prevPageNumber: null,
    hasPrevPage: false,
    hasNextPage: false,
  },
};

// Async thunk for fetching comments
export const fetchComments = createAsyncThunk(
  'comments/fetchComments',
  async (
    {
      assetId,
      SearchQuery,
      SortOrder,
      SortBy,
      PageNumber,
      PageSize,
    }: {
      assetId: string;
      SearchQuery: string;
      SortOrder: string;
      SortBy: string;
      PageNumber: number;
      PageSize: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await getCommentsList(assetId, SearchQuery, SortOrder, SortBy, PageNumber, PageSize);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Async thunk for uploading a file
export const uploadCommentFile = createAsyncThunk('comments/uploadFile', async (file: File, { rejectWithValue }) => {
  try {
    const data = await uploadFile(file);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Async thunk for creating a comment
export const addComment = createAsyncThunk(
  'comments/createComment',
  async (
    commentData: {
      assetId: string;
      subject: string;
      content: string;
      attachments: {
        size: number;
        sizeSuffix: string;
        extension: string;
        fileName: string;
        blobFile: string;
        blobFileUrl: string;
      }[];
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await createComment(commentData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Async thunk for deleting a comment
export const removeComment = createAsyncThunk(
  'comments/removeComment',
  async (commentId: string, { rejectWithValue }) => {
    try {
      const data = await deleteComment(commentId);
      return { commentId, data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Async thunk for replying to a comment
export const replyComment = createAsyncThunk(
  'comments/replyComment',
  async (
    { commentId, content, attachments }: { commentId: any; content: any; attachments: Array<any> },
    { rejectWithValue },
  ) => {
    const requestBody = {
      content,
      attachments,
    };
    try {
      const data = await replyToComment(commentId, requestBody);
      return { commentId, data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Async thunk for deleting a reply
export const removeReply = createAsyncThunk(
  'comments/removeReply',
  async ({ commentId, replyId }: { commentId: string; replyId: string }, { rejectWithValue }) => {
    try {
      const data = await deleteReply(commentId, replyId);
      return { commentId, replyId, data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Slice definition
const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch comments
    builder
      .addCase(fetchComments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.commnetsLoading = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        const { data } = action.payload || {};
        state.loading = false;
        state.commnetsLoading = false;
    
        // Append comments if it's not the first page
        if (data?.pageNumber && data.pageNumber > 1) {
          state.comments = [...state.comments, ...(data.results || [])];
        } else {
          // Replace comments for the first page
          state.comments = data?.results || [];
        }
    
        // Update pagination
        state.pagination = {
          totalResultsCount: data?.totalResultsCount || 0,
          pageNumber: data?.pageNumber || 1,
          pageSize: data?.pageSize || 10,
          totalPages: data?.totalPages || 1,
          nextPageNumber: data?.nextPageNumber || null,
          prevPageNumber: data?.prevPageNumber || null,
          hasPrevPage: data?.hasPrevPage || false,
          hasNextPage: data?.hasNextPage || false,
        };
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.commnetsLoading = false;
      });

    // Upload file
    builder
      .addCase(uploadCommentFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadCommentFile.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadResponse = action.payload;
      })
      .addCase(uploadCommentFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // Create comment
    builder
      .addCase(addComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        state.createCommentResponse = action.payload;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default commentsSlice.reducer;
