import { apiInstance } from "../../config/HttpCommon";

export const getLookups = async () => {
    const response = await apiInstance.get(`/v1/lookups`);
    return response.data;
}

export const updateLookupsById = async (id: string, payload: any) => {
    const response = await apiInstance.put(`/v1/lookups/${id}`, payload);
    return response.data;
}

export const clearServerCacheAction = async () => {
    const response = await apiInstance.get(`/v1/support/clear-server-cache`);
    return response.data;
}

export const scheduleServiceStatusAction = async () => {
    const response = await apiInstance.get(`/v1/support/schedule-service-status`);
    return response.data;
}

export const fetchSupportAssetList = async (searchQuery: string, includeTimeseriesFields: boolean, pageNumber: number, pageSize: number, signal: AbortSignal | undefined = undefined
) => {
    const response = await apiInstance.get(`/v1/support/assets`, {
        params: {
            SearchQuery: searchQuery,
            IncludeTimeseriesFields: includeTimeseriesFields,
            PageNumber: pageNumber,
            PageSize: pageSize,
        },
        signal: signal
    });
    return response.data;
}

export const downloadAssetsCSV = async (searchQuery: string) => {
    const response = await apiInstance.get(`/v1/support/assets/export-csv`,
        {
            params: {
                SearchQuery: searchQuery
            },
            responseType: 'blob'
        });
    return response.data;
}