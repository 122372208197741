import React, { useEffect, useState, useMemo, useRef } from 'react';
import AllWellsTable from './all-wells-table/AllWellsTable';
import './AllWells.scss';
import { useAppDispatch } from '../../../../../hooks/storeHooks';
import { fetchAssetList, resetControlRoomWells } from './AllWellSlice';
import { WellWatchList } from '../../interfaces/ControlRoomWells.interface';

interface AllWellsProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
  selectedWatchList: WellWatchList;
  setSelectedWatchList: React.Dispatch<React.SetStateAction<WellWatchList>>;
}

const AllWells = ({ toggleModal, selectedWatchList, setSelectedWatchList }: AllWellsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useAppDispatch();

  const searchValue = useMemo(() => searchTerm, [searchTerm]);

  const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    const fetchAssets = () => {
      if (abortController.current) {
        abortController.current.abort();
    }
      dispatch(resetControlRoomWells())
      abortController.current = new AbortController();
      const params = selectedWatchList.id
        ? { pageSize: 25, pageNumber: 1, watchListId: selectedWatchList.id, searchQuery: searchTerm, signal: abortController?.current?.signal}
        : { pageSize: 25, pageNumber: 1, searchQuery: searchTerm, signal: abortController?.current?.signal };  
          dispatch(fetchAssetList(params));
    };
    const timeoutId = setTimeout(() => {
    fetchAssets();
    }, 500)
    return () => {
      clearTimeout (timeoutId);
      if (abortController.current) {
        abortController.current.abort();
    }
    } 
  }, [dispatch, selectedWatchList.id, searchTerm]);

  return (
    <>
      <div className='all-wells-section'>
        <div className='tab-content set-point-wrap'>
          <div className='card profile-card m-0'>
            <div className='header headerline'>
              <div className='flex flex-row w-100'>
                <p
                  onClick={() => selectedWatchList.name && setSelectedWatchList({} as WellWatchList)}
                  className={`title w-auto whitespace-nowrap ${selectedWatchList.name ? 'active-color' : ''}`}
                >
                  All Wells
                </p>
                {selectedWatchList.name && (
                  <>
                    <p className='title w-auto ml-2'>/</p>
                    <p className='title w-auto whitespace-nowrap ml-2'>{selectedWatchList.name}</p>
                  </>
                )}
              </div>
              <div className='breadcrumb'></div>
            </div>

            <div className='serch-filter-section flex flex-row w-100'>
              <div className='w-80'>
                <label className='relative block'>
                  <span className='sr-only'>Search by well name or operator</span>
                  <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                    <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                      <path
                        fillRule='evenodd'
                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        clipRule='evenodd'
                      ></path>
                    </svg>
                  </span>
                  <input
                    className='custom-text-input'
                    placeholder='Search by well name or operator'
                    type='text'
                    name='search'
                    value={searchValue}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className='body all-wells-table-body'>            
                <div className='all-wells-table-block'>
                  <div className='flex flex-col'>
                    <AllWellsTable toggleModal={toggleModal} selectedWatchList={selectedWatchList} />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllWells;
