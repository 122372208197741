import { xspocApiInstance } from "../../../../config/HttpCommon";

export interface CustomerItem {
  id: string;
  // createdBy: string;
  // createdOnDate: string;
  // lastModifiedBy: string;
  // lastModifiedDate: string;
  cnX_CustomerId: number;
  cnX_CustomerName: string;
  name: string;
  isActive: boolean;
  b2Cdomainname: string;
}

export interface CustomerApiResponse {
  values: CustomerItem[];
  id: string;
  dateCreated: string;
}

export interface CustomerSearchResponse {
  id: string;
  name: string;
}

// Fetch customer data from API
const fetchCustomerDataAsync = async (): Promise<CustomerItem[]> => {
  try {
    const response = await xspocApiInstance.get(
      "/Customer",
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && Array.isArray(response.data.values)) {
      return response.data.values;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching customer data", error);
    return [];
  }
};

const searchCustomerByNameAsync = async (
  name: string,
  cancelToken: any
): Promise<CustomerSearchResponse[]> => {
  try {
    const response = await xspocApiInstance.get(`/CNXCustomer/search/${name}`, {
      headers: {
        "content-type": "application/json",
      },
      cancelToken,
    });

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error searching customer data by name", error);
    return [];
  }
};

export { fetchCustomerDataAsync, searchCustomerByNameAsync }