import { apiInstance, http } from '../../../../config/HttpCommon';
import { UserPreferenceDataItem } from './TrendsSlice';

export const getTrendsById = async (wellName: string, startDate: string, endDate: string, aggregate?: string, cancelToken?: any) => {
  const response = await http.get(`/GetAssetTrendsGraphData?wellName=${encodeURIComponent(wellName)}&startDate=${startDate}&endDate=${endDate}&Aggregate=${aggregate}`, { cancelToken });
  return response.data;
};

export const getTrendsFilterData = async () => {
  const response = await http.get(`/GetAssetTrends`);
  return response.data;
}

export const userPreferences = async (dashboard: string) => {
  return await apiInstance.get(`/v1/user-preferences/widgets/${dashboard}`);
}
export const updateUserPreference = async (
  dashboard: string,
  data: UserPreferenceDataItem[],
) => {
  return await apiInstance.put(`/v1/user-preferences/widgets/${dashboard}`, data);
};