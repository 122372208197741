import { useEffect } from "react";
import React from "react";
import "./toast.scss";
import { ReactComponent as SuccessIcon } from "../../../../images/check-circle.svg";
import { ReactComponent as ErrorIcon } from "../../../../images/x-circle.svg";

interface SuccessfullToastProps {
  message: string;
  isShowEnabled: boolean;
  setShow: any;
  colorText: string
}
export default function SuccessfullToast({
  message,
  isShowEnabled,
  setShow,
  colorText = "black", // Default text color
}: SuccessfullToastProps) {
  useEffect(() => {
    if (isShowEnabled) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [isShowEnabled, setShow]);

  return (
    <div aria-live="assertive" className="toast-container">
      <div className="toast-top-position">
        <div
          className="toast"
          style={{
            border:
              colorText === "green" ? "1px solid #027A48" : "1px solid #B42318",
            backgroundColor: colorText === "green" ? "#ECFDF3" : "#FEF3F2",
          }}
        >
          <div className="toast-content">
            <div className="toast-message">
              <div className="toast-icon-text">
                {colorText === "green" && <SuccessIcon />}
                {colorText === "red" && <ErrorIcon />}
                <p
                  style={{
                    color: colorText === "green" ? "#027A48" : "#B42318",
                  }}
                >
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
